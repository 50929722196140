import React from 'react';
import Navbar from '../includes/navbar'
import Footer from '../includes/footer'



class commingSoon extends React.Component {

    render(){

        return (

            <div>
                <Navbar/>
             <div className="container">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-push-1 col-md-10 col-lg-push-2 col-lg-8">
                                    <div id="tg-content" className="tg-content">
                                        <div className="tg-comingsooncontent">
                                            <div className="tg-textbox">
                                                <h5>Stay Tuned<span>This page will be available soon.</span></h5>
                                                <div className="tg-description">
                                                </div>
                                                <div className="tg-btns">
                                                    <a className="tg-btn tg-btnaboutus" href="/">Home</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
             </div>
                <Footer />
            </div>
        );
    }
}
export default commingSoon;
