import React, { Component } from "react";
import Navbar from "../includes/navbar";
import Footer from "../includes/footer";
import { Auth } from "aws-amplify";
import { ToastsContainer, ToastsStore } from "react-toasts";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { loginCheck, loginWithGoogleCheck } from "../store/actions/authActions";
import { Link } from "react-router-dom";
import GoogleAuth from "./googleAuth";
import FacebookAuth from "./facebookAuth";
import queryString from "query-string";
import axios from "axios";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginAttempt: Boolean,
      isSocial: true,
    };
    const value = queryString.parse(this.props.location.search);
    const token = value.code;
    if (token === undefined) {
      this.setState({ loginAttempt: false });
    } else {
      this.setState({ loginAttempt: true });
      this.socialLogin(token);
      let time = new Date();
      localStorage.setItem("time", JSON.stringify(time.getTime()));
    }
  }

  state = {
    name: "",
    email: "",
    password: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
  };

  componentDidMount() {
    // const { location, history } = this.props;
    // const previousPath = location.state.from.pathname;
    // console.log(this.props.history.location.state.from.pathname, "checkPath");
    // const { location } = this.props;
    // const { state } = location;
    // this.props.history.go(-1);
    // nextProps.history.goBack();
    //  console.log(previousPath, "previousPath");
    this.goToPreviousPath();
  }
  goToPreviousPath = () => {
    // const lastLocation = withLastLocation();
    // console.log(lastLocation, "lastLocation");
    // history.goBack();
  };
  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  socialLogin = async (token) => {
    const url =
      "https://s9esltpwug.execute-api.ap-south-1.amazonaws.com/prod/social-login-attempt";
    try {
      let user = await axios.get(url + "?code=" + '"' + token + '"');
      if (user.attributes) {
        this.setState({ isSocial: false });
      }
      if (user) {
        const userData = {
          email: user.data.email,
          name: user.data.name,
          isSocial: true,
        };
        const userToken = user.data.tokens.id_token;
        this.props.loginWithGoogleCheck(userToken, userData);
        ToastsStore.success("Login Successful");
      }
    } catch (error) {
      ToastsStore.error(error.message);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // AWS Cognito integration here
    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      if (user) {
        const userData = {
          email: user.attributes.email,
          name: user.attributes.name,
          isSocial: false,
        };
        const userToken = user.signInUserSession.idToken.jwtToken;
        this.props.loginCheck(userToken, userData);
        ToastsStore.success("Login Successful");
      }
    } catch (error) {
      ToastsStore.error(error.message);
    }
  };

  componentWillReceiveProps(nextProps) {
    // var routeChanged = nextProps.location !== this.props.location;

    if (localStorage.getItem("matchUrl")) {
      this.props.history.push(localStorage.getItem("matchUrl"));
    } else if (localStorage.getItem("matchUrlSent")) {
      this.props.history.push(localStorage.getItem("matchUrlSent"));
    } else if (nextProps.userAuth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  onSuccessLogin = () => {
    const { location } = this.props;
    if (location.state && location.state.from) {
      this.props.history.push(location.state.from);
    } else {
      this.props.history.push("/");
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };
  render() {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="col-md-4 " />

          <div
            className="col-md-4"
            style={{
              background: "#f1f1f1",
              boxShadow:
                "0 0 0 -1px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12)",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            <h2>Login</h2>
            <form
              className="tg-formtheme tg-formloging"
              onSubmit={this.handleSubmit}
            >
              <fieldset>
                <div className="form-group">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={this.onInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={this.onInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <Link className="tg-forgetpassword" to={"forgot-password"}>
                    Forgot Password?
                  </Link>
                </div>
                <button className="tg-btn" type="submit">
                  Login
                </button>

                <Link
                  to={"register"}
                  className={"btn"}
                  style={{
                    border: "2px solid #5ecd68",
                    background: "transparent",
                    borderRadius: "25px",
                    padding: "11px",
                    width: "150px",
                    float: "right",
                  }}
                >
                  Register
                </Link>
              </fieldset>
            </form>

            <div className="col-xs-12">
              <div className={"text-center"}>
                <span
                  style={{
                    marginBottom: "-16px",
                    background: "#f1f1f1",
                    padding: "0px 10px",
                  }}
                >
                  Or Register with Social Account
                </span>
                <hr style={{ borderTop: "1px solid", marginTop: "-13px" }} />
              </div>

              <ul
                className="tg-sociallogingsignup"
                style={{ marginBottom: "30px" }}
              >
                <GoogleAuth />
                <FacebookAuth />
              </ul>

              <br />
              <br />
            </div>

            <div>
              <ToastsContainer store={ToastsStore} />
            </div>
          </div>

          <div className="col-md-4 " />
        </div>

        <Footer />
      </div>
    );
  }
}

Login.propTypes = {
  loginCheck: PropTypes.func.isRequired,
  loginWithGoogleCheck: PropTypes.func.isRequired,
  userAuth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  userAuth: state.userAuth,
});
export default connect(mapStateToProps, { loginCheck, loginWithGoogleCheck })(
  withRouter(Login)
);
