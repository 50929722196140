import React from "react";
import Sidebar from "./sidebar";
import "../public/css/dashboard.css";
import Header from "./header";
import axios from "axios";
import request from "request";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../store/actions/authActions";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import config from "../environment";
import Avtar from "../public/images/profile.svg";
import Logo from "../images-custom/logo/IP-footer.png";

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      email: localStorage.getItem("email"),
      Offers: [],
      token: localStorage.getItem("token"),
      show: true,
      title1: [],
      recivers: [],
      msg: [],
      ipId: null,
      msgId: null,
      msgContent: null,
      index: null,
      updateFlag: false,
      img: null,
      itemIndex: -1,
      recieverItemIndex: -1,
    };

    this.call = this.call.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickForChat = this.handleClickForChat.bind(this);
  }

  componentDidMount() {
    const { match, history } = this.props;
    // console.log(match.url, "matchUrl");
    if (!localStorage.getItem("token")) {
      localStorage.setItem("matchUrlSent", match.url);
      history.push("/login");
    }
    // Auth.currentAuthenticatedUser({
    //   bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    // });
    this.call();
  }

  async call() {
    const headers = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    const email = {
      email: localStorage.getItem("email"),
    };

    const result = await axios.get(
      config.production.api +
        'offers/fetchoffer?email="' +
        email.email +
        `"&flag="` +
        "true" +
        '"&token="' +
        headers.token +
        '"',
      { headers: headers }
    );
    this.setState({ Offers: result.data.data });
    let titleArr = [];
    let classArr = [];

    for (let i = 0; i < this.state.Offers.length; i++) {
      if (classArr.indexOf(this.state.Offers[i]["ipId"]) == -1) {
        classArr.push(this.state.Offers[i]["ipId"]);
        let obj = {
          class: this.state.Offers[i]["className"],
          title: this.state.Offers[i]["title"],
          ipId: this.state.Offers[i]["ipId"],
        };
        titleArr.push(obj);
      }
    }

    this.setState({ title1: titleArr });

    var event = new Event("title");

    this.handleClick(this.state.title1[0], event);
  }

  timeConverter(UNIX_timestamp) {
    let a = new Date(UNIX_timestamp);
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let time =
      date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  }

  async handleClick(item, e, index) {
    e.preventDefault();
    this.setState({
      itemIndex: index,
    });
    let data = [];
    for (let i = 0; i < this.state.Offers.length; i++) {
      if (
        this.state.Offers[i]["title"] == item.title &&
        this.state.Offers[i]["className"] == item.class &&
        this.state.Offers[i]["ipId"] == item.ipId
      ) {
        let obj = {};
        obj.id = this.state.Offers[i]["id"];
        obj.ipId = this.state.Offers[i]["ipId"];
        obj.receiver = this.state.Offers[i]["receiver"];
        obj.ipOwner = this.state.Offers[i]["ipOwner"];
        let img = await axios.get(
          config.production.api +
            `getprofilepic?email=%22${this.state.Offers[i]["receiver"]}%22`
        );
        obj.img = img.data;
        this.state.Offers[i]["img"] = img.data;
        data.push(obj);
      }
    }

    this.setState({ recivers: data });
    if (data.length > 0) this.state.img = data[0]["img"];
    this.state.updateFlag = !this.state.updateFlag;
    this.handleClickForChat(data[0], e);
  }

  updateChatForRealTime(item) {
    let index = null;
    for (let i = 0; i < this.state.Offers.length; i++) {
      if (this.state.Offers[i]["id"] == item) {
        this.state.msg = this.state.Offers[i]["message"];
        index = i;
        break;
      }
    }
    this.setState({ msg: this.state.Offers[index] });
    this.state.ipId = this.state.Offers[index]["ipId"];
    this.state.msgId = this.state.Offers[index]["id"];
    this.state.index = index;
  }

  handleClickForChat(item, e, indx) {
    e.preventDefault();
    this.setState({
      recieverItemIndex: indx,
    });
    let index = null;
    for (let i = 0; i < this.state.Offers.length; i++) {
      if (this.state.Offers[i]["id"] == item.id) {
        this.state.msg = this.state.Offers[i]["message"];
        index = i;
        break;
      }
    }
    this.setState({ msg: this.state.Offers[index] });
    if (this.state.Offers[index]) {
      this.state.ipId = this.state.Offers[index]["ipId"];
      this.state.msgId = this.state.Offers[index]["id"];
      this.state.img = this.state.Offers[index]["img"];
      this.state.index = index;
    }
  }

  updateMsg = (event) => {
    this.setState({ msgContent: event.target.value });
  };

  async handleKeyPress(event) {
    console.log("inside the handle key press method is called");
    event.preventDefault();
    event.target.value = "";
    let obj = {};
    let emailObj = {};
    obj[localStorage.getItem("name")] = this.state.msgContent.trim();

    emailObj[localStorage.getItem("email")] = obj;
    let offerDetailData = {
      messageId: this.state.msgId,
      id: this.state.ipId,
      message: emailObj,
      flag: false,
    };
    const result = await axios.post(
      `${config.production.api}offers/updatechat`,
      offerDetailData,
      { headers: { token: localStorage.getItem("token") } }
    );
    this.state.Offers[this.state.index]["message"].push(emailObj);
    this.setState({ msgContent: " " });
    this.updateChatForRealTime(this.state.msgId);
  }

  render() {
    const { msg, title1, recivers, img } = this.state;

    return (
      <div className={"user-ml"}>
        <Sidebar />
        <div>
          <Header />

          <div className="tg-dashboardbanner">
            <h1>Offers/Messages Sent</h1>
            <ol className="tg-breadcrumb">
              <li>
                <a href="javascript:void(0);">Home</a>
              </li>
              <li>
                <a href="javascript:void(0);">Dashboard</a>
              </li>
              <li className="tg-active">Offers/Messages Sent</li>
            </ol>
          </div>

          <main id="tg-main" className="tg-main tg-haslayout">
            <section class="tg-dbsectionspace tg-haslayout">
              <div class="row">
                <form class="tg-formtheme tg-formdashboard">
                  <fieldset>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div class="tg-dashboardbox">
                        <div class="tg-dashboardboxtitle">
                          <h2>Offers/Messages Sent</h2>
                        </div>
                        <div class="tg-dashboardholder tg-offersmessages tg-offersmessageswithsearch">
                          {this.state.Offers.length > 0 ? (
                            <ul>
                              <li>
                                <div class="tg-verticalscrollbar tg-dashboardscrollbar mCustomScrollbar _mCS_2">
                                  <div
                                    id="mCSB_2"
                                    class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                                    tabindex="0"
                                    style={{
                                      maxHeight: "none",
                                      overflow: "scroll",
                                    }}
                                  >
                                    <div
                                      id="mCSB_2_container"
                                      class="mCSB_container"
                                      style={{
                                        position: "relative",
                                        top: "0",
                                        left: "0",
                                      }}
                                      dir="ltr"
                                    >
                                      {title1.map((item, index) => (
                                        <div
                                          class={`tg-offerer ${
                                            this.state.itemIndex === index
                                              ? "activeIndex"
                                              : ""
                                          }`}
                                        >
                                          <button
                                            style={{
                                              width: "100%",
                                              border: "none",
                                              "background-color": "white",
                                              padding: "14px 28px",
                                              "font-size": "13px",
                                              cursor: "pointer",
                                              "text-align": "left",
                                            }}
                                            onClick={(e) =>
                                              this.handleClick(item, e, index)
                                            }
                                          >
                                            <div>{item.title}</div>
                                            <div> class {item.class}</div>
                                          </button>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="tg-offerers tg-verticalscrollbar tg-dashboardscrollbar mCustomScrollbar _mCS_3">
                                  <div
                                    id="mCSB_3"
                                    class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                                    tabindex="0"
                                    style={{
                                      maxHeight: "none",
                                      overflow: "scroll",
                                    }}
                                  >
                                    <div
                                      id="mCSB_3_container"
                                      class="mCSB_container"
                                      style={{
                                        position: "relative",
                                        top: "0",
                                        left: "0",
                                      }}
                                      dir="ltr"
                                    >
                                      {recivers.map((item, index) => (
                                        <div>
                                          <div
                                            class={`tg-offerer ${
                                              this.state.recieverItemIndex ===
                                              index
                                                ? "activeIndex"
                                                : ""
                                            }`}
                                          >
                                            <figure>
                                              {item.img !== null ? (
                                                <img
                                                  style={{
                                                    width: "35px",
                                                    height: "37px",
                                                  }}
                                                  src={item.img}
                                                  alt="image description"
                                                  class="mCS_img_loaded"
                                                />
                                              ) : (
                                                <img
                                                  src="/static/media/profile.7d5e6d1a.svg"
                                                  width={"50"}
                                                  alt="image description"
                                                  className="mCS_img_loaded"
                                                />
                                              )}
                                            </figure>
                                            <h3
                                              onClick={(e) =>
                                                this.handleClickForChat(
                                                  item,
                                                  e,
                                                  index
                                                )
                                              }
                                            >
                                              {item.ipOwner}
                                            </h3>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="tg-chatarea">
                                  <div class="tg-messages tg-verticalscrollbar tg-dashboardscrollbar mCustomScrollbar _mCS_4">
                                    <div
                                      id="mCSB_4"
                                      class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                                      tabindex="0"
                                      style={{
                                        maxHeight: "none",
                                        overflow: "scroll",
                                      }}
                                    >
                                      <div
                                        id="mCSB_4_container"
                                        class="mCSB_container"
                                        style={{
                                          position: "relative",
                                          top: "0",
                                          left: "0",
                                        }}
                                        dir="ltr"
                                      >
                                        {msg && msg["message"]
                                          ? msg["message"].map((item) => (
                                              <div>
                                                {Object.keys(item)[0]
                                                  .trim()
                                                  .toLowerCase() ==
                                                localStorage
                                                  .getItem("email")
                                                  .trim()
                                                  .toLowerCase() ? (
                                                  <div>
                                                    <div class="tg-offerermessage">
                                                      <figure>
                                                        {localStorage.getItem(
                                                          "profileUrl"
                                                        ) != "null" ? (
                                                          <img
                                                            src={localStorage.getItem(
                                                              "profileUrl"
                                                            )}
                                                            style={{
                                                              width: "35px",
                                                              height: "37px",
                                                            }}
                                                            alt="image description"
                                                            class="mCS_img_loaded"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={Avtar}
                                                            style={{
                                                              width: "35px",
                                                              height: "37px",
                                                            }}
                                                            alt="image description"
                                                            class="mCS_img_loaded"
                                                          />
                                                        )}
                                                      </figure>
                                                      <div class="tg-description">
                                                        <p>
                                                          {
                                                            Object.keys(
                                                              Object.values(
                                                                item
                                                              )[0]
                                                            )[0]
                                                          }
                                                          :{" "}
                                                          {
                                                            Object.values(
                                                              Object.values(
                                                                item
                                                              )[0]
                                                            )[0]
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <div class="tg-memessage tg-readmessage">
                                                      <figure>
                                                        {this.state.img ? (
                                                          <img
                                                            src={this.state.img}
                                                            style={{
                                                              width: "35px",
                                                              height: "37px",
                                                            }}
                                                            alt="image description"
                                                            class="mCS_img_loaded"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={Avtar}
                                                            style={{
                                                              width: "35px",
                                                              height: "37px",
                                                            }}
                                                            alt="image description"
                                                            class="mCS_img_loaded"
                                                          />
                                                        )}
                                                      </figure>
                                                      <div class="tg-description">
                                                        <p>
                                                          {
                                                            Object.keys(
                                                              Object.values(
                                                                item
                                                              )[0]
                                                            )[0]
                                                          }
                                                          :{" "}
                                                          {
                                                            Object.values(
                                                              Object.values(
                                                                item
                                                              )[0]
                                                            )[0]
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            ))
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="tg-replaybox">
                                    {msg && msg["message"] ? (
                                      <div>
                                        <textarea
                                          class="form-control"
                                          name="reply"
                                          placeholder="Type Here &amp; Press Enter"
                                          value={this.state.msgContent}
                                          onChange={(e) => this.updateMsg(e)}
                                        ></textarea>
                                        <button
                                          className="btn"
                                          style={{
                                            background: "#00cc67",
                                            color: "white",
                                            fontWeight: "700",
                                            margin: "10px",
                                            float: "right",
                                          }}
                                          onClick={(e) => {
                                            this.handleKeyPress(e);
                                          }}
                                        >
                                          Send
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          ) : (
                            <div className="text-center">
                              <h3>No Offers Available</h3>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

Offers.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  userAuth: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userAuth: state.userAuth,
  userData: state.userData,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Offers));
