import React from 'react';
import { Link } from "react-router-dom";


const NAVBAR = ()=> {
    return (
        <div>
            <nav class="tg-navtabledata" style={{ marginTop: "30px",marginLeft:'30px' }} >
    <ul>
      <li>
        <Link to={"/dashboard/admin/"} style={{ float: "right" }}>
            All Listed IPs
        </Link>
      </li>
      <li>
        <Link to={"/dashboard/admin/approved"} style={{ float: "right" }}>
          Approved IPs
        </Link>
      </li>
      <li>
        <Link to={"/dashboard/admin/pending"} style={{ float: "right" }}>
          Pending IPs
        </Link>
      </li>
      <li>
        <Link to={"/dashboard/admin/rejected"} style={{ float: "right" }}>
          Rejected IPs
        </Link>
      </li>
    </ul>
</nav>
        </div>
    )
}

export default NAVBAR;


