import React, { Component } from 'react';
import Navbar from '../includes/navbar';
import Footer from '../includes/footer';





class privacy extends Component {

    render() {

        return (
            <div>
                <Navbar />

                        <div className="container">
                            <div className="box"  style={{marginTop:'200px', marginBottom:'40px', background:'white'}}>

                                <div className="tg-titleshortcode">
                                    <h2>Privacy Policy</h2>
                                </div>

                                <p>EFFECTIVE DATE: [15-5-19]</p>

                                    <p>GENIE IP SERVICES PVT. LTD. ("us", "we", or "our") operates the brand TRADEMYIP through www.trademyip.com and www.trademyip.in (the "Website").</p>
                                    <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Website and the choices you have associated with that data. This privacy policy applies to the website and all of the products and services offered that are described in Terms and Conditions.</p>
                                    <p>We use your data to provide and improve the Website. By using the Website, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>


                                    <h3>Information Collection and Use</h3>
                                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                                    <h3>Types of Data Collected</h3>
                                    <h4>- Personal Data</h4>

                                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>


                                    <u>
                                        <li>Email address</li>
                                        <li>First name and last name</li>
                                        <li>Phone number</li>
                                        <li>Address, State, Province, ZIP/Postal code, City</li>
                                        <li>Cookies and Usage Data</li>
                                    </u>

                                    <h4>- Usage Data</h4>

                                    <p>We may also collect information that your browser sends whenever you visit our Website or when you access the Website by or through a mobile device ("Usage Data").</p>

                                    <p>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

                                    <p>When you access the Website by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>



                                    <h3>Tracking & Cookies Data</h3>
                                    <p>We may use cookies and similar tracking technologies to track the activity on our Website and hold certain information.</p>
                                    <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

                                    <h3>Examples of Cookies we may use</h3>
                                    <p><b>Session Cookies</b> - We may use Session Cookies to operate our Website.</p>
                                    <p><b>Preference Cookies</b> - Preference Cookies. We may use Preference Cookies to remember your preferences and various settings.</p>
                                    <p><b>Security Cookies</b> - We may use Security Cookies for security purposes.</p>


                                    <h3>Use of Data</h3>
                                    <p>We use the collected data for various purposes:</p>

                                    <ul>
                                        <li>To provide and maintain the Website</li>
                                        <li>To notify you about changes to our Website</li>
                                        <li>To allow you to participate in interactive features of our Website when you choose to do so</li>
                                        <li>Address, State, Province, ZIP/Postal code, City</li>
                                        <li>To provide customer care and support</li>
                                        <li>To provide analysis or valuable information so that we can improve the Website</li>
                                        <li>To monitor the usage of the Website</li>
                                        <li>To detect, prevent and address technical issues</li>
                                    </ul>



                                    <h3>Transfer of Data</h3>
                                    <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
                                    <p>If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there.</p>
                                    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                                    <p>We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>



                                    <h3>Disclosure of Data</h3>
                                    <h4>Legal Requirements</h4>
                                    <p>We may disclose your Personal Data in the good faith belief that such action is necessary to</p>

                                    <ul>
                                        <li>To comply with a legal obligation</li>
                                        <li>To protect and defend the rights or property of GENIE IP SERVICES PVT. LTD.</li>
                                        <li>To prevent or investigate possible wrongdoing in connection with the Website</li>
                                        <li>To protect against legal liability</li>
                                    </ul>


                                    <h3>Security of Data</h3>
                                    <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>


                                    <h3>Service Providers</h3>
                                    <p>We may employ third party companies and individuals to facilitate our Website ("Service Providers"), to provide the Website on our behalf, to perform Website-related services or to assist us in analyzing how our Website is used and to send newsletters or surveys.
                                    </p><p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>



                                    <h3>Links To Other Sites</h3>
                                    <p>Our Website may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>



                                    <h3>Children's Privacy</h3>
                                    <p>Our Website does not address anyone under the age of 18 ("Children").</p>
                                    <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>



                                    <h3>Changes To This Privacy Policy</h3>
                                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                                    <p>We will let you know via email and/or a prominent notice on our Website, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>


                                    <h3>Contact Us</h3>
                                    <p>If you have any questions about this Privacy Policy, please contact us at info@trademyip.com.</p>
                                </div>

                            </div>


                <Footer />
            </div>
        );
    }
}

export default privacy;
