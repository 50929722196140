import React from "react";
import Header from "../user/header";
import Sidebar from "../user/sidebar";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { Carousel } from "react-responsive-carousel";
import Patents from "../images-custom/ip-logos/patent.jpg";
import config from "../environment";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      ip: [],
      images: [],
      documents: [],
      deviceImg: [],
      links: [],
      update: { category: null, id: null, current_status: null },
      reject: { category: null, id: null, current_status: null, message: null },
      class: null,
    };
    this.approve = this.approve.bind(this);
    this.reject = this.reject.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.loadIP();
  }

  loadIP = () => {
    const { id } = this.props.match.params;
    const { type } = this.props.match.params;

    console.log(id, type, "URL DATA");

    const url =
      config.production.api + `fetchbyid?category=%22${type}%22&id=%22${id}%22`;

    axios.get(url).then((response) => {
      this.setState({
        ip: response.data.Item,
        images: response.data.Item.images,
        documents: response.data.Item.documents,
        links: response.data.Item.links,
      });
      this.setState({
        update: {
          category: this.state.ip.category,
          id: this.state.ip.id,
          current_status: !this.state.ip.isApproved,
        },
      });
      this.setState({
        reject: {
          category: this.state.ip.category,
          id: this.state.ip.id,
          current_status: !this.state.ip.isApproved,
        },
      });

      if (type == "trademark") {
        this.setState({ class: response.data.Item.classes.value });
      }
    });
  };

  approve(event) {
    event.preventDefault();

    const header = {
      "Content-Type": "Application/JSON",
      Token: localStorage.getItem("token"),
    };
    try {
      const response = axios.patch(
        config.production.api + "approve",
        this.state.update,
        { headers: header }
      );
      this.loadIP();
      ToastsStore.success("Status Updated Successfully");
    } catch (e) {
      console.log(`Axios request failed: ${e}`);
      ToastsStore.error("Something went wrong");
    }
  }

  reject(event) {
    event.preventDefault();
    const header = {
      "Content-Type": "Application/JSON",
      Token: localStorage.getItem("token"),
    };

    const data = this.state.reject;

    try {
      const response = axios.patch(config.production.api + "reject", data, {
        headers: header,
      });
      this.loadIP();
      ToastsStore.error("REJECTED");
    } catch (e) {
      console.log(`Axios request failed: ${e}`);
      ToastsStore.error("Something went wrong");
    }
  }

  handleChange(event) {
    this.state.reject.message = event.target.value;
    console.log("state", this.state);
    this.setState(this.state);
  }

  render() {
    const { ip, documents, images, links, deviceImg } = this.state;

    let imgValues = [];

    if (images && images != "NA") imgValues = Object.values(images);
    const docValues = Object.values(documents);
    if (deviceImg && Object.keys(deviceImg).length > 0) {
      let devImg = Object.values(deviceImg);
      imgValues.push(devImg);
    }

    return (
      <div style={{ marginLeft: "250px" }}>
        <Sidebar />
        <Header />

        <div>
          <div className="tg-dashboardbanner">
            <h1>ADMIN - User IP Listing</h1>
            <ol className="tg-breadcrumb">
              <li>
                <a href="javascript:void(0);">Admin</a>
              </li>
              <li>
                <a href="javascript:void(0);">User Listing</a>
              </li>
            </ol>
          </div>

          <main id="tg-main" className="tg-main tg-haslayout">
            <section className="tg-dbsectionspace tg-haslayout">
              <div className="row">
                <form className="tg-formtheme tg-formdashboard">
                  <fieldset>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="tg-dashboardbox">
                        <div className="tg-dashboardboxtitle">
                          <h2>User IP Listing</h2>
                        </div>

                        <div
                          className={"col-sm-12"}
                          style={{ marginBottom: "75px" }}
                        >
                          <div className={"col-sm-6"}>
                            <table
                              id="tg-adstype"
                              class="table tg-dashboardtable tg-tablemyads"
                            >
                              <tbody>
                                <tr>
                                  <td>Title</td>
                                  <td>
                                    <h4>{ip.title}</h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Type</td>
                                  <td>
                                    <h4> {ip.category}</h4>
                                  </td>
                                </tr>

                                {ip.category == "copyright" ? (
                                  <tr>
                                    <td>Work Type</td>
                                    <td>
                                      <h4> {ip.work_type}</h4>
                                    </td>
                                  </tr>
                                ) : null}

                                {ip.category == "trademark" ? (
                                  <tr>
                                    <td>Application Number</td>
                                    <td>
                                      <h4> {ip.application_number}</h4>
                                    </td>
                                  </tr>
                                ) : null}

                                {ip.category == "patent" ? (
                                  <tr>
                                    <td>Application Number</td>
                                    <td>
                                      <h4> {ip.patent_number}</h4>
                                    </td>
                                  </tr>
                                ) : null}

                                {ip.category == "patent" ? (
                                  <tr>
                                    <td>Priority Country</td>
                                    <td>
                                      <h4>{ip.priority_country}</h4>
                                    </td>
                                  </tr>
                                ) : null}

                                {ip.category == "design" ||
                                ip.category == "copyright" ? (
                                  <tr>
                                    <td>Registration</td>
                                    <td>
                                      <h4>{ip.registration_number}</h4>
                                    </td>
                                  </tr>
                                ) : null}

                                <tr>
                                  <td>Summary</td>
                                  <td>
                                    <h4> {ip.summary}</h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Uploaded by</td>
                                  <td>
                                    <h4> {ip.user}</h4>
                                    {ip.email}
                                  </td>
                                </tr>

                                {ip.category == "patent" ? null : (
                                  <tr>
                                    <td>Country</td>
                                    <td>
                                      <h4> {ip.country}</h4>
                                    </td>
                                  </tr>
                                )}

                                {this.state.class ? (
                                  <tr>
                                    <td>Class</td>
                                    <td>
                                      <h4>{this.state.class}</h4>
                                    </td>
                                  </tr>
                                ) : null}
                                <tr>
                                  <td>Links</td>
                                  <td>
                                    {links.map((link) => (
                                      <div>
                                        {" "}
                                        {link.text !== "na" &&
                                        link.text !== "NA" ? (
                                          <a href={link.url} target="_blank">
                                            {link.text}
                                          </a>
                                        ) : (
                                          <p>No links available</p>
                                        )}{" "}
                                        <br />
                                      </div>
                                    ))}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Status</td>
                                  <td>
                                    <h4>
                                      {" "}
                                      {ip.isApproved
                                        ? "Approved"
                                        : "Not Approved"}
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Price</td>
                                  <td>
                                    <h4> INR {ip.price}</h4>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Action</td>
                                  <td>
                                    <a
                                      class="btn"
                                      href={"/" + ip.category + "s/ip/" + ip.id}
                                      target="_blank"
                                      style={{
                                        cursor: "pointer",
                                        background: "green",
                                        color: "white",
                                      }}
                                    >
                                      View IP
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            {ip.documents && ip.documents !== "NA" ? (
                              <div className="col-sm-12 bg-dark p-5 doc">
                                <h2 className="text-white">
                                  <span style={{ background: "#363b4d" }}>
                                    Documentation
                                  </span>
                                </h2>
                                <p className="text-white">Attached files</p>

                                <div>
                                  <div className="" style={{ width: "50px" }}>
                                    <span
                                      className="text-white"
                                      style={{ fontSize: "2em" }}
                                    >
                                      <a href={ip.documents[0]} target="_blank">
                                        <i
                                          className="fa fa-download"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </span>
                                  </div>
                                  <div className="" style={{ width: "60%" }}>
                                    {docValues.map((link) => (
                                      <div>
                                        <a href={link} target="_blank">
                                          <span className="text-white">
                                            Doc.pdf
                                          </span>
                                        </a>
                                        <br />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className={"col-sm-6"}>
                            {imgValues.length > 0 ? (
                              <div>
                                <Carousel>
                                  {imgValues.map((img) => (
                                    <div>
                                      <img
                                        src={img}
                                        style={{
                                          width: "100%",
                                          height: "inherit",
                                        }}
                                      />
                                    </div>
                                  ))}
                                </Carousel>
                              </div>
                            ) : (
                              <h3>No images</h3>
                            )}
                          </div>

                          <div>
                            <ToastsContainer store={ToastsStore} />
                          </div>
                        </div>

                        <div
                          className={"row mt-5"}
                          style={{ paddingBottom: "50px", paddingTop: "50px" }}
                        >
                          <div className={"col-sm-4 text-center"}>
                            <div class="col">
                              {ip.isApproved ? (
                                <button
                                  className={"btn btn-danger"}
                                  onClick={this.approve}
                                  style={{ width: "75%" }}
                                >
                                  Disapprove IP
                                </button>
                              ) : (
                                <button
                                  className={"btn btn-success"}
                                  onClick={this.approve}
                                  style={{ width: "75%" }}
                                >
                                  Approve IP
                                </button>
                              )}

                              <Link to={"/dashboard/admin"}>
                                <button
                                  className={"btn btn-info"}
                                  style={{ width: "75%", marginTop: "50px" }}
                                >
                                  Cancel
                                </button>
                              </Link>
                            </div>
                          </div>
                          <div className={"col-sm-4 text-center"}></div>

                          <div className={"col-sm-4 text-center"}>
                            <br />

                            <div className={"text-left"}>
                              {!ip.isRejected ? (
                                <div class="form-group">
                                  <input
                                    type="textarea"
                                    name="message"
                                    onChange={this.handleChange}
                                    class="form-control"
                                    placeholder="Reason for rejection"
                                  />
                                </div>
                              ) : null}

                              {ip.isRejected ? (
                                <a href={"#"} className={"btn btn-warning"}>
                                  Rejected
                                </a>
                              ) : (
                                <button
                                  className={"btn btn-danger"}
                                  style={{ width: "75%" }}
                                  onClick={this.reject}
                                >
                                  Reject
                                </button>
                              )}
                            </div>

                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default Admin;
