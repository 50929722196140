import React, { Component } from 'react';
import Navbar from '../includes/navbar';
import Footer from '../includes/footer';
import Trademarks from '../images-custom/ip-logos/trademark.jpg';
import Patents from '../images-custom/ip-logos/patent.jpg';
import Design from '../images-custom/ip-logos/design.jpg';
import Copyright from '../images-custom/ip-logos/copyright.jpg';


const imgWidth = {
    width: '200px',
};

const imgWidth2 = {
    width: '175px',
};




class Home extends Component {

    render() {

        return (
            <div>
               <Navbar />

                <div className="container">
                        <section className="tg-sectionspace tg-haslayout" style={{paddingBottom:'0px'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="tg-aboutus">
                                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6">
                                            <div className="tg-textshortcode">
                                                <div className="tg-titleshortcode">
                                                    <h2><span>Welcome!</span>About TradeMyIP</h2>
                                                </div>
                                                <div className="tg-description" style={{textAlign:'justify'}}>
                                                    <p>"IP refers to creation of minds. It is important to own and protect your IP as the progress and well-being of humanity rest on its capacity to create and invent new works in the areas of technology and culture." – WIPO</p>

                                                    <p>Awarding an IP is not enough. It needs to be used for the purposes of economic growth, research, creation of jobs, etc. Non-use of an IP not only hampers that but also prevents others willing to use and invent in that area.</p>

                                                    <p>More than 75% of Patents go waste as they are not being used, either due to high cost of money spent on getting it, or not able to find the buyer/licensee for manufacturing of the products. Similarly, non-use of registered Trade marks after a certain period can be cancelled/revoked. Instead, selling or licensing to a potential buyer/licensee can ensure that the IP does not go waste and be used for greater good.</p>
                                                
                                                    <h3>OUR MISSION</h3>
                                                    <p>The basic idea of TRADEMYIP is not only to <b>protect the creation of minds</b> but also allow the creators or owners of IP to <b>exchange their creativity</b> with others who might be able to use it for greater good of humanity.</p>

                                                    <h3>OUR TECHNOLOGY</h3>
                                                    <p>TRADEMYIP is a cloud based service using the cutting-edge technology built on Amazon Web Services using the serverless stack making it safe, reliable and highly scalable.</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6">
                                            <div className="tg-imgshortcode tg-multiimg">
                                                <figure>
                                                    <div className="tg-imgholder imgFigure"><img src={Trademarks} style={imgWidth} alt="image description" /></div>

                                                </figure>
                                                <figure>
                                                    <div className="tg-imgholder imgFigure"><img src={Patents} style={imgWidth} alt="image description" /></div>
                                                </figure>
                                                <figure>
                                                    <div className="tg-imgholder imgFigure"><img src={Design}  style={imgWidth}  alt="image description" /></div>
                                                </figure>
                                                <figure>
                                                    <div className="tg-imgholder"><img src={Copyright} style={imgWidth} alt="image description" /></div>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*<section className="tg-sectionspace tg-bglight tg-haslayout">*/}
                            {/*<div className="container">*/}
                                {/*<div className="row">*/}
                                    {/*<ul className="tg-statistics">*/}
                                        {/*<li>*/}
                                            {/*<div className="tg-statisticicon"><i className="icon-smile"></i></div>*/}
                                            {/*<h3 data-from="0" data-to="246321" data-speed="8000"*/}
                                                {/*data-refresh-interval="50">246321</h3>*/}
                                            {/*<h4>Happy Customers</h4>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                            {/*<div className="tg-statisticicon"><i className="icon-user"></i></div>*/}
                                            {/*<h3 data-from="0" data-to="423156" data-speed="8000"*/}
                                                {/*data-refresh-interval="50">423156</h3>*/}
                                            {/*<h4>Active Members</h4>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                            {/*<div className="tg-statisticicon"><i className="icon-bookmark"></i></div>*/}
                                            {/*<h3 data-from="0" data-to="246321" data-speed="8000"*/}
                                                {/*data-refresh-interval="50">246321</h3>*/}
                                            {/*<h4>Verified Ads</h4>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                            {/*<div className="tg-statisticicon"><i className="icon-coffee-cup"></i></div>*/}
                                            {/*<h3 data-from="0" data-to="74231" data-speed="8000"*/}
                                                {/*data-refresh-interval="50">74231</h3>*/}
                                            {/*<h4>Cup of Coffee</h4>*/}
                                        {/*</li>*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</section>*/}


                        <section className="tg-sectionspace tg-haslayout" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-push-2 col-lg-8">
                                        <div className="tg-sectionhead tg-sectionheadvtwo">
                                            <div className="tg-title">
                                                <h2>Why We Are Best</h2>
                                            </div>
                                            <div className="tg-description">
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tg-features">
                                        <div className="row">
                                            <div className="col-md-6 col-sm6">
                                                <li style={{width:'100%'}}>
                                                    <div className="tg-feature">
                                                        <div className="tg-featureicon"><i className="icon-eye"></i></div>
                                                        <div className="tg-title"><h3><a href="javascript:void(0);">Eye on Quality</a></h3></div>
                                                        <div className="tg-description">
                                                            <p>We are supported by team of experts with more than 45 years of experience. We believe in provideing a personalized service.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                            <div className="col-md-6 col-sm6">
                                                <li style={{width:'100%'}}>
                                                    <div className="tg-feature">
                                                        <div className="tg-featureicon"><i className="icon-lock"></i></div>
                                                        <div className="tg-title"><h3><a href="javascript:void(0);">Protection Guaranteed</a></h3></div>
                                                        <div className="tg-description">
                                                            <p>We believe in protecting user information by using the best security methods.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                            <div className="col-md-6 col-sm6">
                                                <li style={{width:'100%'}}>
                                                    <div className="tg-feature">
                                                        <div className="tg-featureicon"><i className="icon-laptop"></i></div>
                                                        <div className="tg-title"><h3><a href="javascript:void(0);">Prompt Complaint Response</a></h3></div>
                                                        <div className="tg-description">
                                                            <p>We are on top of it. We will surely resolve it within 24 hours.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                            <div className="col-md-6 col-sm6">
                                                <li style={{width:'100%'}}>
                                                    <div className="tg-feature">
                                                        <div className="tg-featureicon"><i className="icon-spell-check"></i>
                                                        </div>
                                                        <div className="tg-title"><h3><a href="javascript:void(0);">Verified
                                                            Ads</a></h3></div>
                                                        <div className="tg-description">
                                                            <p>All listings are verified for their veracity by TRADEMYIP. No spams or junk ads.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        {/*<li>*/}
                                            {/*<div className="tg-feature">*/}
                                                {/*<div className="tg-featureicon"><i className="icon-bubble"></i></div>*/}
                                                {/*<div className="tg-title"><h3><a href="javascript:void(0);">Active*/}
                                                    {/*Support</a></h3></div>*/}
                                                {/*<div className="tg-description">*/}
                                                    {/*<p>Consectetur adipicing elit sed dotam eiusmod tempor incididunt*/}
                                                        {/*labe etae magna aliqua.</p>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</li>*/}

                                        {/*<li>*/}
                                            {/*<div className="tg-feature">*/}
                                                {/*<div className="tg-featureicon"><i className="icon-leaf"></i></div>*/}
                                                {/*<div className="tg-title"><h3><a href="javascript:void(0);">Secure*/}
                                                    {/*Payment Gateway</a></h3></div>*/}
                                                {/*<div className="tg-description">*/}
                                                    {/*<p>Consectetur adipicing elit sed dotam eiusmod tempor incididunt*/}
                                                        {/*labe etae magna aliqua.</p>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</li>*/}
                                    </div>
                                </div>
                            </div>
                        </section>

                </div>



               <Footer />
            </div>
        );
    }
}

export default Home;
