import React from "react";
import Header from "../user/header";
import Sidebar from "../user/sidebar";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../environment";
import IconImg from "../images-custom/logo/IP-icon.png";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AdminNav from "./adminNav";

class approvedIps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      ipListing: [],
      limit: 100,
      category: 0,
      id: 0,
    };
  }

  componentDidMount() {
    this.approved();
  }

  approved = () => {
    const { token, category, id } = this.state;

    axios
      .get(
        config.production.api +
          'fetchbylimit?token="' +
          token +
          '"&category="' +
          category +
          '"&id="' +
          id +
          '"&isApproved="1"',
        { headers: { token: token } }
      )
      .then((response) => {
        this.setState({ ipListing: response.data.data });

        this.setState({
          category: response.data.data.LastEvaluatedKey.category,
        });
        this.setState({ id: response.data.data.LastEvaluatedKey.id });
        console.log(
          "last evaluated key from response",
          response.data.data.LastEvaluatedKey
        );

        //console.log(response.data.data.Items)
      })
      .catch((error) => {
        console.log("CATCH", error);
      });
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            padding: "5px",
            fontSize: "15px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: "5px",
            fontSize: "15px",
            background: "#f7f7f7",
          },
        },
      },
    });

  render() {
    const { ipListing } = this.state;
    const columns = [
      "Photo",
      "Title",
      "Category",
      "Price",
      "Status",
      "Agreement",
      "IP Status",
      "More",
    ];
    const options = {
      filterType: "dropdown",
      responsive: "scroll",
      selectableRows: false,
    };
    return (
      <div style={{ marginLeft: "250px" }}>
        <Sidebar />
        <Header />

        <div>
          <main id="tg-main" className="tg-main tg-haslayout">
            <section className="tg-dbsectionspace tg-haslayout">
              <div className="row">
                <form className="tg-formtheme tg-formdashboard">
                  <fieldset>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="tg-dashboardbox">
                        <div className="tg-dashboardboxtitle">
                          <h2>User IP Listing</h2>
                        </div>

                        <AdminNav />
                        <div className="tg-dashboardholder">
                          {ipListing.length == 0 ? (
                            <div className="tg-otherfilters">
                              <div className="row">
                                <div className="col-xs-12 text-center">
                                  <h3>No IP Listed</h3>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <MuiThemeProvider theme={this.getMuiTheme()}>
                                <MUIDataTable
                                  title="Documents"
                                  data={ipListing.map((List) => {
                                    return [
                                      List.images === "NA" ? (
                                        <img
                                          src={IconImg}
                                          style={{ width: "100px" }}
                                          alt="image description"
                                        ></img>
                                      ) : (
                                        <img
                                          src={List.images[0]}
                                          style={{ width: "150px" }}
                                          alt="image description"
                                        ></img>
                                      ),
                                      List.title,
                                      List.category,
                                      List.price,
                                      List.status,
                                      List.agreement,
                                      List.isApproved ? (
                                        <span class="tg-adstatus tg-adstatusactive">
                                          Approved
                                        </span>
                                      ) : (
                                        <div>
                                          {List.isRejected ? (
                                            <span class="tg-adstatus tg-adstatusdeleted">
                                              Rejected
                                            </span>
                                          ) : (
                                            <span class="tg-adstatus tg-adstatusexpired">
                                              Pending
                                            </span>
                                          )}{" "}
                                        </div>
                                      ),
                                      <div class="tg-btnsactions">
                                        <a
                                          class="tg-btnaction tg-btnactionview"
                                          href={
                                            "/" +
                                            List.category +
                                            "s/ip/" +
                                            List.id
                                          }
                                          target="_blank"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i class="fa fa-eye"></i>
                                        </a>
                                        <Link
                                          to={
                                            "/dashboard/admin/ip/" +
                                            List.category +
                                            "/" +
                                            List.id +
                                            "/"
                                          }
                                          params={{
                                            id: List.id,
                                            type: List.category,
                                          }}
                                          class="tg-btnaction tg-btnactionview"
                                          href="javascript:void(0);"
                                        >
                                          <i class="fa fa-pencil"></i>
                                        </Link>
                                      </div>,
                                    ];
                                  })}
                                  columns={columns}
                                  options={options}
                                />
                              </MuiThemeProvider>
                              {/* <div className="col-sm-12">
                                                        <span className='btn btn-success' style={{float:'right'}} onClick={this.next}>Next</span>
                                                        </div> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default approvedIps;
