import React, { Component } from "react";
import axios from "axios";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { Auth } from "aws-amplify";
import config from "../../environment";
var Loader = require("react-loader");
registerPlugin(FilePondPluginFileValidateType);
class Design extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      country: "",
      applicationNumber: "",
      status: "",
      price: "",
      agreement: "",
      links: [{ text: "NA", url: "NA" }],
      summary: "",
      imageFile: [],
      classes: null,
      countriesList: [],
      document: [],
      user: [],
      classesList: [],
      loaded: false,
      token: localStorage.getItem("token"),
      formErrors: {
        title: {
          valid: "",
          error: "",
        },
        country: {
          valid: "",
          error: "",
        },
        applicationNumber: {
          valid: "",
          error: "",
        },
        status: {
          valid: "",
          error: "",
        },
        price: {
          valid: "",
          error: "",
        },
        agreement: {
          valid: "",
          error: "",
        },
        classes: {
          valid: "",
          error: "",
        },
        image: {
          valid: "",
          error: "",
        },
      },
      documentValid: true,
      documentError: "",
      imageInvalidType: true,
      isFormValid: false,
      isFileSectionTriggered: false,
      uploadedDoc: [],
      uploadedImage: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }
  componentDidMount() {
    this.getUser();
    this.getCountriesList();
    this.getClassesList();
  }

  getUser() {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        this.setState({ user: user.attributes });
      })
      .catch((err) => console.log(err));
  }

  async onSubmit(e) {
    e.preventDefault();

    if (this.state.isFormValid) {
      this.postFileData();
    }
  }
  getClassesList() {
    //this.state.classesList.push({ class: 99 });
    for (let i = 1; i <= 32; i++) {
      this.state.classesList.push({ class: i });
    }
  }
  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.validateField(name, value);
    });

    console.log(this.state, "NEW");
  }
  validateField(fieldName, value) {
    let fieldValidation = this.state.formErrors;
    switch (fieldName) {
      case "title":
        fieldValidation.title.valid = value !== "";
        fieldValidation.title.error = fieldValidation.title.valid
          ? ""
          : "title can't be empty";
        break;
      case "country":
        fieldValidation.country.valid = value !== "0";
        fieldValidation.country.error = fieldValidation.country.valid
          ? ""
          : "country can't be empty";
        break;
      case "applicationNumber":
        fieldValidation.applicationNumber.valid = value !== "";
        fieldValidation.applicationNumber.error = fieldValidation
          .applicationNumber.valid
          ? ""
          : "application number can't be empty";
        break;
      case "status":
        fieldValidation.status.valid = value !== "0";
        fieldValidation.status.error = fieldValidation.status.valid
          ? ""
          : "status can't be empty";
        break;
      case "agreement":
        fieldValidation.agreement.valid = value !== "0";
        fieldValidation.agreement.error = fieldValidation.agreement.valid
          ? ""
          : "agreement can't be empty";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidation,
      },
      this.validateForm
    );
  }

  validateForm() {
    const {
      formErrors,
      imageFile,
      document,
      isFileSectionTriggered,
      documentError,
      documentValid,
      imageInvalidType,
    } = this.state;
    if (isFileSectionTriggered) {
      formErrors.image.valid = imageFile.length ? true : false;
      formErrors.image.error = formErrors.image.valid
        ? ""
        : "at least one image is required";
      if (document.length) {
        if (documentError.length) {
          this.setState({ documentValid: false });
        }
      } else {
        this.setState({ documentValid: true });
      }
    }
    if (imageFile.length) {
      this.setState({ imageInvalidType: true });
    }
    this.setState({
      isFormValid:
        formErrors.title.valid &&
        formErrors.country.valid &&
        formErrors.applicationNumber.valid &&
        formErrors.status.valid &&
        formErrors.agreement.valid &&
        formErrors.image.valid &&
        imageInvalidType &&
        this.state.documentValid,
    });
  }
  handleLinkChange = (e) => {
    if (["text", "url"].includes(e.target.name)) {
      let links = [...this.state.links];
      links[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ links });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addLinks = (e) => {
    this.setState((prevState) => ({
      links: [...prevState.links, { text: "", url: "" }],
    }));
  };
  removeLinks = (index) => {
    this.setState({
      links: this.state.links.filter((v, i) => i !== index),
    });
  };

  async postFileData() {
    //start the loader
    this.setState({ loaded: true });
    const images = [];
    const documents = [];
    let allDocuments = {};
    let allImages = {};

    for (let counter = 0; counter < this.state.imageFile.length; counter++) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(this.state.imageFile[counter]);
      });
      images.push(result_base64);
    }

    for (let counter = 0; counter < this.state.document.length; counter++) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(this.state.document[counter]);
      });
      documents.push(result_base64);
    }

    await Promise.all(
      images.map(async (item, index) => {
        delete axios.defaults.headers.common["token"];
        let patch = {
          email: this.state.user.email,
          category: this.props.category,
          file: item,
          type: "image",
        };
        await axios
          .post(`${config.production.api}fileupload`, patch)
          .then((res) => {
            allImages["key" + index] = res.data.body;
            this.setState({
              uploadedImage: [...this.state.uploadedImage, res.data.body],
            });
            console.log(this.state.uploadedImage, "images");
          })
          .catch((error) => {
            console.log(error);
          });
      })
    );

    await Promise.all(
      documents.map(async (item, index) => {
        let patch = {
          email: this.state.user.email,
          type: "document",
          file: item,
          category: this.props.category,
        };
        await axios
          .post(`${config.production.api}fileupload`, patch)
          .then((res) => {
            allDocuments["key" + index] = res.data.body;
            this.setState((prevState) => ({
              uploadedDoc: [...prevState.uploadedDoc, res.data.body],
            }));
          })
          .catch((error) => {
            console.log("error is", error);
          });
      })
    );

    this.postDesign(allImages, allDocuments);
  }

  async postDesign(allImg, allDoc) {
    const {
      title,
      country,
      applicationNumber,
      status,
      price,
      classes,
      agreement,
      links,
      summary,
      user,
      token,
      imageFile,
      document,
    } = this.state;

    const designData = {
      title: title !== "" ? title : "NA",
      country: country !== "" ? country : "NA",
      registration_number: applicationNumber !== "" ? applicationNumber : "NA",
      classes: classes !== "" ? classes : "NA",
      status: status !== "" ? status : "NA",
      price: price !== "" ? price : "NA",
      agreement: agreement !== "" ? agreement : "NA",
      links: links !== "" ? links : [{ text: "NA", url: "NA" }],
      summary: summary !== "" ? summary : "NA",
      images: this.state.uploadedImage.length ? this.state.uploadedImage : "NA",
      documents: this.state.uploadedDoc.length.length
        ? this.state.uploadedDoc.length
        : "NA",
      category: this.props.category,
      email: localStorage.getItem("email"),
      user: localStorage.getItem("name"),
    };

    try {
      let abc = this.props.history;
      let response = await axios.post(
        `${config.production.api}newpost/design`,
        designData,
        { headers: { token: token } }
      );
      if (response.data.success) {
        this.setState({ loaded: false });
        ToastsStore.success(
          "IP submitted successfully and is in under review."
        );
        setTimeout(function () {
          abc.push("/dashboard/all"); // Delay time by 3 Sec
        }, 3000);
      } else {
        this.setState({ loaded: false });
        ToastsStore.info("Please try again");
      }
    } catch (error) {
      this.setState({ loaded: false });
      ToastsStore.error(
        "IP could not be posted due to Network Error, please try again later."
      );
    }
  }
  async getCountriesList() {
    let response = await axios.get("https://restcountries.eu/rest/v1/all", {});
    this.setState({ countriesList: response.data });
  }
  handleListChange = async (classes) => {
    await this.setState({ classes: classes });
    this.validateField("classes", classes);
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }
  render() {
    const {
      countriesList,
      documentError,
      links,
      formErrors,
      classesList,
    } = this.state;

    const errorStyle = {
      color: "red",
      fontSize: "10px",
      fontWeight: 400,
    };

    const Link = (
      <div onChange={this.handleLinkChange}>
        {links.map((val, idx) => {
          let linkId = `link-${idx}`,
            urlId = `url-${idx}`;
          return (
            <div key={idx}>
              {/* <label htmlFor={linkId}>{`Link #${idx + 1}`}</label> */}
              <div className="form-group" />
              <input
                type="text"
                name="text"
                data-id={idx}
                id={linkId}
                className="form-control"
                placeholder="Enter Text to Display"
              />
              <div className="form-group" />
              <input
                type="text"
                name="url"
                data-id={idx}
                id={urlId}
                className="form-control"
                placeholder="Enter Url"
              />
              {idx > 0 && (
                <div>
                  <button
                    className="btn btn-info btn-block mt-1"
                    type="button"
                    onClick={() => this.removeLinks(idx)}
                    style={{ marginTop: "10px", width: "100px" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          );
        })}
        <button
          className="btn btn-success btn-block mt-4"
          type="button"
          onClick={this.addLinks}
          style={{ marginTop: "10px", width: "100px" }}
        >
          Add More
        </button>
      </div>
    );
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: "40px",
        "&:hover": { borderColor: "#5cb85c" },
        border: "1px solid lightgray",
        boxShadow: "none",
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: 1,
      }),
      clearIndicator: (base) => ({
        ...base,
        padding: 4,
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0px 6px",
      }),
      input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
    };
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <div
            className={`form-group ${this.errorClass(formErrors.title.error)}`}
          >
            <input
              type="text"
              name="title"
              className="form-control"
              onChange={this.onChange}
              placeholder="Name or title of the mark*"
              required
            />
            {!formErrors.title.valid && (
              <p style={errorStyle}>{formErrors.title.error}</p>
            )}
          </div>
          <div
            className={`form-group ${this.errorClass(
              formErrors.country.error
            )}`}
          >
            <select
              className="form-control"
              name="country"
              value={this.state.country}
              onChange={this.onChange}
            >
              <option value="0">country*</option>
              {countriesList.map((data) => (
                <option value={data.name}>{data.name}</option>
              ))}
            </select>
            {!formErrors.country.valid && (
              <p style={errorStyle}>{formErrors.country.error}</p>
            )}
          </div>
          <div
            className={`form-group ${this.errorClass(
              formErrors.classes.error
            )}`}
            style={{ position: "relative", zIndex: 100 }}
          >
            <label>Select Class*</label>
            <Select
              styles={customStyles}
              closeMenuOnSelect={true}
              value={this.state.classes}
              onChange={this.handleListChange}
              options={classesList.map((data) => ({
                label: data.class,
                value: data.class,
              }))}
              required
            />
          </div>
          <div
            className={`form-group ${this.errorClass(
              formErrors.applicationNumber.error
            )}`}
          >
            <input
              type="text"
              name="applicationNumber"
              onChange={this.onChange}
              className="form-control"
              placeholder="Application Number*(alpha numeric)"
            />
            {!formErrors.applicationNumber.valid && (
              <p style={errorStyle}>{formErrors.applicationNumber.error}</p>
            )}
          </div>
          <div
            className={`form-group ${this.errorClass(formErrors.status.error)}`}
          >
            <select
              className="form-control"
              name="status"
              value={this.state.status}
              onChange={this.onChange}
            >
              <option value="0">Select Status*</option>
              <option value="Registered">Registered</option>
              <option value="Pending">Pending</option>
            </select>
            {!formErrors.status.valid && (
              <p style={errorStyle}>{formErrors.status.error}</p>
            )}
          </div>
          <div
            className={`form-group ${this.errorClass(formErrors.price.error)}`}
          >
            <input
              type="number"
              name="price"
              onChange={this.onChange}
              className="form-control"
              placeholder="Price"
              min={0}
            />
            {!formErrors.price.valid && (
              <p style={errorStyle}>{formErrors.price.error}</p>
            )}
          </div>
          <div
            className={`form-group ${this.errorClass(
              formErrors.agreement.error
            )}`}
          >
            <select
              className="form-control"
              name="agreement"
              value={this.state.agreement}
              onChange={this.onChange}
            >
              <option value="0">Select Agreement Type*</option>
              <option value="Sales">Sale</option>
              <option value="License">License</option>
              <option value="Both">Both</option>
            </select>
            {!formErrors.agreement.valid && (
              <p style={errorStyle}>{formErrors.agreement.error}</p>
            )}
          </div>
          <div className="form-group">
            <label>Add Links</label>
            {Link}
          </div>
          <div className="form-group">
            <textarea
              type="text"
              name="summary"
              onChange={this.onChange}
              className="form-control"
              placeholder="Summary* (2000 characters max)"
              maxLength={2000}
              required
            />
          </div>
          <div className="form-group">
            <label>Attach Images* (jpg, jpeg, png only & Max 10 Images)</label>
            <FilePond
              acceptedFileTypes={["image/*"]}
              allowMultiple={true}
              maxFiles={10}
              onupdatefiles={(fileItems) => {
                var types = ["jpeg", "jpg", "png"];
                this.setState({
                  imageFile: fileItems.map((fileItem) => {
                    const ext = fileItem.file.name.split(".").pop();
                    if (types.includes(ext)) {
                      return fileItem.file;
                    } else {
                      this.setState({ imageInvalidType: false });
                    }
                  }),
                });
                this.state.isFileSectionTriggered = true;
                this.validateForm();
              }}
            />
            {!formErrors.image.valid && (
              <p style={errorStyle}>{formErrors.image.error}</p>
            )}
          </div>
          <div className="form-group">
            <label>Attach Documents( Max 5 pdf files & only pdf )</label>
            <FilePond
              allowMultiple={true}
              maxFiles={5}
              onupdatefiles={(fileItems) => {
                this.setState({ documentError: "" });
                var types = ["pdf"];
                this.setState({
                  document: fileItems.map((fileItem) => {
                    const ext = fileItem.file.name.split(".").pop();
                    if (types.includes(ext)) {
                      return fileItem.file;
                    } else {
                      this.setState({
                        documentError: "Invalid File Type: *Only PDF Allowed",
                      });
                    }
                  }),
                });
                this.state.isFileSectionTriggered = true;
                this.validateForm();
              }}
              FilePondPluginFileValidateType={(source, type) => {
                new Promise((resolve, reject) => {
                  const ext = source.name.split(".").pop();
                  if (ext === "pdf") {
                    resolve(type);
                  } else {
                    reject();
                  }
                });
              }}
            />
            {!this.state.documentValid && (
              <p style={errorStyle}>{this.state.documentError}</p>
            )}
          </div>
          <input
            type="submit"
            class="btn btn-info btn-block mt-4"
            value="submit"
            disabled={this.state.loaded || !this.state.isFormValid}
          />
          <Loader loaded={!this.state.loaded} />
          <ToastsContainer store={ToastsStore} />
        </form>
      </div>
    );
  }
}

Design.propTypes = {
  userAuth: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userAuth: state.userAuth,
  userData: state.userData,
});

export default connect(mapStateToProps, {})(withRouter(Design));
