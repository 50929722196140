import React from 'react';
import CommingSoon from "../../pages/commingSoon"



class patentServices extends React.Component {

    render(){

        return (


            <div>
                <CommingSoon/>
            </div>
        );
    }
}
export default patentServices;
