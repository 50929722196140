import React, { Component } from "react";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipCategory: ""
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state.ipCategory);
  }

  render() {
    const { ipCategory } = this.state;
    return (
      <header
        id="tg-dashboardheader"
        className="tg-dashboardheader tg-haslayout"
      >
        <div className="tg-rghtbox">
          <div className="dropdown tg-themedropdown tg-notification">
            <button
              className="tg-btndropdown"
              id="tg-notificationdropdown"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i
                className="icon-alarm"
                style={{ color: "white", border: "1px solid white" }}
              />
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
