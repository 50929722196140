import React, { Component } from 'react';
import Navbar from '../includes/navbar';
import SearchBar from '../home/searchBar';
import Trending from '../home/trending';
import Footer from '../includes/footer';



class Home extends Component {


    render(){

        return (
            <div className="App">
                <Navbar />
                <SearchBar />
                <Trending />
                <Footer />
            </div>
        );
    }
}

export default Home;
