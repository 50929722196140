import React from 'react';
import Navbar from '../../includes/navbar';

import Footer from '../../includes/footer';
import Renewal from '../../images-custom/trademark_renewal.png';
import QuickContact from './quickContact';



class trademarkRenewal extends React.Component {

    render(){

        return (
            <div>
                <Navbar />

                <div className="container">


                <section className="tg-sectionspace tg-haslayout">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-10">

                                    <h2>TRADE MARKS</h2>
                                        <hr className="hr-custom" />

                                    <p>In India Trade marks are registered with Controller General of Patents Designs and Trade marks, Ministry of Commerce and Industry, Government of India. Trade marks are registered under the Trade mark Act, 1999</p>

                                    <h3>RENEWAL</h3>
                                    <hr className="hr-custom" />
                                    <p>Trade marks have to be renewed every 10 years by paying the fees. You can get your trade mark renewed within 1 year of the expiration. Below is the process of renewing the trade mark</p>

                                    <div className="text-center">
                                        <img src={Renewal} />
                                    </div>

                                    <p style={{marginTop: '40px'}}><b>TRADEMYIP can help in providing free consultation, preparation and renewal of your trade marks in all classes.</b></p>

                                    

                                    <h4>BENEFITS OF RENEWING A TRADE MARK</h4>
                                        <hr className="hr-custom" />

                                    <ul>
                                        <li><b>10-year protection</b> – Your trademark will be protected for another 10 years giving you further exclusive right to trade using your trade mark</li>
                                        <li><b>Continue building goodwill</b> – Since you have established your goodwill you are legally protected to continue build your brand and increase your customer base.</li>
                                        <li><b>Increase asset value</b> – Since you have already created an intellectual property the value of your trade mark will increase given the goodwill you have built.</li>
                                    </ul>

                                        <QuickContact />
                                    </div>
                                </div>
                            </div>
                        </section>


                </div>
                <Footer />

            </div>
        );
    }
}

export default trademarkRenewal;
