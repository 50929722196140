import React, { Component } from 'react';
import Navbar from "../includes/navbar";
import Footer from "../includes/footer";
import { Auth } from "aws-amplify";
import {ToastsContainer, ToastsStore} from 'react-toasts';



class Forgot extends Component {


    constructor(props) {
        super(props);
        this.state = {email: null};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({email: event.target.value});
    }

    handleSubmit(event) {

        console.log(this.state.email)

        const emailReset = this.state.email;

        event.preventDefault();

        console.log(emailReset);

        Auth.forgotPassword(emailReset)
            .then(setTimeout(function(){
                ToastsStore.success('Email Sent, Kindly check your inbox')
            }, 3000))
            .catch(ToastsStore.success('Email Sent, Kindly check your inbox'));

            this.props.history.push('/password/reset');
    }



    render() {
        return (
            <div>
                <Navbar />
                <div className="container">
                    <div className="col-md-4 "></div>


                    <div className="col-md-4" style={{background:'#f1f1f1', boxShadow:'0 0 0 -1px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12)', marginTop:'40px', marginBottom:'40px'}}>

                        <div className="tg-title" style={{paddingBottom:'0px'}}>
                            <h2 style={{marginTop:'15px'}}>Reset Password</h2>
                        </div>

                        <form className="tg-formtheme tg-formloging" onSubmit={this.handleSubmit}>
                            <fieldset>

                                <div className="form-group" style={{width:'100%'}}>
                                    <div className="tg-checkbox" style={{width:'100%'}}>
                                        <input id="tg-rememberme" type="email" value={this.state.email} onChange={this.handleChange}  style={{width:'100%', height:'48px', textTransform: 'none'}} placeholder={'Enter Register Email'} />
                                    </div>
                                </div>
                                <button className="tg-btn" style={{width:'100%'}} type="submit">Send Password Reset Link</button>
                            </fieldset>
                        </form>
                        <div>
                            <ToastsContainer store={ToastsStore}/>
                        </div>
                    </div>

                    <div className="col-md-4 "></div>



                </div>


                <Footer />
            </div>
        );
    }

}

export default Forgot;
