import React, { Component } from "react";
import axios from "axios";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { Auth } from "aws-amplify";
import config from "../../environment";
import * as $ from "jquery";
import * as moment from "moment";

import Header from "../header";
import Sidebar from "../sidebar";

var Loader = require("react-loader");
registerPlugin(FilePondPluginFileValidateType);

class Copyright extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      country: "",
      applicationNumber: "",
      workType: "",
      status: "",
      price: "",
      agreement: "",
      published: "",
      publishedOn: "",
      createdOn: "",
      links: [{ text: "NA", url: "NA" }],
      duration: "NA",
      summary: "",
      imageFile: [],
      document: [],
      countriesList: [],
      user: [],
      video: "",
      loaded: false,
      token: localStorage.getItem("token"),
      formErrors: {
        title: {
          valid: "",
          error: ""
        },
        country: {
          valid: "",
          error: ""
        },
        applicationNumber: {
          valid: "",
          error: ""
        },
        workType: {
          valid: "",
          error: ""
        },
        status: {
          valid: "",
          error: ""
        },
        price: {
          valid: "",
          error: ""
        },
        agreement: {
          valid: "",
          error: ""
        },
        image: {
          valid: true,
          error: ""
        },
        publishedOn: {
          valid: "",
          error: ""
        },
        createdOn: {
          valid: "",
          error: ""
        }
      },
      documentValid: "",
      documentError: "",
      imageInvalidType: true,
      isFormValid: false,
      isFileSectionTriggered: false,
      ipData: {
        title: "",
        country: "",
        application_number: "",
        application_date: "",
        classes: {
          label: 3,
          value: ""
        },
        status: "",
        price: "",
        links: [{ text: "NA", url: "NA" }],
        summary: ""
      },
      ipId: "",
      uploadedDoc: [],
      uploadedImage: [],
      isDocLoaded: true,
      isImageLoaded: true,
      isDocUploaded: false,
      isButtonDisabled: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeCreatedDate = this.onChangeCreatedDate.bind(this);
    this.onChangePublishDate = this.onChangePublishDate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }
  componentDidMount() {
    this.getUser();
    this.getCountriesList();
    this.ipDetails();

    $(function() {
      let dtToday = new Date();

      let month = dtToday.getMonth() + 1;
      let day = dtToday.getDate();
      let year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      let maxDate = year + "-" + month + "-" + day;
      $("#txtDate").attr("max", maxDate);
    });
  }
  ipDetails = () => {
    console.log("this");
    const { id, cat } = this.props.match.params;
    this.setState({ ipId: id });
    const url =
      config.production.api + `fetchbyid?category=%22${cat}%22&id=%22${id}%22`;
    axios.get(url).then(response => {
      //console.log(response, "rajesh");
      // this.showSimilarIPs();
      this.setState({
        ipData: response.data.Item
      });
      var imagesArr = [];
      var docArr = [];

      console.log(this.state.ipData.images, "ipData");
      if (this.state.ipData.images != "NA") {
        imagesArr = Object.values(this.state.ipData.images);
      }
      if (this.state.ipData.documents != "NA") {
        docArr = Object.values(this.state.ipData.documents);
      }
      this.setState({});
      this.setState({
        title: this.state.ipData.title,
        country: this.state.ipData.country,
        applicationNumber: this.state.ipData.registration_number,
        workType: this.state.ipData.work_type,
        status: this.state.ipData.status,
        price: this.state.ipData.price,
        agreement: this.state.ipData.agreement,
        links: this.state.ipData.links,
        publishedOn: this.state.ipData.publishedOn,
        type: this.state.ipData.type,
        summary: this.state.ipData.summary,
        uploadedDoc: docArr,
        uploadedImage: imagesArr
      });

      console.log(this.state.uploadedImage, "ip");
      // console.log(this.state.ipData.links, "ip");
      // for (let i = 0; i < this.state.ipData.links.length; i++) {
      //   console.log(this.state.ipData.links[i], "ip");
      //   this.state.links.push({
      //     text: this.state.ipData.links[i].text,
      //     url: this.state.ipData.links[i].url
      //   });
      // }

      if (this.state.ipData.documents == "NA") {
        this.setState({
          isDocUploaded: false
        });
      } else {
        this.setState({
          isDocUploaded: true
        });
      }

      console.log(this.state.ipData, "ipData");
    });
  };
  getUser() {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        this.setState({ user: user.attributes });
      })
      .catch(err => console.log(err));
  }
  removeImages = imageIndex => {
    const images = [...this.state.uploadedImage];
    images.splice(imageIndex, 1);
    this.setState({
      uploadedImage: images
    });

    if (images.length == 0) {
      this.setState({
        imageFile: [],
        isImageLoaded: false
      });
    }
  };
  removeDocs = docIndex => {
    const docs = [...this.state.uploadedDoc];
    docs.splice(docIndex, 1);
    this.setState({
      uploadedDoc: docs
    });
    if (docs.length == 0) {
      this.setState({
        document: [],
        isDocLoaded: false,
        isDocUploaded: false
      });
    }
    console.log(this.state.uploadedDoc, "doc");
  };
  async onSubmit(e) {
    e.preventDefault();
    this.postFileData();
    if (this.state.isFormValid) {
      this.postFileData();
    }
  }
  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.validateField(name, value);
    });
  }
  onChangePublishDate(e) {
    const name = this.state.publishedOn;
    const value = e.target.value;
    this.setState({ publishedOn: e.target.value }, () => {
      this.validateField(name, value);
    });
  }
  onChangeCreatedDate(e) {
    const name = this.state.createdOn;
    const value = e.target.value;
    this.setState({ createdOn: e.target.value }, () => {
      this.validateField(name, value);
    });
  }
  handleLinkChange = e => {
    if (["text", "url"].includes(e.target.name)) {
      let links = [...this.state.links];
      links[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ links });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addLinks = e => {
    this.setState(prevState => ({
      links: [...prevState.links, { text: "", url: "" }]
    }));
  };
  removeLinks = index => {
    this.setState({
      links: this.state.links.filter((v, i) => i !== index)
    });
  };
  async getCountriesList() {
    let response = await axios.get("https://restcountries.eu/rest/v1/all", {});
    this.setState({ countriesList: response.data });
  }
  validateField(fieldName, value) {
    let fieldValidation = this.state.formErrors;
    switch (fieldName) {
      case "title":
        fieldValidation.title.valid = value !== "";
        fieldValidation.title.error = fieldValidation.title.valid
          ? ""
          : "title can't be empty";
        break;
      case "country":
        fieldValidation.country.valid = value !== "0";
        fieldValidation.country.error = fieldValidation.country.valid
          ? ""
          : "country can't be empty";
        break;
      case "applicationNumber":
        fieldValidation.applicationNumber.valid = value !== "";
        fieldValidation.applicationNumber.error = fieldValidation
          .applicationNumber.valid
          ? ""
          : "application number can't be empty";
        break;
      case "status":
        fieldValidation.status.valid = value !== "0";
        fieldValidation.status.error = fieldValidation.status.valid
          ? ""
          : "status can't be empty";
        break;
      case "agreement":
        fieldValidation.agreement.valid = value !== "0";
        fieldValidation.agreement.error = fieldValidation.agreement.valid
          ? ""
          : "agreement can't be empty";
        break;
      case "workType":
        fieldValidation.workType.valid = value !== "0";
        fieldValidation.workType.error = fieldValidation.workType.valid
          ? ""
          : "Work type can't be empty";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidation
      },
      this.validateForm
    );
  }

  validateForm() {
    const {
      formErrors,
      imageFile,
      document,
      isFileSectionTriggered,
      documentError,
      documentValid,
      imageInvalidType
    } = this.state;

    console.log(["doc err len", document.length, documentError.length]);

    if (document.length && documentError.length < 1) {
      this.setState({ documentValid: true });
    } else {
      this.setState({ documentValid: false });
    }

    if (imageFile.length) {
      this.setState({ imageInvalidType: true });
    }

    this.setState({
      isFormValid:
        formErrors.title.valid &&
        formErrors.country.valid &&
        formErrors.applicationNumber.valid &&
        formErrors.status.valid &&
        formErrors.agreement.valid &&
        formErrors.workType.valid &&
        formErrors.image.valid &&
        imageInvalidType &&
        this.state.documentValid,
      isDocUploaded: true
    });

    console.log(" -- form validation " + this.state.documentValid);
  }
  async postFileData() {
    //start the loader
    this.setState({ loaded: true });
    const images = [];
    const documents = [];
    let allDocuments = {};
    let allImages = {};

    for (let counter = 0; counter < this.state.imageFile.length; counter++) {
      let result_base64 = await new Promise(resolve => {
        let fileReader = new FileReader();
        fileReader.onload = e => resolve(fileReader.result);
        fileReader.readAsDataURL(this.state.imageFile[counter]);
      });
      images.push(result_base64);
    }

    for (let counter = 0; counter < this.state.document.length; counter++) {
      let result_base64 = await new Promise(resolve => {
        let fileReader = new FileReader();
        fileReader.onload = e => resolve(fileReader.result);
        fileReader.readAsDataURL(this.state.document[counter]);
      });
      documents.push(result_base64);
    }

    await Promise.all(
      images.map(async (item, index) => {
        delete axios.defaults.headers.common["token"];
        let patch = {
          email: this.state.user.email,
          category: this.props.category,
          file: item,
          type: "image"
        };
        await axios
          .post(
              `${config.production.api}fileupload`,
            patch
          )
          .then(res => {
            allImages["key" + index] = res.data.body;
            this.setState(prevState => ({
              uploadedImage: [...prevState.uploadedImage, res.data.body]
            }));
          })
          .catch(error => {
            console.log(error);
          });
      })
    );

    await Promise.all(
      documents.map(async (item, index) => {
        let patch = {
          email: this.state.user.email,
          type: "document",
          file: item,
          category: this.props.category
        };
        await axios
          .post(
              `${config.production.api}fileupload`,
            patch
          )
          .then(res => {
            allDocuments["key" + index] = res.data.body;
            this.setState(prevState => ({
              uploadedDoc: [...prevState.uploadedDoc, res.data.body]
            }));
          })
          .catch(error => {
            console.log("Error is", error);
          });
      })
    );
    // this.setState({
    //   uploadedImage: allImages,
    //   uploadedDoc: allDocuments
    // });
    await this.postCopyright(allImages, allDocuments);
  }
  async postCopyright(allImg, allDoc) {
    const {
      title,
      country,
      applicationNumber,
      workType,
      status,
      price,
      agreement,
      publishedOn,
      createdOn,
      links,
      duration,
      token,
      imageFile,
      document,
      summary,
      video
    } = this.state;
    const copyrightData = {
      title: title !== "" ? title : "NA",
      country: country !== "" ? country : "NA",
      registration_number: applicationNumber !== "" ? applicationNumber : "NA",
      work_type: workType !== "" ? workType : "NA",
      status: status !== "" ? status : "NA",
      price: price !== "" ? price : "NA",
      agreement: agreement !== "" ? agreement : "NA",
      publishedOn: publishedOn !== "" ? publishedOn : "NA",
      createdOn: createdOn !== "" ? createdOn : "NA",
      links: links !== "" ? links : [{ text: "NA", url: "NA" }],
      duration: duration !== "" ? duration : "NA",
      images: this.state.uploadedImage.length ? this.state.uploadedImage : "NA",
      documents: this.state.uploadedDoc.length ? this.state.uploadedDoc : "NA",
      category: "copyright",
      email: localStorage.getItem("email"),
      user: localStorage.getItem("name"),
      summary: summary !== "" ? summary : "NA",
      video: video !== "" ? video : "NA",
      id: this.state.ipId
    };
    console.log(copyrightData, "data");
    try {
      let abc = this.props.history;
      let response = await axios.put(
        `${config.production.api}update_posts/update-copyright`,
        copyrightData,
        { headers: { token: token } }
      );
      if (response.data.status == 200) {
        this.setState({ loaded: false, isButtonDisabled: true });
        ToastsStore.success(
          "IP submitted successfully and is in under review."
        );
        setTimeout(function() {
          abc.push("/dashboard/all"); // Delay time by 3 Sec
        }, 3000);
      } else {
        this.setState({ loaded: false });
        ToastsStore.info("Please try again");
      }
    } catch (error) {
      this.setState({ loaded: false });
      ToastsStore.error(
        "IP could not be posted due to Network Error, please try again later."
      );
    }
  }
  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }
  render() {
    const { countriesList, documentError, links, formErrors } = this.state;
    const errorStyle = {
      color: "red",
      fontSize: "10px",
      fontWeight: 400
    };

    const Link = (
      <div onChange={this.handleLinkChange}>
        {links.map((val, idx) => {
          let linkId = `link-${idx}`,
            urlId = `url-${idx}`;
          return (
            <div key={idx}>
              {/* <label htmlFor={linkId}>{`Link #${idx + 1}`}</label> */}
              <div className="form-group" />
              <input
                type="text"
                name="text"
                data-id={idx}
                id={linkId}
                className="form-control"
                placeholder="Enter Text to Display"
                defaultValue={this.state.ipData.links[idx].text}
              />
              <div className="form-group" />
              <input
                type="text"
                name="url"
                data-id={idx}
                id={urlId}
                className="form-control"
                placeholder="Enter Url"
                defaultValue={this.state.ipData.links[idx].url}
              />
              {idx > 0 && (
                <div>
                  <button
                    className="btn btn-info btn-block mt-1"
                    type="button"
                    onClick={() => this.removeLinks(idx)}
                    style={{ marginTop: "10px", width: "100px" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          );
        })}
        <button
          className="btn btn-success btn-block mt-4"
          type="button"
          onClick={this.addLinks}
          style={{ marginTop: "10px", width: "100px" }}
        >
          Add More
        </button>
      </div>
    );
    return (
      <div className={"user-ml"}>
        <Sidebar />

        <Header />

        <div className="tg-dashboardbanner">
          <h1>Edit Copyright</h1>
          <ol className="tg-breadcrumb">
            <li>
              <a href="javascript:void(0);">Home</a>
            </li>
            <li>
              <a href="javascript:void(0);">Dashboard</a>
            </li>
            <li className="tg-active">Edit Copyright</li>
          </ol>
        </div>

        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-dbsectionspace tg-haslayout">
            <div className="row">
              <div className="tg-postanad">
                <div className="col-xs-7">
                  <div className="tg-dashboardbox">
                    <div className="tg-dashboardboxtitle">
                      <h2>IP Detail</h2>
                    </div>
                    <div className="tg-dashboardholder">
                      <form onSubmit={this.onSubmit}>
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.title.error
                          )}`}
                        >
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            onChange={this.onChange}
                            placeholder="Name or title of the mark*"
                            required
                            value={this.state.title}
                            defaultValue={this.state.ipData.title}
                          />
                          {!formErrors.title.valid && (
                            <p style={errorStyle}>{formErrors.title.error}</p>
                          )}
                        </div>
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.country.error
                          )}`}
                        >
                          <label>Home Country*</label>
                          <select
                            className="form-control"
                            name="country"
                            value={this.state.country}
                            onChange={this.onChange}
                            required
                          >
                            {/* <option value="0">Home Country*</option> */}
                            {countriesList.map(data => (
                              <option value={data.name}>{data.name}</option>
                            ))}
                          </select>
                          {!formErrors.country.valid && (
                            <p style={errorStyle}>{formErrors.country.error}</p>
                          )}
                        </div>
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.applicationNumber.error
                          )}`}
                        >
                          <input
                            type="text"
                            name="applicationNumber"
                            onChange={this.onChange}
                            className="form-control"
                            value={this.state.applicationNumber}
                            defaultValue={this.state.ipData.registration_number}
                            placeholder="Application Number*(alpha numeric)"
                            required
                          />
                          {!formErrors.applicationNumber.valid && (
                            <p style={errorStyle}>
                              {formErrors.applicationNumber.error}
                            </p>
                          )}
                        </div>
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.workType.error
                          )}`}
                        >
                          <label>Type of work*</label>
                          <select
                            className="form-control"
                            name="workType"
                            value={this.state.workType}
                            onChange={this.onChange}
                            required
                          >
                            {/* <option value="0">Type of work*</option> */}
                            <option value="Artistic Work">Artistic Work</option>
                            <option value="Literature">Literature</option>
                            <option value="Software">Software</option>
                            <option value="Audio">Audio</option>
                            <option value="Video">Video</option>
                          </select>
                          {!formErrors.workType.valid && (
                            <p style={errorStyle}>
                              {formErrors.workType.error}
                            </p>
                          )}
                        </div>

                        {this.state.workType == "Video" ? (
                          <div className="form-group">
                            <input
                              type="text"
                              name="video"
                              onChange={this.onChange}
                              className="form-control"
                              placeholder="Youtube Video URL"
                            />
                          </div>
                        ) : null}
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.status.error
                          )}`}
                        >
                          <label>Select Status*</label>
                          <select
                            className="form-control"
                            name="status"
                            value={this.state.status}
                            onChange={this.onChange}
                            required
                          >
                            {/* <option value="0">Select Status*</option> */}
                            <option value="Registered">Registered</option>
                            <option value="Pending">Pending</option>
                          </select>
                          {!formErrors.status.valid && (
                            <p style={errorStyle}>{formErrors.status.error}</p>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            name="price"
                            onChange={this.onChange}
                            value={this.state.price}
                            defaultValue={this.state.ipData.price}
                            className="form-control"
                            placeholder="Price"
                            min={0}
                          />
                        </div>
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.agreement.error
                          )}`}
                        >
                          <label>Select Agreement Type*</label>
                          <select
                            className="form-control"
                            name="agreement"
                            value={this.state.agreement}
                            onChange={this.onChange}
                            required
                          >
                            {/* <option value="0">Select Agreement Type*</option> */}
                            <option value="Sale">Sale</option>
                            <option value="License">License</option>
                            <option value="Both">Both</option>
                          </select>
                          {!formErrors.agreement.valid && (
                            <p style={errorStyle}>
                              {formErrors.agreement.error}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="published"
                            value={this.state.published}
                            onChange={this.onChange}
                            required
                          >
                            <option value="0">Published*</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        {this.state.published === "true" && (
                          <div className="form-group">
                            <lable>Published On</lable>
                            <input
                              type="Date"
                              name="PublishedOn"
                              onChange={this.onChangePublishDate}
                              className="form-control"
                              placeholder="Published On*"
                              id={"txtDate"}
                              max={moment().format("YYYY-MM-DD")}
                              required
                            />
                            {!formErrors.publishedOn.valid && (
                              <p style={errorStyle}>
                                {formErrors.publishedOn.error}
                              </p>
                            )}
                          </div>
                        )}
                        {this.state.published == "false" && (
                          <div className="form-group">
                            <lable>Created On</lable>
                            <input
                              type="Date"
                              name="createdOn"
                              onChange={this.onChangeCreatedDate}
                              className="form-control"
                              placeholder="Created On*"
                              id={"txtDate"}
                              max={moment().format("YYYY-MM-DD")}
                              required
                            />
                            {!formErrors.createdOn.valid && (
                              <p style={errorStyle}>
                                {formErrors.createdOn.error}
                              </p>
                            )}
                          </div>
                        )}
                        <div className="form-group">
                          <label>Add Links</label>
                          {Link}
                        </div>
                        <div className="form-group">
                          <textarea
                            type="text"
                            name="summary"
                            onChange={this.onChange}
                            className="form-control"
                            value={this.state.summary}
                            defaultValue={this.state.ipData.summary}
                            placeholder="Summary* (2000 characters max)"
                            maxLength={2000}
                            required
                          />
                        </div>
                        {/*<div className="form-group">*/}
                        {/*<input*/}
                        {/*type="text"*/}
                        {/*name="duration"*/}
                        {/*onChange={this.onChange}*/}
                        {/*className="form-control"*/}
                        {/*placeholder="Duration(in years)"*/}
                        {/*/>*/}
                        {/*</div>*/}
                        <div className="form-group">
                          <label>
                            Attach Images (jpg, jpeg, png only & Max 10 Images)
                          </label>
                          <FilePond
                            acceptedFileTypes={["image/*"]}
                            allowMultiple={true}
                            maxFiles={10}
                            onupdatefiles={fileItems => {
                              var types = ["jpeg", "jpg", "png"];
                              this.setState({
                                imageFile: fileItems.map(fileItem => {
                                  const ext = fileItem.file.name
                                    .split(".")
                                    .pop();
                                  if (types.includes(ext)) {
                                    return fileItem.file;
                                  } else {
                                    this.setState({ imageInvalidType: false });
                                  }
                                })
                              });
                              this.state.isFileSectionTriggered = true;
                              this.validateForm();
                            }}
                          />
                          {!formErrors.image.valid && (
                            <p style={errorStyle}>{formErrors.image.error}</p>
                          )}
                        </div>
                        {/* {this.state.imageFile.map(file => (
                    <img src={file} />
                  ))} */}
                        {this.state.isImageLoaded ? (
                          this.state.ipData.images != "NA" ? (
                            <div className="form-group uploaded_image_col">
                              {this.state.uploadedImage.map((url, index) => (
                                <div class="image_item">
                                  {this.state.ipData.images != "NA" ? (
                                    <span
                                      className="delete_image_icon"
                                      onClick={() => {
                                        this.removeImages(index);
                                      }}
                                    >
                                      <i
                                        class="fa fa-times-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : null}
                                  <img className="uploaded_image" src={url} />
                                </div>
                              ))}

                              {/* <img
                      className="uploaded_image"
                      src={this.state.uploadedImage}
                    /> */}
                            </div>
                          ) : null
                        ) : null}

                        <div className="form-group">
                          <label>
                            Attach Documents* ( Max 5 pdf files & only pdf )
                          </label>
                          <FilePond
                            allowMultiple={true}
                            maxFiles={1}
                            onupdatefiles={fileItems => {
                              this.setState({ documentError: "" });
                              var types = ["pdf"];
                              this.setState({
                                document: fileItems.map(fileItem => {
                                  const ext = fileItem.file.name
                                    .split(".")
                                    .pop();
                                  if (types.includes(ext)) {
                                    return fileItem.file;
                                  } else {
                                    this.setState({
                                      documentError:
                                        "Invalid File Type: *Only PDF Allowed"
                                    });
                                  }
                                })
                              });
                              this.state.isFileSectionTriggered = true;
                              this.validateForm();
                            }}
                            FilePondPluginFileValidateType={(source, type) => {
                              new Promise((resolve, reject) => {
                                const ext = source.name.split(".").pop();
                                console.log(" ext is " + ext);
                                if (ext === "pdf") {
                                  resolve(type);
                                } else {
                                  reject();
                                }
                              });
                            }}
                          />
                          {!this.state.documentValid && (
                            <p style={errorStyle}>{this.state.documentError}</p>
                          )}
                        </div>
                        {/* {this.state.document.map(file => (
                    <object width="100%" height="300" data={file}></object>
                  ))} */}
                        {this.state.isDocLoaded ? (
                          this.state.ipData.documents != "NA" ? (
                            <div className="form-group uploaded_image_col">
                              {this.state.uploadedDoc.map((item, index) => (
                                <div className="image_item">
                                  <span
                                    className="delete_image_icon"
                                    onClick={() => {
                                      this.removeDocs(index);
                                    }}
                                  >
                                    <i
                                      class="fa fa-times-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <object
                                    width="100%"
                                    height="300"
                                    data={item}
                                  ></object>
                                </div>
                              ))}
                            </div>
                          ) : null
                        ) : null}
                        <input
                          type="submit"
                          className="btn btn-info btn-block mt-4"
                          value="submit"
                          disabled={
                            !this.state.isDocUploaded ||
                            this.state.isButtonDisabled
                          }
                        />
                        <Loader loaded={!this.state.loaded} />
                        <ToastsContainer store={ToastsStore} />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}
Copyright.propTypes = {
  userAuth: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userAuth: state.userAuth,
  userData: state.userData
});

export default connect(mapStateToProps, {})(withRouter(Copyright));
