import React from "react";
import Sidebar from "./sidebar";
import "../public/css/dashboard.css";
import Header from "./header";
import config from "../environment";
import axios from "axios";
import titleCase from 'title-case';
import IconImg from '../images-custom/logo/IP-icon.png'
import {ToastsContainer, ToastsStore} from "react-toasts";


class Favorites extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
           favorites : []
        }

    }

	componentDidMount(){
		
		this.getFavourite()
	}
	

		getFavourite =()=>{
			const data = {
				"email":localStorage.getItem("email")
			}


			axios.post(config.production.api +`favorites`, data)
				.then(response => {
					this.setState({favorites: response.data.body})
				})
				.catch( error => {
					// handle error
					console.log(error,"Error");
				})
		}



	_delFavourite =(_id)=>{
        const data={
            ip_Id:_id,
			email:localStorage.getItem("email"),
			remove_Fav: "true"
        }
       
         axios.post(config.production.api+`favorites`,data)
        .then(response => {
			ToastsStore.info(response.data.body)
			this.getFavourite()
			// setTimeout(function(){ window.location.reload(); }, 2000);
			console.log(response,"OPT")
        });
    }


  render() {

	console.log(IconImg,"XXXYYY")

    return (
      <div className={"user-ml"}>
        <Sidebar />
        <div>
          <Header />

		  <div className="tg-dashboardbanner">
            <h1>Offers/Favorites</h1>
            <ol className="tg-breadcrumb">
              <li>
                <a href="javascript:void(0);">Home</a>
              </li>
              <li>
                <a href="javascript:void(0);">Dashboard</a>
              </li>
              <li className="tg-active">My Favorites</li>
            </ol>
          </div>

          <main id="tg-main" className="tg-main tg-haslayout">

          <section class="tg-dbsectionspace tg-haslayout">
				<div class="row">
					<form class="tg-formtheme tg-formdashboard">
						<fieldset>
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div class="tg-dashboardbox">
									<div class="tg-dashboardboxtitle">
										<h2>My Favourites</h2>
									</div>
									<div class="tg-dashboardholder">
										{this.state.favorites.length > 0 ?
											<table id="tg-adstype" class="table tg-dashboardtable tg-myfavourites">
											<thead>
												<tr>
													<th>Photo</th>
													<th>Title</th>
													<th>Category</th>
													<th>Description</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
								
												{
													this.state.favorites.map((item) =>
													<tr data-category="inactive">
													<td data-title="Photo">
													{ item.images == "NA" ? <img src={IconImg} style={{width:'100px'}} alt="image description"></img> : <img src={item.images.key0} style={{width:'150px'}} alt="image description"></img> }
													</td>
													<td data-title="title">
														<h3>{item.title}</h3>
														<span>{item.price == "NA" ? null : <span>INR {item.price}</span> }</span>													
													</td>
													<td data-title="category">
													<h3>{titleCase(item.category)}</h3>
													<span>{titleCase(item.sub_category)}</span>
													<span>{ item.classes ? <span style={{color: '#666', display: 'block', fontSize: '12px', fontWeight: '500', lineHeight: '16px'}}>Class {item.classes.value}</span> : null}</span>

													</td>
													<td>
														<address>{item.summary}</address>
													</td>
													<td>
														<div class="tg-btnsactions">
															<a class="tg-btnaction tg-btnactionview" href={'/' + item.category + 's' + '/ip/' + item.id} target="_blank" style={{cursor:'pointer'}}><i class="fa fa-eye"></i></a>
															<a class="tg-btnaction tg-btnactiondelete" href="javascript:void(0);"><i class="fa fa-trash" onClick={()=>this._delFavourite(item.id)}></i></a>
														</div>
													</td>
												</tr>

												  )
												}
											
						
											</tbody>
										</table>
											
											: <h3>No Favorites IP Available</h3>}
											{/* <nav class="tg-pagination">
												<ul>
													<li class="tg-prevpage"><a href="#"><i class="fa fa-angle-left"></i></a></li>
													<li><a href="#">1</a></li>
													<li><a href="#">2</a></li>
													<li><a href="#">3</a></li>
													<li><a href="#">4</a></li>
													<li class="tg-active"><a href="#">5</a></li>
													<li>...</li>
													<li><a href="#">10</a></li>
													<li class="tg-nextpage"><a href="#"><i class="fa fa-angle-right"></i></a></li>
												</ul>
											</nav> */}
									</div>
								</div>
							</div>
						</fieldset>
					</form>
				</div>
			</section>
		</main>
        </div>
      </div>
    );
  }
}



export default Favorites;