import React from 'react';
import axios from "axios";
import {ToastsContainer, ToastsStore} from "react-toasts";



class quickContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = { show : true }
        this.showDiv  = this.showDiv.bind(this)
        this.hideDiv  = this.hideDiv.bind(this)
        this.state = { first: null, last: null, email: null, subject: null, comment: null, mobile: null, ip_id: null, category: null};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(event) {
        const arr = {}
        arr[event.target.name] = event.target.value;
        this.setState(arr);
    }

    handleSubmit(event) {
        event.preventDefault();


        axios.post(`https://hij2qhiaa6.execute-api.us-east-1.amazonaws.com/staging/contact`, this.state)
            .then(res => {
                if(res.data.errorMessage) {
                    ToastsStore.error("Request Unable to complete")
                }
                else {
                    ToastsStore.success("Request Successfully Submitted")
                }
            });

        this.setState({ show: false });
    }


    hideDiv = () => {
        this.setState({ show: false });
    }

    showDiv = () => {
        this.setState({ show: true });
    }

    render(){

        return (


            <div>
                { this.state.show ?
                    <div className="col-xs-12 quickcontact">
                        <div className="row">

                            <form onSubmit={this.handleSubmit}>


                            <div className="col-xs-12">
                                <h3><span onClick={this.hideDiv}><i className="fa fa-times" aria-hidden="true"></i></span> Quick Contact</h3>
                            </div>
                            <div className="col-xs-12">
                                <div className="form-group"><input type="text" name="first" onChange={this.handleChange} className="form-control" placeholder="Full Name*" /></div>
                            </div>

                            <div className="col-xs-12">
                                <div className="form-group"><input type="email" name="email"  onChange={this.handleChange} className="form-control" placeholder="Email*" required={true} />
                                </div>
                            </div>
                            <div className="col-xs-12">
                                <div className="form-group"><input type="number" name="mobile"  onChange={this.handleChange} className="form-control" placeholder="Mobile" />
                                </div>
                            </div>
                            <div className="col-xs-12">
                                <div className="form-group"><textarea className="form-control" name="comment"   onChange={this.handleChange}style={{height: '80px'}} placeholder="Message"></textarea>
                                </div>
                            </div>


                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input className="tg-btn w-100" style={{width: '100%;', background:'#5ecd69'}} type="submit" value="Submit" />
                            </div>
                            </form>
                        </div>
                    </div>

                    : <span className="is-modal-icon" onClick={this.showDiv}><i className="fa fa-caret-left"></i></span> }

                <div> <ToastsContainer store={ToastsStore}/> </div>

            </div>
    );
    }
}
export default quickContact;
