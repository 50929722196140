import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { Auth } from "aws-amplify";
import config from "../../environment";
import { Multiselect } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";
import * as moment from "moment";
import Header from "../header";
import Sidebar from "../sidebar";
var Loader = require("react-loader");
registerPlugin(FilePondPluginFileValidateType);
class patent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      priorityCountry: "",
      countries: "",
      applicationNumber: "",
      category: "",
      priorityDate: "",
      GrantDate: "",
      status: "",
      price: "",
      agreement: "",
      links: [{ text: "NA", url: "NA" }],
      summary: "",
      imageFile: [],
      document: [],
      countriesList: [],
      potential: "",
      user: [],
      loaded: false,
      token: localStorage.getItem("token"),
      formErrors: {
        title: {
          valid: "",
          error: ""
        },
        priorityCountry: {
          valid: "",
          error: ""
        },
        applicationNumber: {
          valid: "",
          error: ""
        },
        status: {
          valid: "",
          error: ""
        },
        price: {
          valid: "",
          error: ""
        },
        agreement: {
          valid: "",
          error: ""
        },
        image: {
          valid: "",
          error: ""
        }
      },
      documentValid: true,
      documentError: "",
      imageInvalidType: true,
      isFormValid: false,
      isFileSectionTriggered: false,
      ipData: {
        title: "",
        country: "",
        application_number: "",
        application_date: "",
        classes: {
          label: 3,
          value: ""
        },
        status: "",
        price: "",
        links: [{ text: "NA", url: "NA" }],
        summary: ""
      },
      ipId: "",
      uploadedDoc: [],
      uploadedImage: [],
      isDocLoaded: true,
      isImageLoaded: true,
      isButtonDisabled: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPriorityDateChange = this.onPriorityDateChange.bind(this);
    this.onGrantDateChange = this.onGrantDateChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    this.getUser();
    this.getCountriesList();
    this.ipDetails();
  }
  ipDetails = () => {
    console.log("this");
    const { id, cat } = this.props.match.params;
    this.setState({ ipId: id });
    const url =
      config.production.api + `fetchbyid?category=%22${cat}%22&id=%22${id}%22`;
    axios.get(url).then(response => {
      //console.log(response, "rajesh");
      // this.showSimilarIPs();
      this.setState({
        ipData: response.data.Item
      });
      var imagesArr = [];
      var docArr = [];

      console.log(this.state.ipData.images, "ipData");
      if (this.state.ipData.images != "NA") {
        imagesArr = Object.values(this.state.ipData.images);
      }
      if (this.state.ipData.documents != "NA") {
        docArr = Object.values(this.state.ipData.documents);
      }
      this.setState({
        title: this.state.ipData.title,
        country: this.state.ipData.countries,
        applicationNumber: this.state.ipData.patent_number,
        applicationDate: this.state.ipData.application_date,
        price: this.state.ipData.price,
        sub_category: this.state.ipData.sub_category,
        status: this.state.ipData.status,
        price: this.state.ipData.price,
        agreement: this.state.ipData.agreement,
        links: this.state.ipData.links,
        priorityDate: this.state.ipData.priority_date,
        priorityCountry: this.state.ipData.priority_country,
        GrantDate: this.state.ipData.grant_date,
        type: this.state.ipData.type,
        summary: this.state.ipData.summary,
        uploadedDoc: docArr,
        uploadedImage: imagesArr,
        potential: this.state.ipData.lowercased_summary
      });

      console.log(this.state.ipData, "ipData");
      console.log(this.state.sub_category, "ipData");
    });
  };
  getUser() {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        this.setState({ user: user.attributes });
      })
      .catch(err => console.log(err));
  }
  removeImages = imageIndex => {
    const images = [...this.state.uploadedImage];
    images.splice(imageIndex, 1);
    this.setState({
      uploadedImage: images
    });
    if (images.length == 0) {
      this.setState({
        imageFile: [],
        isImageLoaded: false
      });
    }

    // this.setState({
    //   uploadedImage: "",
    //   imageFile: [],
    //   isImageLoaded: false
    // });
  };
  removeDocs = docIndex => {
    const docs = [...this.state.uploadedDoc];
    docs.splice(docIndex, 1);
    this.setState({
      uploadedDoc: docs
    });
    if (docs.length == 0) {
      this.setState({
        document: [],
        isDocLoaded: false,
        isDocUploaded: false
      });
    }
    console.log(this.state.uploadedDoc, "doc");
  };
  async onSubmit(e) {
    e.preventDefault();
    this.postFileData();
    // if (this.state.isFormValid) {
    //   this.postFileData();
    // } else {
    //   console.log("Form is not valid");
    // }
  }

  onChange(e) {
    //console.log(e.target);
    const name = e.target.value;
    const value = e.target.value;
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.validateField(name, value);
    });
  }
  onPriorityDateChange(e) {
    this.setState({ priorityDate: e.target.value });
  }
  onGrantDateChange(e) {
    this.setState({ GrantDate: e.target.value });
  }
  handleLinkChange = e => {
    if (["text", "url"].includes(e.target.name)) {
      let links = [...this.state.links];
      links[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ links });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addLinks = e => {
    this.setState(prevState => ({
      links: [...prevState.links, { text: "", url: "" }]
    }));
  };
  removeLinks = index => {
    this.setState({
      links: this.state.links.filter((v, i) => i !== index)
    });
  };
  validateField(fieldName, value) {
    let fieldValidation = this.state.formErrors;
    switch (fieldName) {
      case "title":
        fieldValidation.title.valid = value !== "";
        fieldValidation.title.error = fieldValidation.title.valid
          ? ""
          : "Title can't be empty";
        break;
      case "priorityCountry":
        fieldValidation.priorityCountry.valid = value !== "0";
        fieldValidation.priorityCountry.error = fieldValidation.priorityCountry
          .valid
          ? ""
          : "priorityCountry can't be empty";
        break;
      case "applicationNumber":
        fieldValidation.applicationNumber.valid = value !== "";
        fieldValidation.applicationNumber.error = fieldValidation
          .applicationNumber.valid
          ? ""
          : "application number can't be empty";
        break;
      case "status":
        fieldValidation.status.valid = value !== "0";
        fieldValidation.status.error = fieldValidation.status.valid
          ? ""
          : "status can't be empty";
        break;
      case "agreement":
        fieldValidation.agreement.valid = value !== "0";
        fieldValidation.agreement.error = fieldValidation.agreement.valid
          ? ""
          : "agreement can't be empty";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidation
      },
      this.validateForm
    );
  }

  validateForm() {
    const {
      formErrors,
      imageFile,
      document,
      isFileSectionTriggered,
      documentError,
      documentValid,
      imageInvalidType
    } = this.state;
    if (isFileSectionTriggered) {
      formErrors.image.valid = imageFile.length ? true : false;
      formErrors.image.error = formErrors.image.valid
        ? ""
        : "at least one image is required";
      if (document.length && documentError.length < 1) {
        this.setState({ documentValid: true });
      } else {
        this.setState({ documentValid: true });
      }
    }
    if (imageFile.length) {
      this.setState({ imageInvalidType: true });
    }
    this.setState({
      isFormValid:
        formErrors.title.valid &&
        formErrors.priorityCountry.valid &&
        formErrors.applicationNumber.valid &&
        formErrors.status.valid &&
        formErrors.agreement.valid &&
        formErrors.image.valid &&
        imageInvalidType &&
        this.state.documentValid
    });
  }
  async postFileData() {
    //start the loader
    this.setState({ loaded: true });
    const images = [];
    const documents = [];
    let allDocuments = {};
    let allImages = {};

    for (let counter = 0; counter < this.state.imageFile.length; counter++) {
      let result_base64 = await new Promise(resolve => {
        let fileReader = new FileReader();
        fileReader.onloadend = e => resolve(fileReader.result);
        fileReader.readAsDataURL(this.state.imageFile[counter]);
      });
      console.log(result_base64, "img");
      images.push(result_base64);
    }

    for (let counter = 0; counter < this.state.document.length; counter++) {
      let result_base64 = await new Promise(resolve => {
        let fileReader = new FileReader();
        fileReader.onload = e => resolve(fileReader.result);
        fileReader.readAsDataURL(this.state.document[counter]);
      });
      documents.push(result_base64);
    }

    await Promise.all(
      images.map(async (item, index) => {
        delete axios.defaults.headers.common["token"];
        let patch = {
          email: this.state.user.email,
          category: this.props.category,
          file: item,
          type: "image"
        };
        await axios
          .post(
              `${config.production.api}fileupload`,
            patch
          )
          .then(res => {
            allImages["key" + index] = res.data.body;
            this.setState(prevState => ({
              uploadedImage: [...prevState.uploadedImage, res.data.body]
            }));
          })
          .catch(error => {
            console.log(error);
          });
      })
    );

    await Promise.all(
      documents.map(async (item, index) => {
        let patch = {
          email: this.state.user.email,
          type: "document",
          file: item,
          category: this.props.category
        };
        await axios
          .post(
              `${config.production.api}fileupload`,
            patch
          )
          .then(res => {
            allDocuments["key" + index] = res.data.body;
            this.setState(prevState => ({
              uploadedDoc: [...prevState.uploadedDoc, res.data.body]
            }));
          })
          .catch(error => {
            console.log("Error is", error);
          });
      })
    );
    // this.setState({
    //   uploadedImage: allImages,
    //   uploadedDoc: allDocuments
    // });
    await this.postPatent(allImages, allDocuments);
  }
  async postPatent(allImg, allDoc) {
    const {
      title,
      priorityCountry,
      countries,
      applicationNumber,
      category,
      sub_category,
      priorityDate,
      GrantDate,
      status,
      price,
      agreement,
      links,
      summary,
      potential,
      user,
      token,
      imageFile,
      document
    } = this.state;

    console.log(imageFile);

    const patentData = {
      title: title !== "" ? title : "NA",
      priority_country: priorityCountry !== "" ? priorityCountry : "NA",
      priority_date: priorityDate !== "" ? priorityDate : "NA",
      grant_date: GrantDate !== "" ? GrantDate : "NA",
      countries: countries !== null ? this.state.country : "NA",
      business_potential: potential !== "" ? potential : "NA",
      patent_number: applicationNumber !== "" ? applicationNumber : "NA",
      sub_category: sub_category !== "" ? sub_category : "NA",
      status: status !== "" ? status : "NA",
      price: price !== "" ? price : "NA",
      agreement: agreement !== "" ? agreement : "NA",
      links: links !== "" ? links : [{ text: "NA", url: "NA" }],
      summary: summary !== "" ? summary : "NA",
      images: this.state.uploadedImage.length ? this.state.uploadedImage : "NA",
      documents: this.state.uploadedDoc.length ? this.state.uploadedDoc : "NA",
      category: this.props.category,
      email: localStorage.getItem("email"),
      user: localStorage.getItem("name"),
      category: "patent",
      id: this.state.ipId
    };
    console.log(patentData, "reached");
    try {
      let abc = this.props.history;
      console.log("Posging");
      let response = await axios.put(
        `${config.production.api}update_posts/update-patent`,
        patentData,
        { headers: { token: token } }
      );
      if (response.data.status == 200) {
        this.setState({ loaded: false, isButtonDisabled: true });
        ToastsStore.success(
          "IP submitted successfully and is in under review."
        );
        setTimeout(function() {
          abc.push("/dashboard/all"); // Delay time by 3 Sec
        }, 3000);
      } else {
        this.setState({ loaded: false });
        ToastsStore.info("Please try again");
      }
    } catch (error) {
      this.setState({ loaded: false });
      ToastsStore.error(
        "IP could not be posted due to Network Error, please try again later."
      );
    }
  }
  async getCountriesList() {
    //let response = await axios.get("https://restcountries.eu/rest/v1/all", {});
    const countryList = [
      "Afghanistan",
      "Albania",
      "Algeria",
      "American Samoa",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antarctica",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas (the)",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia (Plurinational State of)",
      "Bonaire, Sint Eustatius and Saba",
      "Bosnia and Herzegovina",
      "Botswana",
      "Bouvet Island",
      "Brazil",
      "British Indian Ocean Territory (the)",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cayman Islands (the)",
      "Central African Republic (the)",
      "Chad",
      "Chile",
      "China",
      "Christmas Island",
      "Cocos (Keeling) Islands (the)",
      "Colombia",
      "Comoros (the)",
      "Congo (the Democratic Republic of the)",
      "Congo (the)",
      "Cook Islands (the)",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Curaçao",
      "Cyprus",
      "Czechia",
      "Côte d'Ivoire",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic (the)",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Ethiopia",
      "Falkland Islands (the) [Malvinas]",
      "Faroe Islands (the)",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "French Polynesia",
      "French Southern Territories (the)",
      "Gabon",
      "Gambia (the)",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Heard Island and McDonald Islands",
      "Holy See (the)",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran (Islamic Republic of)",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea (the Democratic People's Republic of)",
      "Korea (the Republic of)",
      "Kuwait",
      "Kyrgyzstan",
      "Lao People's Democratic Republic (the)",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macao",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands (the)",
      "Martinique",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Mexico",
      "Micronesia (Federated States of)",
      "Moldova (the Republic of)",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands (the)",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger (the)",
      "Nigeria",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands (the)",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestine, State of",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines (the)",
      "Pitcairn",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Republic of North Macedonia",
      "Romania",
      "Russian Federation (the)",
      "Rwanda",
      "Réunion",
      "Saint Barthélemy",
      "Saint Helena, Ascension and Tristan da Cunha",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Martin (French part)",
      "Saint Pierre and Miquelon",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Sint Maarten (Dutch part)",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Georgia and the South Sandwich Islands",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan (the)",
      "Suriname",
      "Svalbard and Jan Mayen",
      "Sweden",
      "Switzerland",
      "Syrian Arab Republic",
      "Taiwan (Province of China)",
      "Tajikistan",
      "Tanzania, United Republic of",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tokelau",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks and Caicos Islands (the)",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates (the)",
      "United Kingdom of Great Britain and Northern Ireland (the)",
      "United States Minor Outlying Islands (the)",
      "United States of America (the)",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela (Bolivarian Republic of)",
      "Viet Nam",
      "Virgin Islands (British)",
      "Virgin Islands (U.S.)",
      "Wallis and Futuna",
      "Western Sahara",
      "Yemen",
      "Zambia",
      "Zimbabwe",
      "Åland Islands"
    ];
    this.setState({ countriesList: countryList });
  }
  handleListChange = async countries => {
    await this.setState({ countries: countries });
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }
  render() {
    const {
      countriesList,
      imageError,
      documentError,
      links,
      formErrors
    } = this.state;

    const errorStyle = {
      color: "red",
      fontSize: "10px",
      fontWeight: 400
    };

    const Link = (
      <div onChange={this.handleLinkChange}>
        {links.map((val, idx) => {
          let linkId = `link-${idx}`,
            urlId = `url-${idx}`;
          return (
            <div key={idx}>
              {/* <label htmlFor={linkId}>{`Link #${idx + 1}`}</label> */}
              <div className="form-group" />
              <input
                type="text"
                name="text"
                data-id={idx}
                id={linkId}
                className="form-control"
                placeholder="Enter Text to Display"
                value={this.state.links[idx].text}
                defaultValue={this.state.ipData.links[idx].text}
              />
              <div className="form-group" />
              <input
                type="text"
                name="url"
                data-id={idx}
                id={urlId}
                className="form-control"
                placeholder="Enter Url"
                value={this.state.links[idx].url}
                defaultValue={this.state.ipData.links[idx].url}
              />
              {idx > 0 && (
                <div>
                  <button
                    className="btn btn-info btn-block mt-1"
                    type="button"
                    onClick={() => this.removeLinks(idx)}
                    style={{ marginTop: "10px", width: "100px" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          );
        })}
        <button
          className="btn btn-success btn-block mt-4"
          type="button"
          onClick={this.addLinks}
          style={{ marginTop: "10px", width: "100px" }}
        >
          Add More
        </button>
      </div>
    );
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: "40px",
        "&:hover": { borderColor: "#5cb85c" },
        border: "1px solid lightgray",
        boxShadow: "none"
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 1
      }),
      clearIndicator: base => ({
        ...base,
        padding: 4
      }),
      valueContainer: base => ({
        ...base,
        padding: "0px 6px"
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0
      })
    };
    return (
      <div className={"user-ml"}>
        <Sidebar />

        <Header />

        <div className="tg-dashboardbanner">
          <h1>Edit Patent</h1>
          <ol className="tg-breadcrumb">
            <li>
              <a href="javascript:void(0);">Home</a>
            </li>
            <li>
              <a href="javascript:void(0);">Dashboard</a>
            </li>
            <li className="tg-active">Edit Patent</li>
          </ol>
        </div>

        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-dbsectionspace tg-haslayout">
            <div className="row">
              <div className="tg-postanad">
                <div className="col-xs-7">
                  <div className="tg-dashboardbox">
                    <div className="tg-dashboardboxtitle">
                      <h2>IP Detail</h2>
                    </div>
                    <div className="tg-dashboardholder">
                      <form onSubmit={this.onSubmit}>
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.title.error
                          )}`}
                        >
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            onChange={this.onChange}
                            placeholder="Name or title of the Patent*"
                            required
                            value={this.state.title}
                            defaultValue={this.state.ipData.title}
                          />
                          {!formErrors.title.valid && (
                            <p style={errorStyle}>{formErrors.title.error}</p>
                          )}
                        </div>

                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.applicationNumber.error
                          )}`}
                        >
                          <input
                            type="text"
                            name="applicationNumber"
                            onChange={this.onChange}
                            value={this.state.applicationNumber}
                            defaultValue={this.state.ipData.patent_number}
                            className="form-control"
                            placeholder="Application Number*(alpha numeric)"
                            required
                          />
                          {!formErrors.applicationNumber.valid && (
                            <p style={errorStyle}>
                              {formErrors.applicationNumber.error}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          {/*<input*/}
                          {/*type="text"*/}
                          {/*name="category"*/}
                          {/*onChange={this.onChange}*/}
                          {/*className="form-control"*/}
                          {/*placeholder="category(like agriculture,automobiles etc)"*/}
                          {/*/>*/}

                          <label>Select Category*</label>
                          <select
                            className="form-control"
                            name="sub_category"
                            onChange={this.onChange}
                            value={this.state.sub_category}
                            defaultValue={this.state.ipData.sub_category}
                            required
                          >
                            {/* <option value="">Select Category*</option> */}
                            <option value="Arts and crafts">
                              Arts and crafts
                            </option>
                            <option value="Biotechnology">Biotechnology</option>
                            <option value="Building and basis material">
                              Building and basis material
                            </option>
                            <option value="Clothing and accessories">
                              Clothing and accessories
                            </option>
                            <option value="Construction and Household">
                              Construction & Household
                            </option>
                            <option value="Clothes and accessories">
                              Clothes and accessories
                            </option>
                            <option value="Energy">Energy</option>
                            <option value="Electronics">Electronics</option>
                            <option value="Electronical and technical goods">
                              Electronical and technical goods
                            </option>
                            <option value="Entertainment and games">
                              Entertainment and games
                            </option>
                            <option value="Food technology">
                              Food technology
                            </option>
                            <option value="Furniture, lighting, arts and household goods">
                              Furniture, lighting, arts and household goods
                            </option>
                            <option value="Games">Games</option>
                            <option value="Household goods">
                              Household goods
                            </option>
                            <option value="Production">Production</option>
                            <option value="Information Technology">
                              Information Technology
                            </option>
                            <option value="Furniture and lighting">
                              Furniture and lighting
                            </option>
                            <option value="Safety and assistive goods">
                              Safety and assistive goods
                            </option>
                            <option value="Means of transport and transport equipment">
                              Means of transport and transport equipment
                            </option>
                            <option value="Leisure activity goods">
                              Leisure activity goods
                            </option>
                            <option value="Machines">Machines</option>
                            <option value="Mechanical Engineering">
                              Mechanical Engineering
                            </option>
                            <option value="Pharmaceutical, medical and cosmetic products">
                              Pharmaceutical, medical and cosmetic products
                            </option>
                            <option value="Machines, motors, technical devices and electronic equipment">
                              Machines, motors, technical devices and electronic
                              equipment
                            </option>
                            <option value="Foodstuffs">Foodstuffs</option>
                            <option value="Services">Services</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div
                          className="form-group"
                          style={{ position: "relative", zIndex: 100 }}
                        >
                          <label>Select Countries</label>
                          {/* <select
                    className="form-control"
                    name="country"
                    value={this.state.country}
                    defaultValue={this.state.ipData.countries}
                    onChange={this.onChange}
                  >
                    <option value="0">country*</option>
                    {countriesList.map(data => (
                      <option value={data.name}>{data.name}</option>
                    ))}
                  </select> */}
                          <Multiselect
                            onChange={value =>
                              this.setState({ country: value })
                            }
                            data={countriesList}
                            value={this.state.country}
                            defaultValue={this.state.ipData.countries}
                          />
                        </div>
                        <div className="form-group">
                          <lable>Priority Date</lable>
                          <input
                            type="date"
                            name="priorityDate"
                            onChange={this.onPriorityDateChange}
                            value={this.state.priorityDate}
                            defaultValue={this.state.ipData.priority_date}
                            className="form-control"
                            placeholder="Priority Date*"
                            max={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.priorityCountry.error
                          )}`}
                        >
                          <label>Priority Country*</label>
                          <select
                            className="form-control"
                            name="priorityCountry"
                            value={this.state.priorityCountry}
                            defaultValue={this.state.ipData.priority_country}
                            onChange={this.onChange}
                          >
                            <option value="0">Priority Country*</option>
                            {countriesList.map(data => (
                              <option value={data}>{data}</option>
                            ))}
                          </select>
                          {!formErrors.priorityCountry.valid && (
                            <p style={errorStyle}>
                              {formErrors.priorityCountry.error}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <lable>Grant On</lable>
                          <input
                            type="date"
                            name="GrantDate"
                            onChange={this.onGrantDateChange}
                            value={this.state.GrantDate}
                            defaultValue={this.state.ipData.grant_date}
                            className="form-control"
                            placeholder="Grant Date"
                            max={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.status.error
                          )}`}
                        >
                          <label>Select Status*</label>
                          <select
                            className="form-control"
                            name="status"
                            value={this.state.status}
                            onChange={this.onChange}
                            required
                          >
                            {/* <option value="0">Select Status*</option> */}
                            <option value="Granted">Granted</option>
                            <option value="Pending">Pending</option>
                          </select>
                          {!formErrors.status.valid && (
                            <p style={errorStyle}>{formErrors.status.error}</p>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            name="price"
                            onChange={this.onChange}
                            value={this.state.price}
                            defaultValue={this.state.ipData.price}
                            className="form-control"
                            placeholder="Price"
                            min={0}
                          />
                        </div>
                        <div
                          className={`form-group ${this.errorClass(
                            formErrors.agreement.error
                          )}`}
                        >
                          <label>Select Agreement Type*</label>
                          <select
                            className="form-control"
                            name="agreement"
                            value={this.state.agreement}
                            onChange={this.onChange}
                            required
                          >
                            {/* <option value="0">Select Agreement Type*</option> */}
                            <option value="Sales">Sale</option>
                            <option value="License">License</option>
                            <option value="Both">Both</option>
                            <option value="Both">PartnerShip</option>
                          </select>
                          {!formErrors.agreement.valid && (
                            <p style={errorStyle}>
                              {formErrors.agreement.error}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Add Links</label>
                          {Link}
                        </div>
                        <div className="form-group">
                          <textarea
                            type="text"
                            name="summary"
                            onChange={this.onChange}
                            value={this.state.summary}
                            defaultValue={this.state.ipData.summary}
                            className="form-control"
                            placeholder="Summary* (2000 characters max)"
                            maxLength={2000}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            type="text"
                            name="potential"
                            onChange={this.onChange}
                            value={this.state.potential}
                            defaultValue={this.state.ipData.lowercased_summary}
                            className="form-control"
                            placeholder="Business Potential* (2000 characters max)"
                            maxLength={2000}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Attach Images*(jpg, jpeg, png only & Max 10 Images
                            Allowed )
                          </label>
                          <FilePond
                            acceptedFileTypes={["image/*"]}
                            allowMultiple={true}
                            maxFiles={10}
                            onupdatefiles={fileItems => {
                              var types = ["jpeg", "jpg", "png"];
                              this.setState({
                                imageFile: fileItems.map(fileItem => {
                                  const ext = fileItem.file.name
                                    .split(".")
                                    .pop();
                                  if (types.includes(ext)) {
                                    this.setState({
                                      isImageLoaded: true
                                    });
                                    return fileItem.file;
                                  } else {
                                    this.setState({ imageInvalidType: false });
                                  }
                                })
                              });
                              this.state.isFileSectionTriggered = true;
                            }}
                          />
                          {!formErrors.image.valid && (
                            <p style={errorStyle}>{formErrors.image.error}</p>
                          )}
                        </div>
                        {/* {this.state.imageFile.map(file => (
            <img src={file} />
          ))} */}
                        {this.state.isImageLoaded ? (
                          this.state.ipData.images != "NA" ? (
                            <div className="form-group uploaded_image_col">
                              {this.state.uploadedImage.map((url, index) => (
                                <div class="image_item">
                                  {this.state.ipData.images != "NA" ? (
                                    <span
                                      className="delete_image_icon"
                                      onClick={() => {
                                        this.removeImages(index);
                                      }}
                                    >
                                      <i
                                        class="fa fa-times-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : null}
                                  <img className="uploaded_image" src={url} />
                                </div>
                              ))}

                              {/* <img
                      className="uploaded_image"
                      src={this.state.uploadedImage}
                    /> */}
                            </div>
                          ) : null
                        ) : null}
                        <div className="form-group">
                          <label>
                            Attach Documents (Max 5 pdf files & only pdf)
                          </label>
                          <FilePond
                            allowMultiple={true}
                            maxFiles={1}
                            onupdatefiles={fileItems => {
                              this.setState({ documentError: "" });
                              var types = ["pdf"];
                              this.setState({
                                document: fileItems.map(fileItem => {
                                  const ext = fileItem.file.name
                                    .split(".")
                                    .pop();
                                  if (types.includes(ext)) {
                                    return fileItem.file;
                                  } else {
                                    this.setState({
                                      documentError:
                                        "Invalid File Type: *Only PDF Allowed"
                                    });
                                  }
                                })
                              });
                              this.state.isFileSectionTriggered = true;
                              this.validateForm();
                            }}
                            FilePondPluginFileValidateType={(source, type) => {
                              new Promise((resolve, reject) => {
                                const ext = source.name.split(".").pop();
                                if (ext === "pdf") {
                                  resolve(type);
                                } else {
                                  reject();
                                }
                              });
                            }}
                          />
                          {!this.state.documentValid && (
                            <p style={errorStyle}>{this.state.documentError}</p>
                          )}
                        </div>
                        {/* {this.state.document.map(file => (
            <object width="100%" height="300" data={file}></object>
          ))} */}
                        {this.state.isDocLoaded ? (
                          this.state.ipData.documents != "NA" ? (
                            <div className="form-group uploaded_image_col">
                              {this.state.uploadedDoc.map((item, index) => (
                                <div className="image_item">
                                  <span
                                    className="delete_image_icon"
                                    onClick={() => {
                                      this.removeDocs(index);
                                    }}
                                  >
                                    <i
                                      class="fa fa-times-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <object
                                    width="100%"
                                    height="300"
                                    data={item}
                                  ></object>
                                </div>
                              ))}
                            </div>
                          ) : null
                        ) : null}
                        <input
                          type="submit"
                          className="btn btn-info btn-block mt-4"
                          value="submit"
                          disabled={
                            !this.state.isImageLoaded ||
                            this.state.isButtonDisabled
                          }
                        />
                        <Loader loaded={!this.state.loaded} />
                        <ToastsContainer store={ToastsStore} />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}
patent.propTypes = {
  userAuth: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userAuth: state.userAuth,
  userData: state.userData
});

export default connect(mapStateToProps, {})(withRouter(patent));
