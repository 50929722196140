import React, { Component } from "react";
import Sidebar from "../user/sidebar";
import TradeMark from "./forms/Trademark";
import Patent from "./forms/patent";
import Copyright from "./forms/copyright";
import Design from "./forms/design";
import Header from "./header";

class postIp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipCategory: ""
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { ipCategory } = this.state;
    return (
      <div className={"user-ml"}>
        <Sidebar />

        <Header />

        <div className="tg-dashboardbanner">
          <h1>Post IP</h1>
          <ol className="tg-breadcrumb">
            <li>
              <a href="javascript:void(0);">Home</a>
            </li>
            <li>
              <a href="javascript:void(0);">Dashboard</a>
            </li>
            <li className="tg-active">Post an IP</li>
          </ol>
        </div>

        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-dbsectionspace tg-haslayout">
            <div className="row">
              <form className="tg-formtheme tg-formdashboard">
                <fieldset>
                  <div className="tg-postanad">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                      <div className="tg-dashboardbox">
                        <div className="tg-dashboardboxtitle">
                          <h2>IP Detail</h2>
                        </div>
                        <div className="tg-dashboardholder">
                          <div className="form-group">
                            <select
                              name="carlist"
                              form="carform"
                              name="ipCategory"
                              value={this.state.ipCategory}
                              onChange={this.onChange}
                            >
                              <option value="select">Select IP Category</option>
                              <option value="trademark">Trademark</option>
                              <option value="design">Design</option>
                              <option value="patent">Patent</option>
                              <option value="copyright">Copyright</option>
                            </select>
                          </div>

                          {ipCategory === "trademark" && (
                            <TradeMark category={ipCategory} />
                          )}
                          {ipCategory === "patent" && (
                            <Patent category={ipCategory} />
                          )}
                          {ipCategory === "copyright" && (
                            <Copyright category={ipCategory} />
                          )}
                          {ipCategory === "design" && (
                            <Design category={ipCategory} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default postIp;
