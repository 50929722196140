import React, { Component } from "react";
import Navbar from "../includes/navbar";
import Footer from "../includes/footer";
import { Auth } from "aws-amplify";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { Link } from "react-router-dom";
import GoogleAuth from "../auth/googleAuth";
import FacebookAuth from "../auth/facebookAuth";

class Register extends Component {
  state = {
    name : null,
    email: "",
    checked: false,
    password: "",
    confirmpassword: "",
    errors: {
      cognito: null
    }
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }

  onCheck(){
  this.setState({checked : !this.state.checked})
  }

  handleSubmit = async event => {
    event.preventDefault();
    // AWS Cognito integration here
    const { name, email, password, confirmpassword, checked } = this.state;
    // perform all neccassary validations
    if (password !== confirmpassword) {
      ToastsStore.error("Password and Confirm Password did not Match");
    return
    }
    if ( !checked ) {
          ToastsStore.error("Kindly Accept Terms and Conditions");
        return
        }
    try {
      const sinupResponse = await Auth.signUp({
        username: email, password, attributes: { name }
      });
      let res = this.props.history
      ToastsStore.success("Successfully Registered, kindly confirm your email address");
      setTimeout(function(){
        res.push("/dashboard/all"); // Delay time by 3 Sec
      }, 3000)
    } catch (error) {
      ToastsStore.error(error.message);
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  onClick() {
    this.setState({checked: !this.state.checked});
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="col-md-4 " />
          <div className="col-md-4" style={{ background: "#f1f1f1", boxShadow: "0 0 0 -1px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12)", marginTop: "40px", marginBottom: "40px" }} >
            <div className="tg-title">
              <h2 style={{ marginTop: "15px" }}>Register Now</h2>
            </div>
            <form className="tg-formtheme tg-formregister" onSubmit={this.handleSubmit} >
              <fieldset>
                <div className="form-group tg-inputwithicon">
                  <i className="icon-user" />
                  <input type="text" id="name" name="name" className="form-control" placeholder="Your Name" value={this.state.name} onChange={this.onInputChange} />
                </div>
                <div className="form-group tg-inputwithicon">
                  <i className="icon-envelope" />
                  <input type="email" id="email" name="email" className="form-control" placeholder="Email*" value={this.state.email} onChange={this.onInputChange} required />
                </div>
                <div className="form-group tg-inputwithicon">
                  <i className="icon-lock" />
                  <input type="password" id="password" name="password" className="form-control" placeholder="Password*" value={this.state.password} onChange={this.onInputChange} required />
                </div>
                <div className="form-group tg-inputwithicon">
                  <i className="icon-lock" />
                  <input type="password" id="confirmpassword" name="confirmpassword" className="form-control" placeholder="Retype Password*" value={this.state.confirmpassword} onChange={this.onInputChange} required />
                </div>
                <div className="form-group">
                  <div className="tg-checkbox">
                    <input id="tg-agree" type="checkbox" name="checked" onClick={this.onCheck}  />
                    <label htmlFor="tg-agree" style={{textTransform: 'none'}}>
                      By registering, you accept our
                      <Link to={"terms"}> Terms &amp; Conditions</Link>
                    </label>
                  </div>
                </div>
                <div className="form-group tg-inputwithicon">
                  <input type="submit" style={{ background: "#00cc67", color: "white", paddingLeft: "0px" }} name="confirmpassword" className="form-control" placeholder="Retype Password*" />
                </div>
              </fieldset>
              <div className={"text-center"}>
                <span style={{ marginBottom: "-16px", background: "#f1f1f1", padding: "0px 10px" }} >
                  Or Register with Social Account
                </span>
                <hr style={{ borderTop: "1px solid", marginTop: "-13px" }} />
              </div>
            </form>
            <ul className="tg-sociallogingsignup" style={{ marginBottom: "30px" }} >
              <GoogleAuth />
              <FacebookAuth />
            </ul>
            <div>
              <ToastsContainer store={ToastsStore} />
            </div>
          </div>
          <div className="col-md-4 " />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Register;
