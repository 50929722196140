import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import "./searchBar.css";
import axios from "axios";
import Trademarks from "../images-custom/ip-logos/trademark.jpg";
import Patents from "../images-custom/ip-logos/patent.jpg";
import Copyright from "../images-custom/ip-logos/copyright.jpg";
import Design from "../images-custom/ip-logos/design.jpg";
import mainCover from "../images-custom/hm-slider/main-cover.jpeg";
import ipLicensing from "../images-custom/hm-slider/ip-licensing.jpeg";
import ipNegotiation from "../images-custom/hm-slider/ip-negotiation.jpeg";
import ipValuation from "../images-custom/hm-slider/ip-valuation.jpeg";

import titleCase from "title-case";
import config from "../environment";
import { ToastsContainer, ToastsStore } from "react-toasts";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Carousel } from "react-responsive-carousel";

const submit = {
  color: "#333",
};

class searchBar extends Component {
  isSubCatActive = true;

  constructor(props) {
    super(props);
    this.state = {
      results: [],
      img: [],
      category: "na",
      subCategory: "na",
      keyword: "na",
      showSearch: false,
      favorites: [],
      favoritesListID: [],
      isFav: false,
      classesListDesign: [],
      classesListTrademark: [],
      isLiked: false,
      resultData: [],
      viewsList: [],
      searchLoading: false,
    };

    this.changeCategory = this.changeCategory.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.myRef = React.createRef();
  }

  changeCategory(event) {
    const key = event.target.name;
    let arr = [];
    arr[key] = event.target.value;
    this.setState(arr);

    if (key === "category") {
      this.setState({ subCategory: "na" });
    }
  }

  async onSearchClick() {
    await this.fetchData();
    return false;
  }

  componentDidMount() {
    this.getFavList();
    this.getClassesListDesign();
    this.getClassesListTrademark();
  }

  fetchData() {
    this.setState({ showSearch: true, searchLoading: true });

    //rajesh

    // e.preventDefault();
    const cat = this.state.category;
    const subCat = this.state.subCategory;
    const keyword = this.state.keyword;

    const header = {
      "Content-Type": "Application/JSON",
    };

    axios
      .get(
        config.production.api +
          `ipsearch?keyword=%22${keyword}%22&category=%22${cat}%22&subcategory=%22${subCat}%22`,
        { headers: header }
      )
      .then((response) => {
        if (response.data) {
          // this.setState({results : response.data,
          //     image:response.data.images,
          // })
          const data = response.data;
          data.forEach((e) => {
            //console.log(e,"EE");
            e.isFvt = false;
            e.noOfView = 0;
          });

          for (let i = 0; i < this.state.favorites.length; i++) {
            for (let k = 0; k < data.length; k++) {
              if (this.state.favorites[i].id == data[k].id) {
                data[k].isFvt = true;
                break;
              }
            }
          }

          this.setState({ resultData: data, image: response.data.images });

          axios.get(config.production.api + `no-of-views`).then((response) => {
            this.setState({ viewsList: response.data });

            for (let i = 0; i < this.state.viewsList.length; i++) {
              for (let k = 0; k < data.length; k++) {
                let keys = Object.keys(this.state.viewsList[i]);
                if (keys == data[k].id) {
                  data[k].noOfView = this.state.viewsList[i][keys];
                  break;
                }
              }
            }

            this.setState({
              resultData: data,
              image: response.data.images,
              searchLoading: false,
            });
          });

          this.setResultsData();
        } else {
          this.setState({ results: [] });
          // incase if need to add empty data
        }
      });

    const data = {
      email: localStorage.getItem("email"),
    };

    const y = this.myRef.current.offsetHeight;

    window.scrollTo({ top: y, behavior: "smooth" });

    return false;
  }

  setResultsData = () => {
    this.setState({ results: this.state.resultData });
  };

  //Get favorites list
  getFavList = async () => {
    const data = {
      email: localStorage.getItem("email"),
    };
    await axios
      .post(config.production.api + `favorites`, data)
      .then((response) => {
        this.setState({ favorites: response.data.body });
        for (let i = 0; i <= this.state.favorites.length; i++) {
          if (this.state.favorites.length > 0) {
            if (this.state.favorites[i].id == this.state.results.id) {
              this.setState({ isFav: true });
              break;
            } else {
              this.setState({ isFav: false });
            }
          } else {
            this.setState({ isFav: false });
          }
        }
      })
      .catch((error) => {
        // handle error
        console.log(error, "Error");
      });
  };

  //Add Favourites
  _addfavourite = async (id) => {
    for (let i = 0; i < this.state.results.length; i++) {
      if (this.state.results[i].id == id) {
        this.state.results[i].isFvt = true;
      }
    }
    const emailData = {
      email: localStorage.getItem("email"),
    };
    if (emailData.email) {
      this.setResultsData();
    }
    let clickedFav = document.getElementById(id);
    const data = {
      ip_Id: id,
      email: localStorage.getItem("email"),
    };

    if (data.email) {
      //api call if user LogedIn
      await axios
        .post(config.production.api + `favorites`, data)
        .then((response) => {
          this.getFavList();

          // this.setState({
          //     isLiked:true
          // })
          ToastsStore.success(response.data.body);
        });
    } else {
      ToastsStore.success("Plese login to add favourite");
    }
  };

  //Delete Favourite
  _delFavourite = async (id) => {
    for (let i = 0; i < this.state.results.length; i++) {
      if (this.state.results[i].id == id) {
        this.state.results[i].isFvt = false;
      }
    }
    const emailData = {
      email: localStorage.getItem("email"),
    };
    if (emailData.email) {
      this.setResultsData();
    }
    const data = {
      ip_Id: id,
      email: localStorage.getItem("email"),
      remove_Fav: "true",
    };

    await axios
      .post(config.production.api + `favorites`, data)
      .then((response) => {
        this.setState({
          isLiked: false,
        });
        ToastsStore.info(response.data.body);
        this.getFavList();
      });
  };

  getClassesListDesign = () => {
    for (let i = 1; i <= 32; i++) {
      this.state.classesListDesign.push(i);
    }
  };

  getClassesListTrademark = () => {
    this.state.classesListTrademark.push(99);
    for (let i = 1; i <= 45; i++) {
      this.state.classesListTrademark.push(i);
    }
  };

  sortTitle(str) {
    if (str !== null && str !== undefined && str.length > 10)
      str = str.substring(0, 33);
    return str;
  }
  sortSubTitle(str) {
    if (str !== null && str !== undefined && str.length > 10)
      str = str.substring(0, 20);
    return str;
  }

  render() {
    const cards = this.state.results;

    return (
      <div
        id="tg-homebanner"
        className="tg-homebanner tg-haslayout"
        style={{ background: "white" }}
      >
        <figure className="item" data-vide-options="position: 50% 50%">
          <div className="video-st">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={1000}
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
            >
              <div>
                <img
                  src={mainCover}
                  style={{ width: "100%", height: "500px" }}
                />
              </div>
              <div>
                <img
                  src={ipLicensing}
                  style={{ width: "100%", height: "500px" }}
                />
              </div>
              <div>
                <img
                  src={ipValuation}
                  style={{ width: "100%", height: "500px" }}
                />
              </div>
              <div>
                <img
                  src={ipNegotiation}
                  style={{ width: "100%", height: "500px" }}
                />
              </div>
            </Carousel>
          </div>
          <figcaption>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="tg-bannercontent">
                    <h1>YOUR IP MARKETPLACE</h1>
                    <h2>BUY | SELL | LICENSE</h2>
                    <form className="tg-formtheme tg-formbannersearch">
                      <fieldset>
                        <div className="form-group tg-inputwithicon">
                          <i className="icon-bullhorn"></i>
                          <input
                            type="text"
                            name="keyword"
                            onChange={this.changeCategory}
                            className="form-control"
                            placeholder="What are you looking for"
                          />
                        </div>
                        <div className="form-group tg-inputwithicon">
                          <i className="icon-location"></i>
                          <div className="tg-select">
                            <select
                              name="category"
                              onChange={this.changeCategory}
                              value={this.state.category}
                            >
                              <option value="na">Select Category</option>
                              <option value="design">Designs</option>
                              <option value="patent">Patents</option>
                              <option value="trademark">Trademarks</option>
                              <option value="copyright">Copyrights</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group tg-inputwithicon">
                          <i className="icon-layers"></i>
                          <div className="tg-select">
                            {this.state.category == "na" ? (
                              <select
                                name="subCategory"
                                onChange={this.changeCategory}
                                value={this.state.subCategory}
                              >
                                <option value="na">Select Sub Category</option>
                              </select>
                            ) : null}

                            {this.state.category == "patent" ? (
                              <select
                                name="subCategory"
                                onChange={this.changeCategory}
                                value={this.state.subCategory}
                              >
                                <option value="">Select Category*</option>
                                <option value="Arts and crafts">
                                  Arts and crafts
                                </option>
                                <option value="Biotechnology">
                                  Biotechnology
                                </option>
                                <option value="Building and basis material">
                                  Building and basis material
                                </option>
                                <option value="Clothing and accessories">
                                  Clothing and accessories
                                </option>
                                <option value="Construction and Household">
                                  Construction & Household
                                </option>
                                <option value="Clothes and accessories">
                                  Clothes and accessories
                                </option>
                                <option value="Energy">Energy</option>
                                <option value="Electronics">Electronics</option>
                                <option value="Electronical and technical goods">
                                  Electronical and technical goods
                                </option>
                                <option value="Entertainment and games">
                                  Entertainment and games
                                </option>
                                <option value="Food technology">
                                  Food technology
                                </option>
                                <option value="Furniture, lighting, arts and household goods">
                                  Furniture, lighting, arts and household goods
                                </option>
                                <option value="Games">Games</option>
                                <option value="Household goods">
                                  Household goods
                                </option>
                                <option value="Production">Production</option>
                                <option value="Information Technology">
                                  Information Technology
                                </option>
                                <option value="Furniture and lighting">
                                  Furniture and lighting
                                </option>
                                <option value="Safety and assistive goods">
                                  Safety and assistive goods
                                </option>
                                <option value="Means of transport and transport equipment">
                                  Means of transport and transport equipment
                                </option>
                                <option value="Leisure activity goods">
                                  Leisure activity goods
                                </option>
                                <option value="Machines">Machines</option>
                                <option value="Mechanical Engineering">
                                  Mechanical Engineering
                                </option>
                                <option value="Pharmaceutical, medical and cosmetic products">
                                  Pharmaceutical, medical and cosmetic products
                                </option>
                                <option value="Machines, motors, technical devices and electronic equipment">
                                  Machines, motors, technical devices and
                                  electronic equipment
                                </option>
                                <option value="Foodstuffs">Foodstuffs</option>
                                <option value="Services">Services</option>
                                <option value="Other">Other</option>
                              </select>
                            ) : null}

                            {this.state.category == "trademark" ? (
                              <select
                                name="subCategory"
                                onChange={this.changeCategory}
                                value={this.state.subCategory}
                              >
                                <option value="na">Select Sub Category</option>
                                {this.state.classesListTrademark.map((data) => (
                                  <option value={data}>Class {data}</option>
                                ))}
                              </select>
                            ) : null}

                            {this.state.category == "copyright" ? (
                              <select
                                name="subCategory"
                                onChange={this.changeCategory}
                                value={this.state.subCategory}
                              >
                                <option value="na">Select Sub Category</option>
                                <option value="Artistic Work">
                                  Artistic Work
                                </option>
                                <option value="Literature">Literature</option>
                                <option value="Software">Software</option>
                                <option value="Audio">Audio</option>
                                <option value="Video">Video</option>
                              </select>
                            ) : null}

                            {this.state.category == "design" ? (
                              <select
                                name="subCategory"
                                onChange={this.changeCategory}
                                value={this.state.subCategory}
                              >
                                <option value="na">Select Sub Category</option>
                                {this.state.classesListDesign.map((data) => (
                                  <option value={data}>Class {data}</option>
                                ))}
                              </select>
                            ) : null}
                          </div>
                        </div>
                        <button
                          onClick={this.onSearchClick}
                          className="tg-btn"
                          type="button"
                        >
                          Search Now
                        </button>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </figcaption>
        </figure>

        <div className="container" ref={this.myRef}>
          <div className="col-sm-12 p-0">
            <div className={this.state.showSearch ? "showbox" : "hidebox"}>
              <div
                className="tg-title text-left"
                style={{ marginTop: "40px", paddingBottom: "0px" }}
              >
                <h2 className="text-left">Search Results</h2>
              </div>
              <div className="tg-description">
                <p>{cards.length} Found</p>
              </div>
            </div>
          </div>
          {this.state.searchLoading ? (
            <>
              {" "}
              <div style={{ textAlign: "center", margin: "auto" }}>
                <CircularProgress
                  style={{ width: 100, height: 100, margin: "120px" }}
                />
              </div>
            </>
          ) : (
            <>
              {this.state.results.map((item, index) => {
                return (
                  <div>
                    <div
                      className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                      style={{ float: "left" }}
                    >
                      <div className="card-bg cstmh tg-ad tg-verifiedad cborder">
                        <figure>
                          <Link
                            to={item.category + "s/ip/" + item.id}
                            params={{ id: item.id, type: item.category }}
                          >
                            {item.category == "trademark" ? (
                              <div>
                                {item.images === "NA" ? (
                                  <img src={Trademarks} className={"dmimg"} />
                                ) : (
                                  <img
                                    src={item.images[0]}
                                    alt="Trademarks sp"
                                    className={"dmimg"}
                                  />
                                )}{" "}
                              </div>
                            ) : null}
                            {item.category == "patent" ? (
                              <div>
                                {item.images === "NA" ? (
                                  <img
                                    src={Patents}
                                    alt="patent"
                                    className={"dmimg"}
                                  />
                                ) : (
                                  <img
                                    src={item.images[0]}
                                    alt="patent sp"
                                    className={"dmimg"}
                                  />
                                )}{" "}
                              </div>
                            ) : null}
                            {item.category == "design" ? (
                              <div>
                                {item.images === "NA" ? (
                                  <img
                                    src={Design}
                                    alt="design"
                                    className={"dmimg"}
                                  />
                                ) : (
                                  <img
                                    src={item.images[0]}
                                    alt="desig  sp"
                                    className={"dmimg"}
                                  />
                                )}{" "}
                              </div>
                            ) : null}
                            {item.category == "copyright" ? (
                              <div>
                                {item.images === "NA" ? (
                                  <img
                                    src={Copyright}
                                    alt="copyright"
                                    className={"dmimg"}
                                  />
                                ) : (
                                  <img
                                    src={item.images[0]}
                                    alt="copyright sp"
                                    className={"dmimg"}
                                  />
                                )}{" "}
                              </div>
                            ) : null}
                          </Link>
                        </figure>

                        <div className="tg-adcontent">
                          <ul className="tg-productcagegories">
                            <li>
                              <a href="#">{titleCase(item.category)}</a>
                              <span className="card--status">
                                {titleCase(item.status)}
                              </span>
                            </li>
                          </ul>
                          <div className="tg-adtitle">
                            <h3>
                              <a href="#">{this.sortTitle(item.title)}</a>
                            </h3>
                            {item.category == "trademark" ||
                            item.category == "design" ? (
                              <div className="row">
                                <div
                                  className="col-sm-6"
                                  style={{ float: "left" }}
                                >
                                  <time datetime="2017-06-06">
                                    Class {item.classes.value}
                                  </time>
                                </div>
                                <div className="col-sm-6 text-right">
                                  {item.noOfView != 0 ? (
                                    <time>{item.noOfView} views</time>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                            {item.category == "patent" ? (
                              <div className="row">
                                <div
                                  className="col-sm-6"
                                  style={{ float: "left" }}
                                >
                                  <time datetime="2017-06-06">
                                    {this.sortSubTitle(item.sub_category)}...
                                  </time>
                                </div>
                                <div className="col-sm-6 text-right">
                                  {item.noOfView != 0 ? (
                                    <time>{item.noOfView} views</time>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}

                            {item.category == "copyright" ? (
                              <div className="row">
                                <div
                                  className="col-sm-6"
                                  style={{ float: "left" }}
                                >
                                  <time datetime="2017-06-06">
                                    {item.work_type}
                                  </time>
                                </div>
                                <div className="col-sm-6 text-right">
                                  {item.noOfView != 0 ? (
                                    <time>{item.noOfView} views</time>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div className="tg-phonelike">
                            <Link
                              className="tg-btnphone"
                              to={item.category + "s/ip/" + item.id}
                              params={{ id: item.id, type: item.category }}
                            >
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Show Phone No."
                                data-last="0800 - 1234 - 562 - 6"
                              >
                                <em>Show More</em>
                              </span>
                            </Link>
                            <span class="tg-like">
                              {item.isFvt ? (
                                <i
                                  className="fa fa-heart selectedFav"
                                  id={item.id}
                                  onClick={() => {
                                    this._delFavourite(item.id);
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-heart "
                                  id={item.id}
                                  onClick={() => this._addfavourite(item.id)}
                                ></i>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
    // }
  }
}

export default searchBar;
