import React from "react";
import Navbar from "../includes/navbar";
import Footer from "../includes/footer";
import { ToastsContainer, ToastsStore } from "react-toasts";
import axios from "axios";

class trademarkRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first: null,
      last: null,
      email: null,
      subject: null,
      comment: null,
      mobile: null,
      ip_id: null,
      category: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const arr = {};
    arr[event.target.name] = event.target.value;
    this.setState(arr);
  }

  handleSubmit(event) {
    event.preventDefault();

    axios
      .post(
        `https://hij2qhiaa6.execute-api.us-east-1.amazonaws.com/staging/contact`,
        this.state
      )
      .then(res => {
        console.log(res);
        if (res.data.errorMessage) {
          ToastsStore.error("Request Unable to complete");
        } else {
          ToastsStore.success("Request Successfully Submitted");
        }
      });
  }

  render() {
    return (
      <div>
        <Navbar />

        <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
          <main id="tg-main" className="tg-main tg-haslayout">
            <section className="tg-sectionspace tg-haslayout">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <h3>Contact Team</h3>
                    <p>Kindly fill the form provided below to post an Ad.</p>

                    <div className="col-sm-6 col-md-6 col-lg-6 col-sm-offset-3">
                      <div className="row">
                        <form onSubmit={this.handleSubmit}>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="first"
                                className="form-control"
                                placeholder="First Name*"
                                value={this.state.first}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="last"
                                className="form-control"
                                placeholder="Last Name*"
                                value={this.state.last}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Email*"
                                required={true}
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="subject"
                                className="form-control"
                                placeholder="Subject (optional)"
                                value={this.state.subject}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="comment"
                                placeholder="Comment"
                                value={this.state.comment}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                              type="submit"
                              className="tg-btn"
                              style={{ background: "#5ecd69" }}
                              value="Submit"
                            />
                          </div>
                        </form>

                        <div>
                          <ToastsContainer store={ToastsStore} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default trademarkRegistration;
