import React, { Component } from 'react';
import { Link } from "react-router-dom";
import IPFooter from "../public/images/logo/IP-footer.png"
import terms from "../pages/terms";
import privacy from "../pages/privacy";


const imgStyle = {
    position: 'absolute',
    width: '70px'
}


const sm = {
    fontSize:'12px',
    color: '#8c8c8c'
}

class footer extends Component {
    render() {
        return (
            <footer id="tg-footer" className="nmn tg-footer tg-haslayout" style={{background:'#363b4d !important', padding:'15px 15px'}}>
                <div className="content-box">
                    <div className="container">
                        <div className="row">
                            <div className="tg-footerinfo" style={{height:'360px'}}>
                                <div className="container">
                                    <div className="tg-widget tg-widgettext text-left">
                                        <div className="tg-widgetcontent">
                                            <strong className="tg-logo"><a href="javascript:void(0);"><img src={IPFooter} width='180px' alt="Trade My IP" /></a></strong>
                                            <div className="tg-description">
                                                <p><i>"IP refers to creation of minds. It is important to own and protect your IP as the progress and well-being of humanity rest on its capacity to create and invent new works in the areas of technology and culture."</i> <span>– WIPO</span></p>
                                            </div>
                                            <div className="tg-followus">
                                                <strong>Follow Us:</strong>
                                                <ul className="tg-socialicons">
                                                    <li className="tg-facebook"><a href="https://business.facebook.com/Trademyip-102363468009039/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                                    {/* <li className="tg-twitter"><a href="javascript:void(0);"><i className="fa fa-twitter"></i></a></li> */}
                                                    <li className="tg-linkedin"><a href="https://www.linkedin.com/company/trademyip/" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                                    {/* <li className="tg-googleplus"><a href="javascript:void(0);"><i className="fa fa-google-plus"></i></a></li> */}
                                                    {/* <li className="tg-rss"><a href="javascript:void(0);"><i className="fa fa-rss"></i></a></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ft-patch">
                        <div className="container">

                            <div className="row" style={{marginTop:'10px', marginBottom:'10px'}}>

                                <div className="col-sm-3 text-left p-0">
                                    <ul>
                                        <li><Link to={'/terms'}>Terms of Use</Link></li>
                                        <li><Link to={'/privacy'}>Privacy Policy</Link></li>
                                        <li><a href="/sitemap.xml">Sitemap</a></li>
                                    </ul>
                                </div>

                                <div className="col-sm-6 text-right">
                                    <a href="https://plebiscite.in" target="_blank">
                                        <span className="tg-copyright" style={{color:'white'}}>2019 All Rights Reserved ©  TradeMyIP <span style={sm}>(A Brand of GENIE IP SERVICES PVT. LTD)</span></span></a>
                                </div>

                                <div className="col-sm-3 text-right"><a href="https://plebiscite.in" target="_blank"><span className="tg-copyright"><span style={sm}>Developed by Plebiscite IT Services</span></span></a></div>


                            </div>


                        </div>
                    </div>



                    

                </div>
            </footer>
        );
    }
}

export default footer;
