import React from "react";
import Header from "./header";
import Sidebar from "../user/sidebar";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { ToastsStore } from "react-toasts";
import Avtar from "../public/images/profile.svg";
import axios from "axios";
import config from "../environment";
import Loader from 'react-loader-spinner'




class editProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      uploadImg :null,
      localUsers: {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email")
      },
      image : null,
      imgUploading : false
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        this.setState({ users: user.attributes });
        console.log(user.attributes);
      })
      .catch(err => console.log(err));
  }

  onInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    console.log(this.state);
  };
  fileSelectedHandler = event=>{
    this.setState({
      uploadImg:event.target.files[0]
    })
  }

 
  handleSubmit = async event => {
    event.preventDefault();
    this.setState({imgUploading : true})

    const { name } = this.state;

    const user = await Auth.currentAuthenticatedUser();

    let abc = this.props;

    try {
    if(!this.state.name){
        this.state.name = localStorage.getItem("name");
      }
      const result = await Auth.updateUserAttributes(user, {
        name: this.state.name
      });
     try{
    
        let uploadImgArr = [];
        let uploadImages = {}
        if(this.state.uploadImg){
          for (let counter = 0; counter < 1; counter++) {
            let result_base64 = await new Promise(resolve => {
              let fileReader = new FileReader();
              fileReader.onload = e => resolve(fileReader.result);
              fileReader.readAsDataURL(this.state.uploadImg);
            });
            uploadImgArr.push(result_base64);
          }

          await Promise.all(
            uploadImgArr.map(async (item, index) => {
              delete axios.defaults.headers.common["token"];
              let patch = {
                email: this.state.localUsers.email,
                file: item,
                type: "image"
              };
              await axios
                .post(
                  config.production.api + "upload-image",
                  patch
                )
                .then(res => {
                  uploadImages["key" + index] = res.data.body;
                  this.setState({imgUploading : false})
                })
                .catch(error => {
                  console.log(error);
                });
            })
          );

    
      }
    
     }catch(error){

     }
      ToastsStore.success("Success");
      abc.history.push("/dashboard");
    } catch (error) {
      ToastsStore.error(error.message);
    }
  };

  async getProfilePic(){
    var email = new String(localStorage.getItem("email"))
    let Url =  await axios.get(config.production.api +  `getprofilepic?email=%22${email}%22`);
    localStorage.setItem("profileUrl",Url.data)
  }

  render() {
    const { users } = this.state;
    const { localUsers } = this.state;
    this.getProfilePic()
    return (
      <div className={"user-ml"}>
        <Sidebar />
        <Header />

        <div className="tg-dashboardbanner">
          <h1>Profile</h1>
          <ol className="tg-breadcrumb">
            <li>
              <a href="javascript:void(0);">Home</a>
            </li>
            <li>
              <a href="javascript:void(0);">Edit Profile</a>
            </li>
          </ol>
        </div>

        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-dbsectionspace tg-haslayout">
            <div className="row">
              <form className="tg-formtheme tg-formdashboard">
                <fieldset>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="tg-dashboardbox">
                      <div className="tg-dashboardboxtitle">
                        <h2>Edit Account Information</h2>
                      </div>
                      <div className="tg-dashboardholder">
                        <div className="tg-otherfilters">
                          <div className="row">
                            <form onSubmit={this.handleSubmit}>

                              <div>
                              {localStorage.getItem("profileUrl") != "null"?
                              <img 
                               src={localStorage.getItem("profileUrl")}
                               width={"100"} style={{borderRadius:'50%', border : "1px solid lightgray",height: "85px"}}
                               alt="image description"
                               className="mCS_img_loaded"
                               />:<img
                               src={this.state.image ? this.state.image : Avtar}
                               width={"100"} style={{borderRadius:'50%', border : "1px solid lightgray"}}
                               alt="image description"
                               className="mCS_img_loaded"
                             />

                            }
                              
                               <div className="row">
                                 <div className="col-sm-6">
                                  <input
                                    name="uploadImg"
                                    onChange={this.fileSelectedHandler}
                                    type="file"
                                    style={{border: '1px solid #d2d2d2', borderRadius: '5px', height: '40px',padding: '8px', width: '100%', marginTop:'20px', marginBottom:'20px'}}
                                    required={this.state.type =="DEVICE"? true : false}
                                   />
                                   </div>
                                   {this.state.imgUploading ? (<div className="col-sm-6">
                                      <Loader type="Puff" color="#00BFFF" height={40} width={40}  style={{marginTop:'20px'}}/>
                                   </div>) : null}

                                   </div>


                                  
                                {/* <button onClick={this.fileUploadHandler}>Upload</button> */}
                              </div>
                               
                  
  
                           

                              <fieldset>
                                <div
                                  className="form-group"
                                  style={{
                                    marginBottom: "10px",
                                    width: "500px"
                                  }}
                                >
                                  {users.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      value={localUsers.name}
                                      onChange={this.onInputChange}
                                      disabled
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      placeholder={users.name}
                                      onChange={this.onInputChange}
                                    />
                                  )}
                                </div>

                                <div
                                  className="form-group"
                                  style={{
                                    marginBottom: "10px",
                                    width: "500px"
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="email"
                                    value={
                                      users.length == 0
                                        ? localUsers.email
                                        : users.email
                                    }
                                    disabled={true}
                                  />
                                </div>
                                <br />
                                {/*<div className={'row'}>*/}
                                {/*<div className="col-sm-12" style={{marginBottom:'10px', width:'500px'}}>*/}
                                {/*<input type="number" className="form-control" value="" placeholder={'Mobile'} />*/}
                                {/*</div>*/}
                                {/*</div>*/}

                                <div className={"row"}>
                                  <div
                                    className="col-sm-12"
                                    style={{
                                      marginBottom: "10px",
                                      width: "500px",
                                      float: "left"
                                    }}
                                  >
                                    <Link
                                      to={"/dashboard"}
                                      className="btn btn-danger"
                                      style={{ marginRight: "20px" }}
                                    >
                                      CANCEL
                                    </Link>

                                    <input
                                      type="submit"
                                      className="btn btn-success"
                                      value="SAVE"
                                    />
                                  </div>
                                </div>
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default editProfile;
