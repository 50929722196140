import React from 'react';
import Navbar from '../../includes/navbar';

import Footer from '../../includes/footer'
import Registration from '../../images-custom/trademark_registration.png'

import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import Button from 'react-bootstrap/Button';

import 'font-awesome/css/font-awesome.min.css';
import QuickContact from './quickContact';

const myaccord = {
    width:'100%', textAlign:'left'
}



class trademarkRegistration extends React.Component {

    render(){

        return (
            <div>
                <Navbar />

                <div className="container">

                        <section className="tg-sectionspace tg-haslayout">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-10">
                                    <h2>TRADE MARKS</h2>
                                        <hr className="hr-custom" />

                                        <p>In India Trade marks are registered with Controller General of Patents Designs and Trade marks, Ministry of Commerce and Industry, Government of India. Trade marks are registered under the Trade mark Act, 1999.</p>

                                    <h3>REGISTRATION</h3>
                                        <hr className="hr-custom" />


                                    <p>Trade mark can be registered for words, phrase, symbol, sound, picture or a combination of these. It is used to differentiate a business from others. If your mark is distinctive and unique and if you have been using the mark prior to applying, there is a good chance that it will get registered.</p>
                                    <p>A trade mark for goods is registered in classes 1-34 and for services under classes 35-45. To register a trade mark it should be distinctive, not deceptively similar to other marks, should not be descriptive, etc.</p>
                                    <p>Trade marks can be registered for marks that are:</p>
                                    <ul>
                                        <li><b>Prior user</b> – mark currently being used in trade/business, etc.</li>
                                        <li><b>Proposed to be used</b> – not being used currently but meant for future use.</li>
                                    </ul>
                                    <p>To prove that mark is being used prior, supporting documents have to be provided like invoices, bills, publication, ads, etc.</p>
                                    <p>Below is the process for applying for the trade mark</p>

                                    <div className="text-center">
                                        <img src={Registration} />
                                        <p>*A smooth application (with no objections and oppositions) takes at least 6 months to be registered.</p>
                                    </div>

                                    <p><b>TRADEMYIP can help in providing free search, free consultation, preparation and filing of a trade marks in all classes. </b></p>

                                    <h4 className="upper">Benefits of applying for a trade mark</h4>
                                        <hr className="hr-custom" />
                                    <ul>
                                        <li><u><b>Recognition</b></u> – Your trade mark is registered and recognized by Registrar of trade mark. This creates a presence for your mark when it is search in the database.</li>
                                        <li><u><b>Exclusivity</b></u> – As an owner of trade mark you have exclusive right to use it on your products and/or services. You can prevent and take legal action against anyone copying your trademark or creating deceptively similar mark.</li>
                                        <li><u><b>Goodwill</b></u> – A trade mark can help build goodwill as the customers can identify and trust your trade mark on the products and services being sold.</li>
                                        <li><u><b>Branding</b></u> – A trade mark can help you advertise and market your products and/or services.</li>
                                        <li><u><b>Asset</b></u> – A trade mark is an intellectual property which means the owner can sell or franchise it for commercial use.
                                        </li>
                                    </ul>

                                    <p>Below is some important information to understand process and the documents required for filing of trade mark.</p>

                                    <Accordion defaultActiveKey="1">
                                        <Card>
                                            <Card.Header  class="accord">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" class="accord-text" style={myaccord}>
                                                    <span class="accord-text">
                                                        <span style={{color:'white', marginRight:'10px',}}><i className="fa fa-plus-circle"></i></span>I. Filing process</span>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <h4><b>1. Trade mark search</b></h4>
                                                    <p>First step before filing for a trade mark is to search for similar trade marks in the database provided by trademark registry online.</p>

                                                    <h4><b>2. Filing of application</b></h4>
                                                    <p>A trade mark can be filed in 3 ways:</p>
                                                    <ul>
                                                        <li>a.	Single application in one class – This is done when the application is only dealing (or going to) deal in goods/services of just one class.</li>
                                                        <li>b.	Multiple applications in one class each – This is done if the applicant is going to be creating good/services that fall in multiple classes (multiple products for eg.)</li>
                                                        <li>c.	Multiclass application or single application for various classes -  Same as #2 above but the application is filed once with information for all the good/services for all the classes being applied for in one shot</li>
                                                    </ul>

                                                    <p>The difference between #a and #b is explained in #4 below although the official fees is same.</p>
                                                    <p>TM-A form is filed with the trade marks registry online containing the following information</p>

                                                    <ul>
                                                        <li>Name and address of the applicant</li>
                                                        <li>Word mark or Logo (with the image)</li>
                                                        <li>Class along with description of goods/services</li>
                                                        <li>Trade mark use date (with supported document) if any or proposed to be used</li>
                                                        <li>Documents related to company (like MSME, COI, DIPP etc.) or if it’s an individual/sole proprietor.</li>
                                                        <li>Documents to show proof of prior use (if applicable)</li>
                                                    </ul>

                                                    <p>The application is filed online along with the government fees. Once the fee is paid an application number is generated and allotted to your application. This application number is the one used for identifying the trade mark in future.</p>



                                                    <h4><b>3. Vienna Codification</b></h4>
                                                    <p>After the application is filed it is sent for Vienna Codification. Vienna codification is done by the Registry so that trademark searches can be conducted for artworks/logos.</p>



                                                    <h4><b>4. Examination</b></h4>
                                                    <p>The trade mark application is assigned to a trade mark office in Mumbai. The officer will check for the following</p>

                                                    <ul>
                                                        <li>If all the documents are correctly provided and goods and services are falling in the correct class</li>
                                                         <li>If the trade mark falls under section 9 (absolute) or section 11 (relative) grounds for refusal.</li>
                                                    </ul>

                                                    <p>Based on the examination, a report is generated stating any objections (if any). If there are no objections then it is accepted and sent for publication in trade mark journal.</p>
                                                    <p>In case of a multiclass application it is possible that the objection was raised only for a particular class but the whole application is considered to be objected. This raises a risk for refusal of the whole application if incase the objection is not cleared. While in case of multiple single applications being filed each application is treated separately in its entirety and the objection of one does not spill over to other application.</p>



                                                    <h4><b>5. Publication in Journal</b></h4>
                                                    <p>The proposed trade mark is then published in the weekly journal available to public which contains all the other trade marks accepted by the registrar. Each page in the journal contains the details of the trade mark along with the name and address of the application, class and goods for which it needs to be registered.</p>
                                                    <p>Anyone can oppose the published trade mark if they seem to be deceptively similar or can cause harm to their business. The maximum time allowed to oppose should be within 4 months from the date of publication.</p>


                                                    <h4><b>6. Registration</b></h4>
                                                    <p>If there are no objections to the trade mark or there was a hearing due to opposition which has been concluded in favor of the applicant then the trade is sent for registration. A registration certificate is issued in favor of the application which gives the applicant exclusive right to use it for the period of 10 years which can then be renewed.</p>


                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header class="accord">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="2" style={myaccord}>
                                                    <span className="accord-text"><span style={{color:'white', marginRight:'10px'}}><i className="fa fa-plus-circle"></i></span>II. Documents for filing</span>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body class="ml-30 card-body">
                                                    <p>Below is the list of documents required for filing a trade mark application.</p>

                                                    <h4><b>Individual/Sole Proprietor</b></h4>
                                                    <p>The documents required for an Individual and Sole proprietor are same</p>

                                                    <ul>
                                                        <li>Letter of authorization signed and scanned. This is the Power of attorney given in the name of the trade mark agent/lawyer to file the application on your behalf. Originals are required for our records.</li>
                                                        <li>Word mark or copy of logo (in case of image should be in jpg format not more than 8 X 8 cm).</li>
                                                        <li>1)	Documentary evidence of the date in case the trade mark is already in use.</li>
                                                        <li>Assignment deed or user affidavit - optional</li>
                                                    </ul>
                                                    <p>Official fees for filing a trade mark application in single class is <b>INR 4500 if filed online.</b></p>



                                                    <h4><b>Company/Partnership (including startups)</b></h4>
                                                    <p>For companies and startups, the official fees is <b>INR 4500</b> (online) given the documents (MSME, COI, DIPP, etc.) are provided. Otherwise the fees is <b>INR 9000</b> (online).</p>
                                                    <ul>
                                                        <li>Letter of authorization signed (with company seal) and scanned from authorized signatory. This is the Power of attorney given in the name of the trade mark agent/lawyer to file the application on your behalf. Originals are required for our records.</li>
                                                        <li>Word mark or copy of logo (in case of image should be in jpg format 8 X 8 cm).</li>
                                                        <li>1)	Documentary evidence of the date in case the trade mark is already in use.</li>
                                                        <li>MSME certificate (if applicable)</li>
                                                        <li>COI certificate or partnership deed.</li>
                                                    </ul>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header class="accord">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="3" style={myaccord}>
                                                    <span className="accord-text"><span style={{color:'white', marginRight:'10px'}}><i className="fa fa-plus-circle"></i></span>III. Trade Mark Symbols</span>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body class="card-body">

                                                    <p><sup><b>TM</b></sup> – This symbol can be used when the trade mark is applied with the trade mark registry and is in pending state. This symbol is meant for classes 1-34 or for goods.</p>
                                                    <p><sup><b>SM</b></sup> - This stands for Service Mark and is similar to the one above but this is meant for classes 35-45 or for services.</p>
                                                    <p><sup><b>R</b></sup> – This symbol should be used once the trade mark is registered with the registry and a registration certificate has been issued.</p>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header class="accord">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="4" style={myaccord}>
                                                    <span className="accord-text"><span style={{color:'white', marginRight:'10px'}}><i className="fa fa-plus-circle"></i></span>IV. Trade mark Classes</span>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="4">
                                                <Card.Body class="card-body">

                                                    <h3><b>Trademark classification of goods.</b></h3>

                                                    <b>Class 1.- Chemicals</b>
                                                    <p>Chemical used in industry, science, photography, agriculture, horticulture and forestry; unprocessed artificial resins, unprocessed plastics; manures; fire extinguishing compositions; tempering and soldering preparations; chemical substances for preserving foodstuffs; tanning substances; adhesive used in industry.</p>

                                                    <b>Class 2.- Paints </b>
                                                    <p>Paints, varnishes, lacquers; preservatives against rust and against deterioration of wood; colorants; mordents; raw natural resins; metals in foil and powder form for painters; decorators; printers and artists. </p>


                                                    <b>Class 3.- Cosmetics & cleaning preparations</b>
                                                    <p>Bleaching preparations and other substances for laundry use; cleaning; polishing; scouring and abrasive preparations; soaps; perfumery, essential oils, cosmetics, hair lotions, dentifrices.</p>

                                                    <b>Class 4.- Oil & Lubricants</b>
                                                    <p>Industrial oils and greases; lubricants; dust absorbing, wetting and binding compositions; fuels- including motor spirit and illuminants; candles, wicks.</p>



                                                    <b>Class 5.- Pharmaceuticals</b>
                                                    <p>Pharmaceutical, veterinary and sanitary preparations; dietetic substances adapted for medical use, food for babies; plasters, materials for dressings; materials for stopping teeth, dental wax; disinfectants; preparation for destroying vermin; fungicides, herbicides.</p>


                                                    <b>Class 6.- Metal Goods </b>
                                                    <p>Common metals and their alloys; metal building materials; transportable buildings of metal; materials of metal for railway tracks; non-electric cables and wires of common metal; ironmongery, small items of metal hardware; pipes and tubes of metal; safes; goods of common metal not included in other classes; ores.</p>


                                                    <b>Class 7.- Machinery</b>
                                                    <p> Machines and machine tools; motors and engines- except for land vehicles; machine coupling and transmission components- except for land vehicles; agricultural implements other than hand-operated; incubators for eggs.</p>


                                                    <b>Class 8.- Hand Tools </b>
                                                   <p> Hand tools and implements- hand-operated; cutlery; side arms; razors.</p>


                                                    <b>Class 9.- Scientific & Electrical Equipment, Computers </b>
                                                    <p> Scientific, nautical, surveying, electric, photographic, cinematographic, optical, weighing, measuring, signalling, checking- supervision, life saving and teaching apparatus and instruments; apparatus for recording, transmission or reproduction of sound or images; magnetic data carriers, recording discs; automatic vending machines and mechanisms for coin-operated apparatus; cash registers, calculating machines, data processing equipment and computers; fire extinguishing apparatus.</p>


                                                    <b>Class 10.- Medical Equipment</b>
                                                    <p>Surgical, medical, dental and veterinary apparatus and instruments, artificial limbs, eyes and teeth; orthopedic articles; suture materials.</p>


                                                    <b>Class 11.- Lighting Equipment</b>
                                                    <p>Apparatus for lighting, heating, steam generating, cooking, refrigerating, drying ventilating, water supply and sanitary purposes.</p>


                                                    <b>  Class 12.- Vehicles</b>
                                                    <p> Vehicles; apparatus for locomotion by land, air or water.</p>

                                                    <b> Class 13.- Firearms</b>
                                                    <p>Firearms; ammunition and projectiles; explosives; fire works.</p>

                                                    <b> Class 14.- Jewelry</b>
                                                    <p> Precious metals and their alloys and goods in precious metals or coated therewith, not included in other classes; jewelry, precious stones; horological and other chronometric instruments.</p>




                                                    <b>  Class 15.- Musical Instruments</b>
                                                    <p>Musical instruments.</p>

                                                    <b>  Class 16.- Stationery & Printed Matter</b>
                                                    <p> Paper, cardboard and goods made from these materials, not included in other classes; printed matter; bookbinding material; photographs; stationery; adhesives for stationery or household purposes; artists’ materials; paint brushes; typewriters and office requisites- except furniture; instructional and teaching material- except apparatus; plastic materials for packaging- not included in other classes; playing cards; printers’ type; printing blocks.</p>



                                                    <b> Class 17.- Rubber Goods & Flexible Pipes</b>
                                                    <p>Rubber, gutta percha, gum, asbestos, mica and goods made from these materials and not included in other classes; plastics in extruded form for use in manufacture; packing, stopping and insulating materials; flexible pipes, not of metal.</p>


                                                    <b> Class 18.- Leather Goods</b>
                                                    <p>Leather and imitations of leather, and goods made of these materials and not included in other classes; animal skins, hides, trunks and travelling bags; umbrellas, parasols and walking sticks; whips, harness and saddlery.</p>


                                                    <b>Class 19.- Non-Metallic Building Materials & Rigid Pipes</b>
                                                    <p>Building materials,- non-metallic, non-metallic rigid pipes for building; asphalt, pitch and bitumen; nonmetallic transportable buildings; monuments, not of metal.</p>


                                                    <b>Class 20.- Furniture & related goods</b>
                                                    <p>Furniture, mirrors, picture frames; goods- not included in other classes of wood, cork, reed, cane, wicker, horn, bone, ivory, whalebone, shell, amber, mother- of-pearl, meerschaum and substitutes for all these materials, or of plastics</p>



                                                    <b>Class 21. - - Household Items</b>
                                                    <p>Household or kitchen utensils and containers(not of precious metal or coated therewith; combs and sponges; brushes(except paints brushes; brush making materials; articles for cleaning purposes; steelwool; unworked or semi-worked glass- except glass used in building; glassware, porcelain and earthenware not included in other classes.</p>


                                                    <b> Class 22.- Ropes & related items</b>
                                                    <p>Ropes, string, nets, tents, awnings, tarpaulins, sails, sacks and bags- not included in other classes padding and stuffing materials(except of rubber or plastics; raw fibrous textile materials.</p>


                                                    <b> Class 23.- Yarns & Threads</b>
                                                    <p>Yarns and threads, for textile use.</p>

                                                    <b>Class 24.- Textiles (Fabrics)</b>
                                                    <p>Textiles and textile goods, not included in other classes; bed and table covers.</p>


                                                    <b> Class 25.- Garments & Footwear</b>
                                                    <p>Clothing, footwear, headgear.</p>


                                                    <b>Class 26.- Fancy Goods</b>
                                                    <p>Lace and embroidery, ribbons and braid; buttons, hooks and eyes, pins and needles; artificial flowers.</p>

                                                    <b>Class 27.- Floor coverings</b>
                                                    <p>Carpets, rugs, mats and matting, linoleum and other materials for covering existing floors; wall hangings-non-textile.</p>


                                                    <b>Class 28.- Toys & Sporting Articles</b>
                                                    <p>Games and playthings, gymnastic and sporting articles not included in other classes; decorations for Christmas trees.</p>

                                                    <b>Class 29.- Meats & Processed Foods</b>
                                                    <p>Meat, fish, poultry and game; meat extracts; preserved, dried and cooked fruits and vegetables; jellies, jams, fruit sauces; eggs, milk and milk products; edible oils and fats.</p>

                                                    <b>Class 30.- Tea, Coffee & cereals preparations</b>
                                                    <p>Coffee, tea, cocoa, sugar, rice, tapioca, sago, artificial coffee; flour and preparations made from cereals, bread, pastry and confectionery, ices; honey, treacle; yeast, baking powder; salt, mustard; vinegar, sauces,- condiments; spices; ice.</p>

                                                    <b>Class 31.- Fruits, Flowers, Vegetables & Agricultural Products</b>
                                                    <p>Agricultural, horticultural and forestry products and grains not included in other classes; live animals; fresh fruits and vegetables; seeds, natural plants and flowers; foodstuffs for animals, malt.</p>



                                                    <b>Class 32.- Light Beverages</b>
                                                    <p>Beers, mineral and aerated waters, and other non-alcoholic drinks; fruit drinks and fruit juices; syrups and other preparations for making beverages.</p>


                                                    <b>Class 33.- Wine and spirits</b>
                                                    <p>Alcoholic beverages- except beers.</p>

                                                    <b>Class 34.- Smokers’ articles</b>
                                                    <p>Tobacco, smokers’ articles, matches.</p>




                                                    <hr className="hr-custom" />
                                                    <h3><b>Trademark Classification of Services</b></h3>


                                                    <b>Class 35.- Advertising, business, retailing and online services</b>
                                                    <p>Advertising, business management, business administration, office functions.</p>

                                                    <b>Class 36.- Real estate, insurance and financial</b>
                                                    <p>Insurance, financial affairs; monetary affairs; real estate affairs.</p>


                                                    <b>Class 37.- Building construction and repair</b>
                                                    <p>Building construction; repair; installation services.</p>

                                                    <b>Class 38.- Broadcasting and telecommunications</b>
                                                    <p>Telecommunications.</p>

                                                    <b>Class 39.- Transportation & Travels</b>
                                                    <p>Transport; packaging and storage of goods; travel arrangement.</p>


                                                    <b>Class 40.- Treatment of Materials</b>
                                                    <p>Treatment of materials.</p>


                                                    <b>Class 41.- Education & Entertainment</b>
                                                    <p>Education; providing of training; entertainment; sporting and cultural activities.</p>


                                                    <b>Class 42.- Design & Development Of Software & Hardware</b>
                                                    <p>Scientific and technological services and research and design relating thereto; industrial analysis and research services; design and development of computer hardware and software.</p>

                                                    <b>Class 43.- Restaurants & Hotel</b>
                                                    <p>Services for providing food and drink; temporary accommodation.</p>


                                                    <b>Class 44.- Medical, Beauty & Agricultural</b>
                                                    <p>Medical services, veterinary services, hygienic and beauty care for human beings or animals; agriculture, horticulture and forestry services.</p>



                                                    <b>Class 45.- Legal, Social & Security</b>
                                                    <p> Services Legal services; security services for the protection of property and individuals; personal and social services rendered by others to meet the needs of individuals</p>


                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                                </div>
                            </div>
                            <QuickContact/>


                        </section>
                </div>
                <Footer />
            </div>
        );
    }
}

export default trademarkRegistration;
