import React, { Component } from "react";
import axios from "axios";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { Auth } from "aws-amplify";
import config from "../../environment";
import Select from "react-select";
import * as moment from "moment";
import Header from "../header";
import Sidebar from "../sidebar";

var Loader = require("react-loader");
registerPlugin(FilePondPluginFileValidateType);
class Trademark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      country: "",
      applicationNumber: "",
      applicationDate: "",
      classes: null,
      status: "",
      price: "",
      agreement: "",
      links: [{ text: "NA", url: "NA" }],
      type: "",
      summary: "",
      deviceImg: null,
      countriesList: [],
      classesList: [],
      imageFile: [],
      document: [],
      user: [],
      loaded: false,
      token: localStorage.getItem("token"),
      formErrors: {
        title: {
          valid: "",
          error: ""
        },
        country: {
          valid: "",
          error: ""
        },
        applicationNumber: {
          valid: "",
          error: ""
        },
        status: {
          valid: "",
          error: ""
        },
        classes: {
          valid: "",
          error: ""
        },
        agreement: {
          valid: "",
          error: ""
        },
        type: {
          valid: "",
          error: ""
        },
        image: {
          valid: "",
          error: ""
        }
      },
      documentValid: true,
      documentError: "",
      imageInvalidType: true,
      isFormValid: true,
      isFileSectionTriggered: true,
      ipData: {
        title: "",
        country: "",
        application_number: "",
        application_date: "",
        status: "",
        price: "",
        links: [{ text: "NA", url: "NA" }],
        summary: ""
      },
      ipId: "",
      uploadedDoc: [],
      uploadedImage: [],
      isDocLoaded: true,
      isImageLoaded: true,
      deviceImageUrl: "",
      isDeviceImage: false,
      prevImagesData: [],
      prevDocumentsData: [],
      isButtonDisabled: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onApplicationDateChange = this.onApplicationDateChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }
  componentDidMount() {
    this.getUser();

    this.getClassesList();
    this.getCountriesList();
    this.ipDetails();
  }

  ipDetails = () => {
    console.log("this");
    const { id, cat } = this.props.match.params;
    this.setState({ ipId: id });
    const url =
      config.production.api + `fetchbyid?category=%22${cat}%22&id=%22${id}%22`;
    axios.get(url).then(response => {
      //console.log(response, "rajesh");
      // this.showSimilarIPs();
      this.setState({
        ipData: response.data.Item
      });
      var imagesArr = [];
      var docArr = [];
      var deviceArr = [];
      console.log(this.state.ipData.images, "ipData");
      if (this.state.ipData.images != "NA") {
        imagesArr = Object.values(this.state.ipData.images);
      }
      if (this.state.ipData.documents != "NA") {
        docArr = Object.values(this.state.ipData.documents);
      }

      deviceArr = Object.values(this.state.ipData.deviceImg);

      this.setState({
        title: this.state.ipData.title,
        country: this.state.ipData.country,
        applicationNumber: this.state.ipData.application_number,
        applicationDate: this.state.ipData.application_date,
        classes: this.state.ipData.classes,
        status: this.state.ipData.status,
        price: this.state.ipData.price,
        agreement: this.state.ipData.agreement,
        links: this.state.ipData.links,
        type: this.state.ipData.type,
        summary: this.state.ipData.summary,
        uploadedDoc: docArr,
        uploadedImage: imagesArr,
        deviceImageUrl: deviceArr
      });

      console.log(deviceArr, "dImage");

      console.log(this.state.deviceImageUrl, "dImage");

      if (deviceArr.length == 0) {
        this.setState({
          isDeviceImage: true
        });
      }
      // console.log(this.state.ipData.links, "ip");
      // for (let i = 0; i < this.state.ipData.links.length; i++) {
      //   console.log(this.state.ipData.links[i], "ip");
      //   this.state.links.push({
      //     text: this.state.ipData.links[i].text,
      //     url: this.state.ipData.links[i].url
      //   });
      // }

      console.log(this.state.ipData, "ipData");
    });
  };
  getUser() {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        this.setState({ user: user.attributes });
      })
      .catch(err => console.log(err));
  }

  removeImages = imageIndex => {
    const images = [...this.state.uploadedImage];
    images.splice(imageIndex, 1);
    this.setState({
      uploadedImage: images
    });

    console.log(images);
    if (images.length == 0) {
      this.setState({
        imageFile: [],
        isImageLoaded: false
      });
    }

    // this.setState({
    //   uploadedImage: "",
    //   imageFile: [],
    //   isImageLoaded: false
    // });
  };
  removeDocs = docIndex => {
    const docs = [...this.state.uploadedDoc];
    docs.splice(docIndex, 1);
    this.setState({
      uploadedDoc: docs
    });
    if (docs.length == 0) {
      this.setState({
        document: [],
        isDocLoaded: false,
        isDocUploaded: false
      });
    }
    // this.setState({
    //   uploadedDoc: "",
    //   document: [],
    //   isDocLoaded: false,
    //   isDocUploaded: false
    // });
    console.log(this.state.uploadedDoc, "doc");
  };
  removeDeviceImage = () => {
    this.setState({
      deviceImageUrl: "",
      isDeviceImage: true
    });
  };
  async onSubmit(e) {
    //console.log("called");
    e.preventDefault();
    this.postFileData();
    if (this.state.isFormValid) {
      console.log("called");
      this.postFileData();
    }
  }
  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.validateField(name, value);
    });

    if (this.state.type == "WORD") {
      this.setState({
        deviceImageUrl: "",
        isDeviceImage: true
      });
    }
  }
  fileSelectedHandler = event => {
    const file = event.target.files[0];
    console.log(file);
    this.setState({
      deviceImg: file
    });
    console.log(this.state.deviceImg);
  };
  onApplicationDateChange(e) {
    this.setState({ applicationDate: e.target.value });
  }
  handleLinkChange = e => {
    if (["text", "url"].includes(e.target.name)) {
      let links = [...this.state.links];
      links[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ links });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addLinks = e => {
    this.setState(prevState => ({
      links: [...prevState.links, { text: "", url: "" }]
    }));
  };
  removeLinks = index => {
    this.setState({
      links: this.state.links.filter((v, i) => i !== index)
    });
  };

  getClassesList() {
    this.state.classesList.push({ class: 99 });
    for (let i = 1; i <= 45; i++) {
      this.state.classesList.push({ class: i });
    }
  }

  async getCountriesList() {
    let response = await axios.get("https://restcountries.eu/rest/v1/all", {});
    this.setState({ countriesList: response.data });
  }
  handleListChange = async e => {
    console.log(e.target.value);
    await this.setState({ classes: e.target.value });
    // this.validateField("classes", classes);
  };

  validateField(fieldName, value) {
    let fieldValidation = this.state.formErrors;
    switch (fieldName) {
      case "title":
        fieldValidation.title.valid = value !== "";
        fieldValidation.title.error = fieldValidation.title.valid
          ? ""
          : "title can't be empty";
        break;
      case "country":
        fieldValidation.country.valid = value !== "0";
        fieldValidation.country.error = fieldValidation.country.valid
          ? ""
          : "country can't be empty";
        break;
      case "applicationNumber":
        fieldValidation.applicationNumber.valid = value !== "";
        fieldValidation.applicationNumber.error = fieldValidation
          .applicationNumber.valid
          ? ""
          : "application number can't be empty";
        break;
      case "status":
        fieldValidation.status.valid = value !== "0";
        fieldValidation.status.error = fieldValidation.status.valid
          ? ""
          : "status can't be empty";
        break;
      case "agreement":
        fieldValidation.agreement.valid = value !== "0";
        fieldValidation.agreement.error = fieldValidation.agreement.valid
          ? ""
          : "agreement can't be empty";
        break;
      case "classes":
        fieldValidation.classes.valid = value !== null || "";
        fieldValidation.classes.error = fieldValidation.classes.valid
          ? ""
          : "classes can't be empty";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidation
      },
      this.validateForm
    );
  }

  validateForm() {
    const {
      formErrors,
      imageFile,
      document,
      isFileSectionTriggered,
      documentError,
      documentValid,
      imageInvalidType
    } = this.state;
    if (isFileSectionTriggered) {
      formErrors.image.valid = true;
      formErrors.image.error = formErrors.image.valid
        ? ""
        : "At least one image is required";
      if (document.length) {
        if (documentError.length) {
          this.setState({ documentValid: false });
        }
      } else {
        this.setState({ documentValid: true });
      }
    }
    if (imageFile.length) {
      this.setState({ imageInvalidType: true });
    }
    this.setState({
      isFormValid:
        formErrors.title.valid &&
        formErrors.country.valid &&
        formErrors.applicationNumber.valid &&
        formErrors.status.valid &&
        formErrors.agreement.valid &&
        formErrors.classes.valid &&
        formErrors.image.valid &&
        imageInvalidType &&
        this.state.documentValid
    });
  }

  async postFileData() {
    this.setState({ loaded: true });
    const images = [];
    const documents = [];
    let allDocuments = {};
    let allImages = {};
    let deviceImgArr = [];
    let deviceImages = {};

    for (let counter = 0; counter < this.state.imageFile.length; counter++) {
      let result_base64 = await new Promise(resolve => {
        let fileReader = new FileReader();
        fileReader.onload = e => resolve(fileReader.result);
        fileReader.readAsDataURL(this.state.imageFile[counter]);
      });
      images.push(result_base64);
      //console.log(images);
    }

    for (let counter = 0; counter < this.state.document.length; counter++) {
      let result_base64 = await new Promise(resolve => {
        let fileReader = new FileReader();
        fileReader.onload = e => resolve(fileReader.result);
        fileReader.readAsDataURL(this.state.document[counter]);
      });
      documents.push(result_base64);
    }
    console.log(this.state.deviceImg, "dj");
    if (this.state.deviceImg) {
      for (let counter = 0; counter < 1; counter++) {
        let result_base64 = await new Promise(resolve => {
          let fileReader = new FileReader();
          fileReader.onload = e => resolve(fileReader.result);
          fileReader.readAsDataURL(this.state.deviceImg);
        });
        deviceImgArr.push(result_base64);
      }
      deviceImgArr.push(this.state.deviceImg);
      await Promise.all(
        deviceImgArr.map(async (item, index) => {
          delete axios.defaults.headers.common["token"];
          let patch = {
            email: this.state.user.email,
            category: this.props.category,
            file: item,
            type: "image"
          };
          await axios
            .post(
                `${config.production.api}fileupload`,
              patch
            )
            .then(res => {
              deviceImages["key" + index] = res.data.body;
              console.log(deviceImages, "dk");
            })
            .catch(error => {
              console.log(error);
            });
        })
      );
    }

    await Promise.all(
      images.map(async (item, index) => {
        delete axios.defaults.headers.common["token"];
        let patch = {
          email: this.state.user.email,
          category: this.props.category,
          file: item,
          type: "image"
        };
        await axios
          .post(
              `${config.production.api}fileupload`,
            patch
          )
          .then(res => {
            allImages["key" + index] = res.data.body;
            console.log(res.data.body);
            console.log(this.state.uploadedImage, "up");
            this.setState({
              uploadedImage: [...this.state.uploadedImage, res.data.body]
            });
            console.log(this.state.uploadedImage, "images");
          })
          .catch(error => {
            console.log(error);
          });
      })
    );

    await Promise.all(
      documents.map(async (item, index) => {
        console.log(item);
        let patch = {
          email: this.state.user.email,
          type: "document",
          file: item,
          category: this.props.category
        };
        await axios
          .post(
              `${config.production.api}fileupload`,
            patch
          )
          .then(res => {
            allDocuments["key" + index] = res.data.body;

            this.setState(prevState => ({
              uploadedDoc: [...prevState.uploadedDoc, res.data.body]
            }));
          })
          .catch(error => {
            console.log("error is", error);
          });
      })
    );
    // this.setState({
    //   uploadedImage: allImages,
    //   uploadedDoc: allDocuments
    // });

    this.postTrademark(allImages, allDocuments, deviceImages);
  }
  async postTrademark(allImg, allDoc, deviceImages) {
    const {
      title,
      country,
      applicationNumber,
      applicationDate,
      classes,
      status,
      price,
      agreement,
      links,
      type,
      summary,
      user,
      token,
      imageFile,
      document,
      deviceImageUrl
    } = this.state;
    console.log(deviceImageUrl, "dk");
    console.log(deviceImages, "dk");
    const trademarkData = {
      title: title !== "" ? title : "NA",
      country: country !== "" ? country : "NA",
      application_number: applicationNumber !== "" ? applicationNumber : "NA",
      application_date: applicationDate !== "" ? applicationDate : "NA",
      classes: classes !== "" ? classes : "NA",
      type: type !== "" ? type : "NA",
      status: status !== "" ? status : "NA",
      price: price !== "" ? price : "NA",
      agreement: agreement !== "" ? agreement : "NA",
      links: links !== "" ? links : [{ text: "NA", url: "NA" }],
      summary: summary !== "" ? summary : "NA",
      images: this.state.uploadedImage.length ? this.state.uploadedImage : "NA",
      documents: this.state.uploadedDoc.length ? this.state.uploadedDoc : "NA",
      category: "trademark",
      email: localStorage.getItem("email"),
      user: localStorage.getItem("name"),
      id: this.state.ipId,
      deviceImg: deviceImageUrl.length > 0 ? deviceImageUrl : deviceImages
    };

    console.log(trademarkData);

    try {
      let abc = this.props.history;
      let response = await axios.put(
        `${config.production.api}update_posts/update-trademark`,
        trademarkData,
        { headers: { token: token } }
      );
      if (response.data.status == 200) {
        console.log(response, "RES");
        this.setState({ loaded: false, isButtonDisabled: true });
        ToastsStore.success(
          "IP submitted successfully and is in under review."
        );
        setTimeout(function() {
          abc.push("/dashboard/all"); // Delay time by 3 Sec
        }, 3000);
      } else {
        this.setState({ loaded: false });
        ToastsStore.info("Please try again");
      }
    } catch (error) {
      this.setState({ loaded: false });
      ToastsStore.error(
        "IP could not be posted due to Network Error, please try again later."
      );
    }
  }
  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }
  render() {
    //console.log(this.state.ipData.classes.label, "classes");
    //console.log(this.state.ipData.length, "cou");

    //let classesOptions = {};
    // setTimeout(() => {
    //   console.log(this.state.ipData.classes.value, "country");
    //   classesOptions = {
    //     name: this.state.ipData.classes.label,
    //     value: this.state.ipData.classes.value
    //   };

    // }, 1000);

    const {
      countriesList,
      classesList,
      documentError,
      links,
      formErrors
    } = this.state;
    const errorStyle = {
      color: "red",
      fontSize: "10px",
      fontWeight: 400
    };

    const Link = (
      <div onChange={this.handleLinkChange}>
        {links.map((val, idx) => {
          let linkId = `link-${idx}`,
            urlId = `url-${idx}`;
          return (
            <div key={idx}>
              {/* <label htmlFor={linkId}>{`Link #${idx + 1}`}</label> */}
              <div className="form-group" />
              <input
                type="text"
                name="text"
                data-id={idx}
                id={linkId}
                className="form-control"
                placeholder="Enter Text to Display"
                value={this.state.links[idx].text}
                defaultValue={this.state.ipData.links[idx].text}
              />
              <div className="form-group" />
              <input
                type="text"
                name="url"
                data-id={idx}
                id={urlId}
                className="form-control"
                placeholder="Enter Url"
                value={this.state.links[idx].url}
                defaultValue={this.state.ipData.links[idx].url}
              />
              {idx > 0 && (
                <div>
                  <button
                    className="btn btn-info btn-block mt-1"
                    type="button"
                    onClick={() => this.removeLinks(idx)}
                    style={{ marginTop: "10px", width: "100px" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          );
        })}
        <button
          className="btn btn-success btn-block mt-4"
          type="button"
          onClick={this.addLinks}
          style={{ marginTop: "10px", width: "100px" }}
        >
          Add More
        </button>
      </div>
    );
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: "40px",
        "&:hover": { borderColor: "#5cb85c" },
        border: "1px solid lightgray",
        boxShadow: "none"
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 1
      }),
      clearIndicator: base => ({
        ...base,
        padding: 4
      }),
      valueContainer: base => ({
        ...base,
        padding: "0px 6px"
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0
      })
    };
    return (
      <div className={"user-ml"}>
        <Sidebar />

        <Header />

        <div className="tg-dashboardbanner">
          <h1>Edit Trademark</h1>
          <ol className="tg-breadcrumb">
            <li>
              <a href="javascript:void(0);">Home</a>
            </li>
            <li>
              <a href="javascript:void(0);">Dashboard</a>
            </li>
            <li className="tg-active">Edit Trademark</li>
          </ol>
        </div>

        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-dbsectionspace tg-haslayout">
            <div className="row">
              <form className="tg-formtheme tg-formdashboard">
                <fieldset>
                  <div className="tg-postanad">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                      <div className="tg-dashboardbox">
                        <div className="tg-dashboardboxtitle">
                          <h2>IP Detail</h2>
                        </div>
                        <div className="tg-dashboardholder">
                          <div className="form-group">
                            <div>
                              <form onSubmit={this.onSubmit}>
                                <div
                                  className={`form-group ${this.errorClass(
                                    formErrors.title.error
                                  )}`}
                                >
                                  <input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    onChange={this.onChange}
                                    placeholder="Name or title of the mark*"
                                    required
                                    defaultValue={this.state.ipData.title}
                                  />
                                </div>
                                <div
                                  className={`form-group ${this.errorClass(
                                    formErrors.country.error
                                  )}`}
                                >
                                  <select
                                    className="form-control"
                                    name="country"
                                    value={this.state.country}
                                    onChange={this.onChange}
                                    defaultValue={this.state.ipData.country}
                                    required
                                  >
                                    {countriesList.map(data => (
                                      <option value={data.name}>
                                        {data.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div
                                  className={`form-group ${this.errorClass(
                                    formErrors.applicationNumber.error
                                  )}`}
                                >
                                  <input
                                    type="text"
                                    name="applicationNumber"
                                    onChange={this.onChange}
                                    className="form-control"
                                    placeholder="Application Number*( 6-8 digit)"
                                    defaultValue={
                                      this.state.ipData.application_number
                                    }
                                    minLength="6"
                                    maxLength="8"
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <lable>Application Date</lable>
                                  <input
                                    type="date"
                                    name="applicationDate"
                                    onChange={this.onApplicationDateChange}
                                    className="form-control"
                                    placeholder="ApplicationDate"
                                    max={moment().format("YYYY-MM-DD")}
                                    defaultValue={
                                      this.state.ipData.application_date
                                    }
                                    required
                                  />
                                </div>
                                <div
                                  className={`form-group ${this.errorClass(
                                    formErrors.classes.error
                                  )}`}
                                  style={{ position: "relative", zIndex: 100 }}
                                >
                                  <label>Select Class*</label>
                                  <select
                                    className="form-control"
                                    value={this.state.classes}
                                    defaultValue={this.state.ipData.classes}
                                    onChange={this.handleListChange}
                                    required
                                  >
                                    {classesList.map(data => (
                                      <option value={data.class}>
                                        {data.class}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div
                                  className={`form-group ${this.errorClass(
                                    formErrors.status.error
                                  )}`}
                                >
                                  <label>Select Status*</label>
                                  <select
                                    className="form-control"
                                    name="status"
                                    value={this.state.status}
                                    onChange={this.onChange}
                                    defaultValue={this.state.ipData.status}
                                    required
                                  >
                                    {/* <option value="0">Select Status*</option> */}
                                    <option value="Null">Null</option>
                                    <option value="Examination">
                                      Examination
                                    </option>
                                    <option value="Accepted">Accepted</option>
                                    <option value="Published">Published</option>
                                    <option value="Registered">
                                      Registered
                                    </option>
                                    <option value="Opposed">Opposed</option>
                                  </select>
                                </div>
                                <div
                                  className={`form-group ${this.errorClass(
                                    formErrors.type.error
                                  )}`}
                                >
                                  <label>Select Type*</label>
                                  <select
                                    className="form-control"
                                    name="type"
                                    value={this.state.type}
                                    onChange={this.onChange}
                                    defaultValue={this.state.ipData.type}
                                    required
                                  >
                                    {/* <option value="0">Select Type*</option> */}
                                    <option value="WORD">WORD</option>
                                    <option value="DEVICE">DEVICE</option>
                                  </select>
                                </div>

                                {this.state.type == "DEVICE" ? (
                                  <div className="form-group">
                                    {this.state.deviceImageUrl != null ||
                                    this.state.deviceImageUrl != "NA" ? (
                                      <div className="d_image uploaded_image_col">
                                        {!this.state.isDeviceImage ? (
                                          <span
                                            className="delete_image_icon"
                                            onClick={this.removeDeviceImage}
                                          >
                                            <i
                                              class="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        ) : null}

                                        <img src={this.state.deviceImageUrl} />
                                      </div>
                                    ) : null}

                                    <lable>Device Images</lable>

                                    {!this.state.isDeviceImage ? (
                                      ""
                                    ) : (
                                      <input
                                        onChange={this.fileSelectedHandler}
                                        type="file"
                                        style={{
                                          border: "1px solid #d2d2d2",
                                          borderRadius: "5px",
                                          height: "40px",
                                          padding: "8px",
                                          width: "100%"
                                        }}
                                        required={
                                          this.state.type == "DEVICE"
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                  </div>
                                ) : null}

                                <div className="form-group">
                                  <input
                                    type="number"
                                    name="price"
                                    onChange={this.onChange}
                                    className="form-control"
                                    placeholder="Price"
                                    defaultValue={this.state.ipData.price}
                                    min={0}
                                  />
                                </div>
                                <div
                                  className={`form-group ${this.errorClass(
                                    formErrors.agreement.error
                                  )}`}
                                >
                                  <label>Select Agreement Type*</label>
                                  <select
                                    className="form-control"
                                    name="agreement"
                                    value={this.state.agreement}
                                    onChange={this.onChange}
                                    defaultValue={this.state.ipData.agreement}
                                    required
                                  >
                                    {/* <option value="0">
                                      Select Agreement Type*
                                    </option> */}
                                    <option value="Sale">Sale</option>
                                    <option value="License">License</option>
                                    <option value="Both">Both</option>
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label>Add Links</label>
                                  {Link}
                                </div>
                                <div className="form-group">
                                  <textarea
                                    type="text"
                                    name="summary"
                                    onChange={this.onChange}
                                    value={this.state.summary}
                                    defaultValue={this.state.ipData.summary}
                                    className="form-control"
                                    placeholder="Summary*"
                                    maxLength={2000}
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <label>
                                    Attach Images(jpg, jpeg, png only & Max 10
                                    Images)
                                  </label>
                                  <FilePond
                                    acceptedFileTypes={["image/*"]}
                                    allowMultiple={true}
                                    maxFiles={10}
                                    onupdatefiles={fileItems => {
                                      var types = ["jpeg", "jpg", "png"];
                                      this.setState({
                                        imageFile: fileItems.map(fileItem => {
                                          const ext = fileItem.file.name
                                            .split(".")
                                            .pop();
                                          if (types.includes(ext)) {
                                            return fileItem.file;
                                          } else {
                                            this.setState({
                                              imageInvalidType: false
                                            });
                                          }
                                        })
                                      });
                                      this.state.isFileSectionTriggered = true;
                                      this.validateForm();
                                    }}
                                  />
                                  {!formErrors.image.valid && (
                                    <p style={errorStyle}>
                                      {formErrors.image.error}
                                    </p>
                                  )}
                                </div>
                                {/* {this.state.imageFile.map(file => (
                                  <img src={file.name} />
                                ))} */}
                                {this.state.isImageLoaded ? (
                                  this.state.ipData.images != "NA" ? (
                                    <div className="form-group uploaded_image_col">
                                      {this.state.uploadedImage.map(
                                        (url, index) => (
                                          <div class="image_item">
                                            {this.state.ipData.images !=
                                            "NA" ? (
                                              <span
                                                className="delete_image_icon"
                                                onClick={() => {
                                                  this.removeImages(index);
                                                }}
                                              >
                                                <i
                                                  class="fa fa-times-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                            ) : null}
                                            <img
                                              className="uploaded_image"
                                              src={url}
                                            />
                                          </div>
                                        )
                                      )}

                                      {/* <img
                                      className="uploaded_image"
                                      src={this.state.uploadedImage}
                                    /> */}
                                    </div>
                                  ) : null
                                ) : null}

                                <div className="form-group">
                                  <label>Attach Documents(only pdf)</label>
                                  <FilePond
                                    allowMultiple={true}
                                    maxFiles={5}
                                    onupdatefiles={fileItems => {
                                      this.setState({ documentError: "" });
                                      var types = ["pdf"];
                                      this.setState({
                                        document: fileItems.map(fileItem => {
                                          const ext = fileItem.file.name
                                            .split(".")
                                            .pop();
                                          if (types.includes(ext)) {
                                            return fileItem.file;
                                          } else {
                                            this.setState({
                                              documentError:
                                                "Invalid File Type: *Only PDF Allowed"
                                            });
                                          }
                                        })
                                      });
                                      this.state.isFileSectionTriggered = true;
                                      this.validateForm();
                                    }}
                                    FilePondPluginFileValidateType={(
                                      source,
                                      type
                                    ) => {
                                      new Promise((resolve, reject) => {
                                        const ext = source.name
                                          .split(".")
                                          .pop();
                                        if (ext === "pdf") {
                                          resolve(type);
                                        } else {
                                          reject();
                                        }
                                      });
                                    }}
                                  />
                                  {!this.state.documentValid && (
                                    <p style={errorStyle}>
                                      {this.state.documentError}
                                    </p>
                                  )}
                                </div>
                                {this.state.document.map(file => (
                                  <object
                                    width="100%"
                                    height="300"
                                    data={file}
                                  ></object>
                                ))}
                                {this.state.isDocLoaded ? (
                                  this.state.ipData.documents != "NA" ? (
                                    <div className="form-group uploaded_image_col">
                                      {this.state.uploadedDoc.map(
                                        (item, index) => (
                                          <div className="image_item">
                                            <span
                                              className="delete_image_icon"
                                              onClick={() => {
                                                this.removeDocs(index);
                                              }}
                                            >
                                              <i
                                                class="fa fa-times-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <object
                                              width="100%"
                                              height="300"
                                              data={item}
                                            ></object>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : null
                                ) : null}

                                <input
                                  type="submit"
                                  class="btn btn-info btn-block mt-4"
                                  value="submit"
                                  disabled={this.state.isButtonDisabled}
                                />
                                <Loader loaded={!this.state.loaded} />
                                <ToastsContainer store={ToastsStore} />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Trademark;
