import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../images-custom/logo/IP.png";
import Profile from "../public/images/profile.svg";
import "./navbar.css";
import TreeViewMenu from "react-simple-tree-menu";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../store/actions/authActions";
import { withRouter } from "react-router-dom";
import {Auth} from "aws-amplify";
import axios from "axios";
import config from "../environment";

const treeData = {
  "first-level-node-1": {
    label: "Home",
    index: 0,
    url: "/"
  },
  "first-level-node-2": {
    label: "Services",
    index: 0,
    url: "#",
    nodes: {
      "second-level-node-1": {
        label: "IP Protection",
        index: 0,
        url: "/services/ip-protection"
      },
      "second-level-node-2": {
        label: "Trademarks",
        index: 0,
        url: "#",
        nodes: {
          "third-level-node-1": {
            label: "Registration",
            index: 0,
            url: "/services/trademark-registration",
            nodes: {} // you can remove the nodes property or leave it as an empty array
          },
          "third-level-node-2": {
            label: "Renewal",
            index: 0,
            url: "/services/trademark-renewal",
            nodes: {} // you can remove the nodes property or leave it as an empty array
          },

          "third-level-node-3": {
            label: "Assignment",
            index: 0,
            url: "/services/trademark-assignment",
            nodes: {} // you can remove the nodes property or leave it as an empty array
          },

          "third-level-node-4": {
            label: "Objection",
            index: 0,
            url: "/services/trademark-objection",
            nodes: {} // you can remove the nodes property or leave it as an empty array
          },

          "third-level-node-5": {
            label: "Opposition",
            index: 0,
            url: "/services/trademark-opposition",
            nodes: {} // you can remove the nodes property or leave it as an empty array
          }
        }
      }
    }
  },
  "first-level-node-3": {
    label: "About",
    index: 1,
    url: "/about"
  },
  "first-level-node-4": {
    label: "Contact",
    index: 0,
    url: "/contact"
  }
};

class navbar extends Component {

  constructor(props) {
    super(props);
    this.state = { showMenu: false };
    this.state = {
      users : [],
      localUsers : {
        name: localStorage.getItem('name')
      }
      
    };
    this.getProfilePic()
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }
  async getProfilePic(){
    var email = new String(localStorage.getItem("email"))
    let Url =  await axios.get(config.production.api +  `getprofilepic?email=%22${email}%22`);
    localStorage.setItem("profileUrl",Url.data)
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      if(user) {
        this.setState({ users: user.attributes });
      }
    })
    .catch(err => console.log(err));
  }


  hideMenu = () => {
    this.setState({ showMenu: false });
  };

  showMenu = () => {
    this.setState({ showMenu: true });
  };
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
    this.props.history.push("/login");
    window.location.reload();
  }
  render() {
    const { isAuthenticated } = this.props.userAuth;
    const { users } = this.state;
    const { localUsers } = this.state;
  
    const guestNav = (
      <div className="row">
        <div className="dropdown tg-themedropdown tg-userdropdown">
        <div className="dropdown">
        <a href="/login" id="tg-adminnav" className="dropbtn" data-toggle="dropdown">
        <div>
          <span className="tg-userdp" style={{float: 'left', overflow: 'hidden', margin: '5px 10px 5px 0px'}}><img src={Profile} style={{width:'38px', position:'absolute', marginLeft: '-50px', marginTop: '10px'}} alt="image description" /></span>
        </div>
        <span className="tg-name">Welcome</span><br />
        <span >Guest</span>
        </a>
        <div className="dropdown-content">
        <Link to={'/login'} href="#">Login</Link>
        <Link to={'/register'} href="#">Register</Link>
        </div>
        </div>
        </div>
      </div>
    );
    const authNav = (
      <div className="row">
        <div className="row">
          <div className="dropdown tg-themedropdown tg-userdropdown">
            <div className="dropdown xxx" style={{width:'215px'}}>
              <a href="#" id="tg-adminnav" className="dropbtn" data-toggle="dropdown" style={{width:'300'}}>
              <span className="tg-userdp" style={{float: 'left', overflow: 'hidden', margin: '5px 10px 5px 0px', borderRadius: '50%', border: '1px solid #fff'}}> {   localStorage.getItem('profileUrl') !=  "null" ? <img src={localStorage.getItem('profileUrl')} style={{width: '42px', height: '42px'}} alt="image " />:<img src={Profile} style={{width:'38px'}} alt="image description" />}</span>
                <span className="tg-name">Welcome</span><br />
                <span>{ users.length == 0 ? localUsers.name : users.name  }</span>
              </a>
              <div className="dropdown-content">
                <a  href={'/dashboard'}>Profile</a>
                <Link onClick={this.onLogoutClick.bind(this)}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <header id="tg-header" className="tg-header tg-haslayout">
        <div className="tg-topbar">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="dropdown tg-themedropdown tg-userdropdown">
                  <div className="dropdown">
                    {isAuthenticated ? authNav : guestNav}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tg-navigationarea bg-white">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <strong className="tg-logo">
                  <a href="/">
                    <img
                      src={logo}
                      width="150px"
                      alt="TRADEMYIP - 'Exchange your creativity'"
                    />
                  </a>
                </strong>
                <a className="tg-btn bg-primary" href="/dashboard/post">
                  <i className="icon-bookmark" />
                  <Link to={"/dashboard/post"}>
                    <span className="text-white">List your IP</span>
                  </Link>
                </a>
                <div className="hide-me">
                  {this.state.showMenu ? (
                    <span onClick={this.hideMenu} style={{ fontSize: "2.5em" }}>
                      <i className="fa fa-times" />
                    </span>
                  ) : (
                    <span onClick={this.showMenu}>
                      <i className="fa fa-bars" style={{ fontSize: "2.5em" }} />
                    </span>
                  )}
                </div>
                {this.state.showMenu ? (
                  <div className="menu-mob">
                    <TreeViewMenu
                      data={treeData}
                      onClickItem={({ key, label, ...props }) =>
                        (document.location = props.url)
                      }
                    />
                  </div>
                ) : null}
                <nav id="tg-nav" className="tg-nav">
                  <div
                    id="tg-navigation"
                    className="collapse navbar-collapse tg-navigation"
                  >
                    <ul>
                      <li className="menu-item">
                        <Link to={"/"}>Home</Link>
                      </li>
                      <li className="menu-item-has-children current-menu-item">
                        <span className="tg-dropdowarrow">
                          <i className="fa fa-angle-down" />
                        </span>
                        <a href="javascript:void(0);">Services</a>
                        <ul className="sub-menu">
                          <li>
                            <Link to={"/services/ip-protection"}>
                              IP Protection
                            </Link>
                          </li>
                          <li className="menu-item-has-children">
                            <span className="tg-dropdowarrow">
                              <i className="fa fa-angle-down" />
                            </span>
                            <a href="javascript:void(0);">TradeMarks</a>
                            <ul className="sub-menu">
                              <li>
                                <Link to={"/services/trademark-registration"}>
                                  Registration
                                </Link>
                              </li>
                              <li>
                                <Link to={"/services/trademark-renewal"}>
                                  Renewal
                                </Link>
                              </li>
                              <li>
                                <Link to={"/services/trademark-assignment"}>
                                  Assignment
                                </Link>
                              </li>
                              <li>
                                <Link to={"/services/trademark-objection"}>
                                  Objection
                                </Link>
                              </li>
                              <li>
                                <Link to={"/services/trademark-opposition"}>
                                  Opposition
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to={"/services/patent-services"}>
                              Patents
                            </Link>
                          </li>
                          <li>
                            <Link to={"/services/copyright-services"}>
                              Copyright
                            </Link>
                          </li>
                          <li>
                            <Link to={"/services/design-services"}>Design</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="ui-menu-item">
                        <span className="tg-dropdowarrow">
                          <i className="fa fa-angle-down" />
                        </span>
                        <Link to={"/about"}>About</Link>
                      </li>
                      <li className="ui-menu-item">
                        <Link to={"/contact"}>Contact</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  userAuth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userAuth: state.userAuth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(navbar));
