import React, { Component } from "react";
import Home from "./home/index";
import Login from "./auth/login";
import Register from "./auth/register";
import Forgot from "./auth/forgot";
import Reset from "./auth/PasswordReset";

import DesignIP from "./ip/designIP";
import PatentsIP from "./ip/patentsIP";
import CopyrightsIP from "./ip/copyrightsIP";
import TrademarksIP from "./ip/trademarksIP";
import About from "./pages/about";
import IpProtection from "./pages/ipProtection";
import TrademarkRenewal from "./pages/Services/trademarkRenewal";
import TrademarkRegistration from "./pages/Services/trademarkRegistration";
import TrademarkAssignment from "./pages/Services/trademarkAssignment";
import TrademarksOpposition from "./pages/Services/trademarksOpposition";
import Contact from "./pages/contact";
import DesignServices from "./pages/Services/designServices";
import PatentServices from "./pages/Services/patentServices";
import CopyrightServices from "./pages/Services/copyrightServices";
import TrademarksObjection from "./pages/Services/trademarksObjection";
import PostAd from "./user/postAd";
import Offers from "./user/offers";
import Favorites from "./user/favorite";
import { loadReCaptcha } from "react-recaptcha-google";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Dashboard from "./user/dashboard";
import EditProfile from "./user/editProfile";
import AllAds from "./user/myAds";
import PostIP from "./user/postIp";
import { setCurrentUser, logoutUser } from "./store/actions/authActions";
import PrivateRoute from "./common/PrivateRoutes";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store.js";
import Admin from "./admin/index";
import AdminIpView from "./admin/view";
import PendingIP from "./admin/pendingIPs";
import Approved from "./admin/approvedIPs";
import Rejected from "./admin/rejectedIPs";
import { ToastsContainer, ToastsStore } from "react-toasts";
import EditTrademark from "./user/editForms/trademark";
import EditCopyright from "./user/editForms/copyright";
import EditDesign from "./user/editForms/design";
import EditPatent from "./user/editForms/patent";
import offerRecieved from "./user/offerRecieved";

if (localStorage.token) {
  const token = localStorage.token;
  store.dispatch(setCurrentUser(token));
  const currentTime = Date.now() / 1000;
  if (token.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/login";
  }
}
const timeDiff = new Date().getTime() - Number(localStorage.getItem("time"));
if (localStorage.getItem("token") && timeDiff > 3600000) {
  setTimeout(function () {
    //ToastsStore.error("Session Timeout");
    alert("Session Timeout");
    localStorage.clear();
    window.location.reload();
  }, 5000);
}

class App extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
    loadReCaptcha();
    this.callthis();
  }

  callthis = () => {
    ToastsStore.success("Session Timeout");
  };

  render() {
    return (
      <Provider store={store}>
        <div>
          <ToastsContainer store={ToastsStore} />
        </div>
        <Router>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
       
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/forgot-password" component={Forgot} />
          <Route exact path="/password/reset" component={Reset} />
          
          <Route
            exact
            path="/services/ip-protection"
            component={IpProtection}
          />
          <Route
            exact
            path="/services/trademark-renewal"
            component={TrademarkRenewal}
          />
          <Route
            exact
            path="/services/trademark-registration"
            component={TrademarkRegistration}
          />
          <Route
            exact
            path="/services/design-services"
            component={DesignServices}
          />
          <Route
            exact
            path="/services/patent-services"
            component={PatentServices}
          />
          <Route
            exact
            path="/services/copyright-services"
            component={CopyrightServices}
          />
          <Route
            exact
            path="/services/trademark-assignment"
            component={TrademarkAssignment}
          />
          <Route
            exact
            path="/services/trademark-objection"
            component={TrademarksObjection}
          />
          <Route
            exact
            path="/services/trademark-opposition"
            component={TrademarksOpposition}
          />
          <Route exact path="/designs/ip/:id" component={DesignIP} />
          <Route exact path="/patents/ip/:id" component={PatentsIP} />
          <Route exact path="/copyrights/ip/:id" component={CopyrightsIP} />
          <Route exact path="/trademarks/ip/:id" component={TrademarksIP} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />

          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
          </Switch>

          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/profile/edit"
              component={EditProfile}
            />
          </Switch>

          <Switch>
            <PrivateRoute exact path="/dashboard/all" component={AllAds} />
          </Switch>

          <Switch>
            <PrivateRoute exact path="/dashboard/post" component={PostIP} />
          </Switch>

          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/post/edit/trademark/:cat/:id"
              component={EditTrademark}
            />
            <PrivateRoute
              exact
              path="/dashboard/post/edit/copyright/:cat/:id"
              component={EditCopyright}
            />
            <PrivateRoute
              exact
              path="/dashboard/post/edit/design/:cat/:id"
              component={EditDesign}
            />
            <PrivateRoute
              exact
              path="/dashboard/post/edit/patent/:cat/:id"
              component={EditPatent}
            />
          </Switch>

          <Switch>
            <Route exact path="/dashboard/offers" component={Offers} />
          </Switch>
          <Switch>
            <Route
              exact
              path="/dashboard/offerRecieved"
              component={offerRecieved}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/favorites"
              component={Favorites}
            />
          </Switch>


          <Switch>
            <PrivateRoute exact path="/dashboard/admin" component={Admin} />
          </Switch>

          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/admin/pending"
              component={PendingIP}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/admin/approved"
              component={Approved}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/admin/rejected"
              component={Rejected}
            />
          </Switch>

          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/admin/ip/:type/:id"
              component={AdminIpView}
            />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
