import React, { Component } from 'react';
import Navbar from '../includes/navbar';
import Footer from '../includes/footer';
import QuickContact from "./Services/quickContact";

import Trademarks from '../images-custom/ip-logos/trademark.jpg';
import Patents from '../images-custom/ip-logos/patent.jpg';
import Copyright from '../images-custom/ip-logos/copyright.jpg';
import Design from '../images-custom/ip-logos/design.jpg';
import GI from '../images-custom/ip-logos/GI.jpg';
import TradeSecret from '../images-custom/ip-logos/trade_secret.jpg';

class ipProtection extends Component {

    render() {

        return (
            <div>
                <Navbar />
                <div className="container">

                <section className="tg-sectionspace tg-haslayout">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-10">

                                    <h2>IP PROTECTION SERVICES</h2>
                                    <hr className="hr-custom" />

                                    <p>Anytime you create something like a product, artistic work, images, literature, idea, etc. you create intellectual property. It is important to protect it, not only to gain exclusivity but for economics and for its further growth in areas such as research, innovation and employment. IP protection also helps from unfair competition and misuse and allows customers to identify safe and guaranteed products.</p>

                                    <p>As an IP owner, you need to be aware of the intellectual property laws to protect your IP or at least seek proper guidance and help, to protect your IP. IP protection is needed not only in your home country but also internationally.</p>

                                    <p>IP can be of different types and it’s important to understand what type of IP protection you need.</p>

                                    <h3>TRADE MARKS</h3>
                                    <hr className="hr-custom" />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <img src={Trademarks} style={{marginTop:'10px'}} />
                                        </div>

                                        <div className="col-sm-9">
                                            <p>A trade mark is a unique symbol, logo or a word that is used to represent a business for trading goods and/or services and to distinguish them from goods and/or services of other businesses. </p>
                                            <p>Trade mark will give you an exclusive right to do business, trading goods and/or services using that mark thus preventing others to use the same mark thus avoiding any confusion for the customers. Trade mark also helps you to protect the reputation of your business. </p>
                                            <p>Trade marks are registered for a period of 10 years in India which is subject to renewal for another 10 years and so on. </p>

                                        </div>
                                    </div>

                                    <h3>PATENTS</h3>
                                    <hr className="hr-custom" />

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <img src={Patents} style={{marginTop:'10px'}} />
                                        </div>
                                        <div className="col-sm-9">
                                        <p>A patent is a protection granted by the governing authority for an invention. This is given to the inventor to stop others from making, using, selling the invention to others. This is a business asset that can be sold or licensed for example, in case of manufacturing of new products.</p>
                                            <p>To patent an invention, it should have 3 things:</p>
                                            <ul>
                                                <li><strong>New</strong> – It should not be known in public domain</li>
                                                <li><strong>Inventive step</strong> – It should be non-obvious to experts with knowledge in that subject area.</li>
                                                <li><strong>Industrial Application</strong> – It should be capable of being applied in the industry. i.e there should be some practical application of the invention.</li>
                                            </ul>

                                        </div>
                                    </div>

                                    <p>Getting a patent can take years as it is a costly affair and it is important to file the patent as early as possible to get the protection for the invention.</p>
                                    <p>Patents are granted for a period of 20 years and needs to be renewed every year by filing a working statement.</p>

                                    <h3 className="upper">Copyrights</h3>
                                        <hr className="hr-custom" />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <img src={Copyright} style={{marginTop:'10px'}} />
                                            </div>
                                            <div className="col-sm-9" style={{textAlign:'justify'}}>
                                                <p>A copyright is a person’s exclusive right to reproduce, publish or sell his original work of authorship such as literary, musical, dramatic, software or artistic work. The owner of copyright can reproduce, create derivative works, distribute copies of the work, display and perform the work publicly.</p>
                                                <p>Generally a copyright is for the period of 60 years but the time period can vary depending on the type of work.</p>
                                            </div>
                                        </div>

                                        <h3 className="upper">Designs</h3>
                                        <hr className="hr-custom" />

                                        <div className="row">
                                            <div className="col-sm-3">
                                                <img src={Design} style={{marginTop:'10px'}} />
                                            </div>
                                            <div className="col-sm-9" style={{textAlign:'justify'}}>
                                                <p>A design, generally referred to as industrial design is a right that protects the visual design of objects that are not purely utilitarian. It allows the owner to use it exclusively on goods for purposes of doing business/trade.</p>
                                                <p>Industrial design covers shape, configuration, pattern, ornament or composition of lines and color. It has to be new and original and can be applied to any article by any industrial process or means, chemical or manual.</p>
                                                <p>An industrial design is registered for a period of 10 years which can be extended by 5 more years.</p>
                                            </div>

                                        </div>

                                        <h3 className="upper">Trade Secrets</h3>
                                        <hr className="hr-custom" />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <img src={TradeSecret} style={{marginTop:'10px'}} />
                                            </div>
                                            
                                            <div className="col-sm-9" style={{textAlign:'justify'}}>
                                                <p>A trade secret generally can be a formula, algorithm, programs, compositions, compilation of data, recipe, practice, process, design, instrument or any information related to business that is not publicly known. It generally refers to confidential information that is required for running of a business. </p>
                                                <p>A trade secret can be defined as:</p>
                                                <ul style={{paddingLeft:'30px'}}>
                                                    <li style={{listStyle:'disc'}}>a secret not generally known or readily accessible;</li>
                                                    <li style={{listStyle:'disc'}}>has commercial value by virtue of secrecy; and </li>
                                                    <li style={{listStyle:'disc'}}>has been subjected to reasonable steps for ensuring its secrecy.</li>
                                                </ul>
                                            </div>

                                        </div>
                                        <p>This kind of information generally provides an edge over the competition. In India there are no separate laws for trade secret and its covered under various statutes. </p>


                                        <h3 className="upper">Geographical Indications</h3>
                                        <hr className="hr-custom" />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <img src={GI} style={{marginTop:'10px'}} />
                                            </div>
                                            <div className="col-sm-9" style={{textAlign:'justify'}}>
                                                <p>A geographical indication is a sign used on products that have a specific geographical origin and possess qualities or a reputation that are due to that origin. Typically, such a name conveys an assurance of quality and distinctiveness which is essentially attributable to the fact of its origin in that defined geographical locality, region or country. </p>
                                                <p>GIs are covered under the Geographical Indications of Goods (Registration and Protection) Act 1999. GIs have been used in India for a wide variety of products, such as Basmati Rice, Darjeeling Tea, Kangra Tea, Feni, Alphonso Mango, Alleppey Green Cardamom, Coorg Cardamom, Kanchipuram Silk Saree, Kohlapuri Chappal, etc.</p>
                                            </div>
                                        </div>
                                        <p>By registering a GI in India, the IP owner can prevent unauthorized use of the registered GI by others by initiating infringement action by way of a civil suit or criminal complaint. GIs can be registered for a period of 10 years and can be renewed from time to time for a period of 10 years at a time.</p>

                                    </div>
                                </div>
                            </div>
                            <QuickContact/>

                        </section>


                </div>

                <Footer />
            </div>
        );
    }
}

export default ipProtection;
