import React, { Component } from 'react';
import Navbar from "../includes/navbar";
import Footer from '../includes/footer';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Link } from "react-router-dom";
import { ReCaptcha } from 'react-recaptcha-google'
import {Auth} from "aws-amplify";





class PasswordReset extends Component {


    state = {
        email:"",
        confirmpassword: "",
        password:"",
        code:"",
        errors: {
            cognito: null,
        }
    }

    handleSubmit = async event => {
        event.preventDefault();
        // @geroge put form validations here
        // AWS Cognito integration here
        const {email,confirmpassword,password,code} = this.state;
        try{
            if(password != confirmpassword){
                throw "password doesnt match"
            }
            await Auth.forgotPasswordSubmit(
                   email,code,confirmpassword
               );
            this.props.history.push('/')
        }
        catch(error){
            console.log(error)
        }

        
    };



    onInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
    }





    render() {
        return (
            <div>
                <Navbar />

                <div className="container">
                    <div className="col-md-4 "></div>
                    <div className="col-md-4" style={{background:'#f1f1f1', boxShadow:'0 0 0 -1px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12)', marginTop:'40px', marginBottom:'40px'}}>
                        <div className="tg-title">
                            <h2 style={{marginTop:'15px'}}>Change Password</h2>
                        </div>

                        <form className="tg-formtheme tg-formregister" onSubmit={this.handleSubmit}>
                            <fieldset>

                                <div className="form-group tg-inputwithicon">
                                    <i className="icon-lock"></i>
                                    <input type="text" id="email" name="email" className="form-control" placeholder="Enter Email" value={this.state.email} onChange={this.onInputChange} required={true} />
                                </div>
                                <div className="form-group tg-inputwithicon">
                                    <i className="icon-lock"></i>
                                    <input type="number" id="code" name="code" className="form-control" placeholder="Enter Code" value={this.state.code} onChange={this.onInputChange} required={true} />
                                </div>

                                <div className="form-group tg-inputwithicon">
                                    <i className="icon-lock"></i>
                                    <input type="password" id="password" name="password" className="form-control" placeholder="New Password*" value={this.state.password} onChange={this.onInputChange} required={true} />
                                </div>
                                <div className="form-group tg-inputwithicon">
                                    <i className="icon-lock"></i>
                                    <input type="password" id="confirmpassword" name="confirmpassword" className="form-control" placeholder="Retype New Password*" value={this.state.confirmpassword} onChange={this.onInputChange} required={true} />
                                </div>

                                <div className="form-group" style={{    marginBottom: '15px'}}><ReCaptcha ref={(el) => {this.captchaDemo = el;}}  size="normal" render="explicit" sitekey="6LfEY6IUAAAAAGzF9TMjMRCrp-a6q9WMxZ5pxg7J" onloadCallback={this.onLoadRecaptcha} verifyCallback={this.verifyCallback} /></div>


                                <div className="form-group tg-inputwithicon">
                                    <input type="submit" style={{background:'#00cc67', color:'white', paddingLeft: '0px'}} name="confirmpassword" className="form-control" value={'Change Password'} />
                                </div>

                            </fieldset>
                        </form>

                        <div>
                            <ToastsContainer store={ToastsStore}/>
                        </div>

                    </div>
                    <div className="col-md-4"></div>
                </div>

                <Footer />
            </div>
        );
    }

}

export default PasswordReset;
