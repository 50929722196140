import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import trademark from "../public/images/ip-logos/trademark.jpg";
import Copyright from "../public/images/ip-logos/copyright.jpg";
import Design from "../public/images/ip-logos/design.jpg";
import patents from "../public/images/ip-logos/patent.jpg";
import titleCase from "title-case";
import Trademarks from "../images-custom/ip-logos/trademark.jpg";
import config from "../environment.js";
import Patents from "../images-custom/ip-logos/patent.jpg";
import Skeleton from "react-loading-skeleton";
import { ToastsContainer, ToastsStore } from "react-toasts";
import CircularProgress from "@material-ui/core/CircularProgress";

//import FavNotification from "../common/favNotification"
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ip_patents: [],
      ip_trademarks: [],
      ip_copyrights: [],
      ip_designs: [],
      designsImages: [],
      trademarksImages: [],
      copyrightsImages: [],
      patentsImages: [],
      patentslimit: 8,
      trademarkslimit: 8,
      copyrightslimit: 8,
      designslimit: 8,
      showPatents: true,
      showtrademarks: true,
      showcopyrights: true,
      showdesigns: true,
      latestTrademark: [],
      latestPatent: [],
      latestDesign: [],
      latestCopyright: [],
      favoritesList: [],
      favoritesListID: [],
      viewsList: [],
      isFavorite: false,
      favNotification: false,
      loading: true,
    };
    this.showMore = this.showMore.bind(this);
    this._addfavourite = this._addfavourite.bind(this);
    //this.onlyFavoritesListID=this.onlyFavoritesListID.bind(this)
  }

  sortTitle(str) {
    if (str !== null && str !== undefined && str.length > 10)
      str = str.substring(0, 33);
    return str;
  }
  sortSubTitle(str) {
    if (str !== null && str !== undefined && str.length > 10)
      str = str.substring(0, 20);
    return str;
  }

  onlyFavoritesListID = () => {};

  componentDidMount() {
    this.getAllCategories();
    this.latestTrademark();
    this.getAllPatent();
    this.latestPatents();
    this.getAllDesign();
    this.latestDesign();
    this.latestCopyright();
    this.getAllCopyright();
  }
  getAllCategories = () => {
    axios
      .get(config.production.api + `fetchbycategory?category=%22trademark%22`)
      .then((response) => {
        if (response.data) {
          this.setState({
            loading: false,
          });
          if (response.data.body.length) {
            const data = response.data.body;
            data.forEach((e) => {
              //console.log(e,"EE");
              e.isFvt = false;
              e.noOfView = 0;
            });
            //this.setState({ip_trademarks: response.data.body})
            this.setState({ trademarksImages: response.data.body.images });
            if (localStorage.getItem("email")) {
              const email = {
                email: localStorage.getItem("email"),
              };
              axios
                .post(config.production.api + `favorites`, email)
                .then((response) => {
                  this.setState({ favoritesList: response.data.body });

                  for (let i = 0; i < this.state.favoritesList.length; i++) {
                    for (let k = 0; k < data.length; k++) {
                      if (this.state.favoritesList[i].id == data[k].id) {
                        data[k].isFvt = true;
                        break;
                      }
                    }
                  }

                  this.setState({ ip_trademarks: data });
                });
            } else {
              this.setState({ ip_trademarks: response.data.body });
            }

            axios
              .get(config.production.api + `no-of-views`)
              .then((response) => {
                console.log("response ", response);
                this.setState({ viewsList: response.data });

                for (let i = 0; i < this.state.viewsList.length; i++) {
                  for (let k = 0; k < data.length; k++) {
                    let keys = Object.keys(this.state.viewsList[i]);
                    if (keys == data[k].id) {
                      data[k].noOfView = this.state.viewsList[i][keys];
                      break;
                    }
                  }
                }

                this.setState({ ip_trademarks: data });
              });
            // console.log(this.state.ip_trademarks, "RRJJ")
          }
        }
      });
  };
  getAllDesign = () => {
    axios
      .get(config.production.api + `fetchbycategory?category=%22design%22`)
      .then((response) => {
        if (response.data) {
          if (response.data.body.length) {
            const data = response.data.body;
            data.forEach((e) => {
              //console.log(e,"EE");
              e.isFvt = false;
              e.noOfView = 0;
            });
            //this.setState({ip_trademarks: response.data.body})

            if (localStorage.getItem("email")) {
              const email = {
                email: localStorage.getItem("email"),
              };
              axios
                .post(config.production.api + `favorites`, email)
                .then((response) => {
                  this.setState({ favoritesList: response.data.body });

                  for (let i = 0; i < this.state.favoritesList.length; i++) {
                    for (let k = 0; k < data.length; k++) {
                      if (this.state.favoritesList[i].id == data[k].id) {
                        data[k].isFvt = true;
                        break;
                      }
                    }

                    // this.state.favoritesListID.push(this.state.favoritesList[i].id)
                  }

                  this.setState({ ip_designs: data });
                });
            } else {
              this.setState({ ip_designs: response.data.body });
            }

            axios
              .get(config.production.api + `no-of-views`)
              .then((response) => {
                console.log("response ", response);
                this.setState({ viewsList: response.data });

                for (let i = 0; i < this.state.viewsList.length; i++) {
                  for (let k = 0; k < data.length; k++) {
                    let keys = Object.keys(this.state.viewsList[i]);
                    if (keys == data[k].id) {
                      data[k].noOfView = this.state.viewsList[i][keys];
                      break;
                    }
                  }
                }

                this.setState({ ip_designs: data });
              });

            //   this.setState({ip_patents: response.data.body})
          }
        }
      });
  };
  getAllPatent = () => {
    axios
      .get(config.production.api + `fetchbycategory?category=%22patent%22`)
      .then((response) => {
        if (response.data) {
          if (response.data.body.length) {
            const data = response.data.body;
            data.forEach((e) => {
              //console.log(e,"EE");
              e.isFvt = false;
              e.noOfView = 0;
            });
            //this.setState({ip_trademarks: response.data.body})

            if (localStorage.getItem("email")) {
              const email = {
                email: localStorage.getItem("email"),
              };
              axios
                .post(config.production.api + `favorites`, email)
                .then((response) => {
                  this.setState({ favoritesList: response.data.body });

                  for (let i = 0; i < this.state.favoritesList.length; i++) {
                    for (let k = 0; k < data.length; k++) {
                      if (this.state.favoritesList[i].id == data[k].id) {
                        data[k].isFvt = true;
                        break;
                      }
                    }
                  }
                  this.setState({ ip_patents: data });
                });
            } else {
              this.setState({ ip_patents: response.data.body });
            }

            axios
              .get(config.production.api + `no-of-views`)
              .then((response) => {
                console.log("response ", response);
                this.setState({ viewsList: response.data });

                for (let i = 0; i < this.state.viewsList.length; i++) {
                  for (let k = 0; k < data.length; k++) {
                    let keys = Object.keys(this.state.viewsList[i]);
                    if (keys == data[k].id) {
                      data[k].noOfView = this.state.viewsList[i][keys];
                      break;
                    }
                  }
                }

                this.setState({ ip_patents: data });
              });
            //   this.setState({ip_patents: response.data.body})
          }
        }
      });
  };
  getAllCopyright = () => {
    axios
      .get(config.production.api + `fetchbycategory?category=%22copyright%22`)
      .then((response) => {
        if (response.data) {
          if (response.data.body.length) {
            const data = response.data.body;
            data.forEach((e) => {
              //console.log(e,"EE");
              e.isFvt = false;
              e.noOfView = 0;
            });
            //this.setState({ip_trademarks: response.data.body})

            if (localStorage.getItem("email")) {
              const email = {
                email: localStorage.getItem("email"),
              };
              axios
                .post(config.production.api + `favorites`, email)
                .then((response) => {
                  this.setState({ favoritesList: response.data.body });

                  for (let i = 0; i < this.state.favoritesList.length; i++) {
                    for (let k = 0; k < data.length; k++) {
                      if (this.state.favoritesList[i].id == data[k].id) {
                        data[k].isFvt = true;
                        break;
                      }
                    }

                    // this.state.favoritesListID.push(this.state.favoritesList[i].id)
                  }

                  this.setState({ ip_copyrights: data });
                });
            } else {
              this.setState({ ip_copyrights: response.data.body });
            }

            axios
              .get(config.production.api + `no-of-views`)
              .then((response) => {
                console.log("response ", response);
                this.setState({ viewsList: response.data });

                for (let i = 0; i < this.state.viewsList.length; i++) {
                  for (let k = 0; k < data.length; k++) {
                    let keys = Object.keys(this.state.viewsList[i]);
                    if (keys == data[k].id) {
                      data[k].noOfView = this.state.viewsList[i][keys];
                      break;
                    }
                  }
                }

                this.setState({ ip_copyrights: data });
              });
            //   this.setState({ip_patents: response.data.body})
          }
        }
      });
  };

  // getAllNoOfViews=()=>{
  //     axios.get(config.production.api+`stg/no-of-views`)
  //     .then(response => {
  //         if(response.data) {
  //           if(response.data.body.length){
  //             const data=response.data.body;
  //             data.forEach(e => {
  //                 //console.log(e,"EE");
  //                 e.isFvt=false;
  //             });
  //             //this.setState({ip_trademarks: response.data.body})

  //             if(localStorage.getItem('email')){

  //                 const email = {
  //                     email : localStorage.getItem('email')
  //                 }
  //                 axios.post(config.production.api+`favorites`, email)
  //                 .then(response => {
  //                     this.setState({favoritesList : response.data.body})

  //                     for(let i = 0; i < this.state.favoritesList.length; i++){
  //                        for(let k=0; k< data.length; k++){
  //                            if(this.state.favoritesList[i].id == data[k].id){
  //                                 data[k].isFvt=true;
  //                                 break;
  //                            }
  //                        }

  //                         // this.state.favoritesListID.push(this.state.favoritesList[i].id)
  //                     }

  //                     this.setState({ip_copyrights: data})

  //                 })
  //             }
  //             else{
  //                 this.setState({ip_copyrights: response.data.body})
  //             }
  //             //   this.setState({ip_patents: response.data.body})
  //           }
  //       }
  //   })
  // }

  _delFavourite = (_id) => {
    const data = {
      ip_Id: _id,
      email: localStorage.getItem("email"),
      remove_Fav: "true",
    };

    axios.post(config.production.api + `favorites`, data).then((response) => {
      this.getAllCategories();
      this.latestTrademark();
      this.latestPatents();
      this.getAllPatent();
      this.getAllCopyright();
      this.latestCopyright();
      this.latestDesign();
      this.getAllDesign();
      ToastsStore.info(response.data.body);
      // setTimeout(function(){ window.location.reload(); }, 2000);
    });
  };

  // componentWillMount(){
  //     for(let i = 0; i < this.state.favoritesList.length; i++){
  //         console.log(this.state.favoritesList[i].id,"XXX");
  //     }
  // }

  // Latest IPs function
  latestTrademark = () => {
    const header = {
      "Content-Type": "Application/JSON",
    };
    axios
      .get(
        config.production.api +
          `latest_IPs?keyword="na"&category="trademark"&subcategory="na"`,
        { headers: header }
      )
      .then((response) => {
        const data = response.data.body;
        data.forEach((e) => {
          //console.log(e,"EE");
          e.isFvt = false;
          e.noOfView = 0;
        });

        if (localStorage.getItem("email")) {
          const email = {
            email: localStorage.getItem("email"),
          };
          axios
            .post(config.production.api + `favorites`, email)
            .then((response) => {
              this.setState({ favoritesList: response.data.body });

              for (let i = 0; i < this.state.favoritesList.length; i++) {
                for (let k = 0; k < data.length; k++) {
                  if (this.state.favoritesList[i].id == data[k].id) {
                    data[k].isFvt = true;
                    break;
                  }
                }

                // this.state.favoritesListID.push(this.state.favoritesList[i].id)
              }

              this.setState({ latestTrademark: data });
            });
        } else {
          this.setState({ latestTrademark: response.data.body });
        }

        axios.get(config.production.api + `no-of-views`).then((response) => {
          console.log("response ", response);
          this.setState({ viewsList: response.data });

          for (let i = 0; i < this.state.viewsList.length; i++) {
            for (let k = 0; k < data.length; k++) {
              let keys = Object.keys(this.state.viewsList[i]);
              if (keys == data[k].id) {
                data[k].noOfView = this.state.viewsList[i][keys];
                break;
              }
            }
          }

          this.setState({ latestTrademark: data });
        });
        // this.setState({latestTrademark : response.data.body})
        // console.log(this.state.latestTrademark,"VIPIN")
      });
  };
  // Latest Patent IPs function
  latestPatents = () => {
    const header = {
      "Content-Type": "Application/JSON",
    };
    axios
      .get(
        config.production.api +
          `latest_IPs?keyword="na"&category="patent"&subcategory="na"`,
        { headers: header }
      )
      .then((response) => {
        const data = response.data.body;
        data.forEach((e) => {
          //console.log(e,"EE");
          e.isFvt = false;
          e.noOfView = 0;
        });

        if (localStorage.getItem("email")) {
          const email = {
            email: localStorage.getItem("email"),
          };
          axios
            .post(config.production.api + `favorites`, email)
            .then((response) => {
              this.setState({ favoritesList: response.data.body });

              for (let i = 0; i < this.state.favoritesList.length; i++) {
                for (let k = 0; k < data.length; k++) {
                  if (this.state.favoritesList[i].id == data[k].id) {
                    data[k].isFvt = true;
                    break;
                  }
                }

                // this.state.favoritesListID.push(this.state.favoritesList[i].id)
              }
              this.setState({ latestPatent: data });
            });
        } else {
          this.setState({ latestPatent: response.data.body });
        }

        axios.get(config.production.api + `no-of-views`).then((response) => {
          console.log("response ", response);
          this.setState({ viewsList: response.data });

          for (let i = 0; i < this.state.viewsList.length; i++) {
            for (let k = 0; k < data.length; k++) {
              let keys = Object.keys(this.state.viewsList[i]);
              if (keys == data[k].id) {
                data[k].noOfView = this.state.viewsList[i][keys];
                break;
              }
            }
          }

          this.setState({ latestPatent: data });
        });
        // this.setState({latestPatent : response.data.body})
      });
  };

  latestCopyright = () => {
    const header = {
      "Content-Type": "Application/JSON",
    };
    axios
      .get(
        config.production.api +
          `latest_IPs?keyword="na"&category="copyright"&subcategory="na"`,
        { headers: header }
      )
      .then((response) => {
        const data = response.data.body;
        data.forEach((e) => {
          //console.log(e,"EE");
          e.isFvt = false;
          e.noOfView = 0;
        });

        if (localStorage.getItem("email")) {
          const email = {
            email: localStorage.getItem("email"),
          };
          axios
            .post(config.production.api + `favorites`, email)
            .then((response) => {
              this.setState({ favoritesList: response.data.body });

              for (let i = 0; i < this.state.favoritesList.length; i++) {
                for (let k = 0; k < data.length; k++) {
                  if (this.state.favoritesList[i].id == data[k].id) {
                    data[k].isFvt = true;
                    break;
                  }
                }

                // this.state.favoritesListID.push(this.state.favoritesList[i].id)
              }

              this.setState({ latestCopyright: data });
            });
        } else {
          this.setState({ latestCopyright: response.data.body });
        }

        axios.get(config.production.api + `no-of-views`).then((response) => {
          console.log("response ", response);
          this.setState({ viewsList: response.data });

          for (let i = 0; i < this.state.viewsList.length; i++) {
            for (let k = 0; k < data.length; k++) {
              let keys = Object.keys(this.state.viewsList[i]);
              if (keys == data[k].id) {
                data[k].noOfView = this.state.viewsList[i][keys];
                break;
              }
            }
          }

          this.setState({ latestCopyright: data });
        });
        // this.setState({latestPatent : response.data.body})
      });
  };
  latestDesign = () => {
    const header = {
      "Content-Type": "Application/JSON",
    };
    axios
      .get(
        config.production.api +
          `latest_IPs?keyword="na"&category="design"&subcategory="na"`,
        { headers: header }
      )
      .then((response) => {
        const data = response.data.body;
        data.forEach((e) => {
          //console.log(e,"EE");
          e.isFvt = false;
          e.noOfView = 0;
        });

        if (localStorage.getItem("email")) {
          const email = {
            email: localStorage.getItem("email"),
          };
          axios
            .post(config.production.api + `favorites`, email)
            .then((response) => {
              this.setState({ favoritesList: response.data.body });

              for (let i = 0; i < this.state.favoritesList.length; i++) {
                for (let k = 0; k < data.length; k++) {
                  if (this.state.favoritesList[i].id == data[k].id) {
                    data[k].isFvt = true;
                    break;
                  }
                }
              }

              this.setState({ latestDesign: data });
            });
        } else {
          this.setState({ latestDesign: response.data.body });
        }

        axios.get(config.production.api + `no-of-views`).then((response) => {
          console.log("response ", response);
          this.setState({ viewsList: response.data });

          for (let i = 0; i < this.state.viewsList.length; i++) {
            for (let k = 0; k < data.length; k++) {
              let keys = Object.keys(this.state.viewsList[i]);
              if (keys == data[k].id) {
                data[k].noOfView = this.state.viewsList[i][keys];
                break;
              }
            }
          }

          this.setState({ latestDesign: data });
        });
        // this.setState({latestPatent : response.data.body})
      });
  };
  async showMore(cat) {
    if (cat === "PATENTS") {
      await this.setState({ patentslimit: this.state.patentslimit + 8 });
      if (this.state.patentslimit >= this.state.ip_patents.length) {
        this.setState({ showPatents: false });
      }
    }
    if (cat === "TRADEMARKS") {
      await this.setState({ trademarkslimit: this.state.trademarkslimit + 8 });
      if (this.state.trademarkslimit >= this.state.ip_trademarks.length) {
        this.setState({ showtrademarks: false });
      }
    }
    if (cat === "DESIGNS") {
      await this.setState({ designslimit: this.state.designslimit + 8 });
      if (this.state.designslimit >= this.state.ip_designs.length) {
        this.setState({ showdesigns: false });
      }
    }
    if (cat === "COPYRIGHTS") {
      await this.setState({ copyrightslimit: this.state.copyrightslimit + 8 });
      if (this.state.copyrightslimit >= this.state.ip_copyrights.length) {
        this.setState({ showcopyrights: false });
      }
    }
  }

  renderButton(cat) {
    // show button only if state.showMore set to true
    if (cat === "PATENTS") {
      if (!this.state.showPatents) return null;
      return (
        <button
          class="btn btn-info btn-block mt-4"
          style={{
            width: "290px",
            margin: "auto",
            backgroundColor: "#5fcd69 !important",
            borderColor: "#5fcd69 !important",
          }}
          onClick={() => this.showMore("PATENTS")}
        >
          ShowMore
        </button>
      );
    }
    if (cat === "TRADEMARKS") {
      if (!this.state.showtrademarks) return null;
      return (
        <button
          class="btn btn-info btn-block mt-4"
          style={{
            width: "290px",
            margin: "auto",
            backgroundColor: "#5fcd69 !important",
            borderColor: "#5fcd69 !important",
          }}
          onClick={() => this.showMore("TRADEMARKS")}
        >
          Show More
        </button>
      );
    }
    if (cat === "COPYRIGHTS") {
      if (!this.state.showcopyrights) return null;
      return (
        <button
          class="btn btn-info btn-block mt-4"
          style={{
            width: "290px",
            margin: "auto",
            backgroundColor: "#5fcd69 !important",
            borderColor: "#5fcd69 !important",
          }}
          onClick={() => this.showMore("COPYRIGHTS")}
        >
          ShowMore
        </button>
      );
    }
    if (cat === "DESIGNS") {
      if (!this.state.showdesigns) return null;
      return (
        <button
          class="btn btn-info btn-block mt-4"
          style={{
            width: "290px",
            margin: "auto",
            backgroundColor: "#5fcd69 !important",
            borderColor: "#5fcd69 !important",
          }}
          onClick={() => this.showMore("DESIGNS")}
        >
          ShowMore
        </button>
      );
    }
  }

  fetchData() {
    const params = new URLSearchParams(this.props.location.search);
    const cat = params.get("cat");
    this.setState({ category: cat });
    axios
      .get(
        config.production.api +
          `search?keyword=%22na%22&category=%22trademark%22&subcategory=%22na%22`
      )
      .then((response) => {
        this.setState({ results: response.data.Items });
      });
  }
  _addfavourite(_id) {
    const data = {
      ip_Id: _id,
      email: localStorage.getItem("email"),
    };
    if (data.email) {
      //api call if user LogedIn
      axios.post(config.production.api + `favorites`, data).then((response) => {
        this.getAllCategories();
        this.latestTrademark();
        this.latestPatents();
        this.getAllPatent();
        this.getAllCopyright();
        this.latestCopyright();
        this.latestDesign();
        this.getAllDesign();
        ToastsStore.success(response.data.body);
        // body
        // console.log(response,"api")
      });
    } else {
      this.setState({ favNotification: true });
    }
  }

  hideFavNotification = () => {
    this.setState({ favNotification: false });
  };

  show_Notifification() {
    return (
      <div className="ABC">
        <label for="notify-2">
          <div
            id="notification-bar"
            style={{
              right: "0px",
              borderRadius: "5px",
              marginTop: "10px",
              marginRight: "10px",
              bottom: "0",
              marginBottom: "10px",
            }}
          >
            <div class="container" style={{ borderRadius: "5px" }}>
              <i
                class="fa fa-times-circle"
                style={{ marginTop: "3px" }}
                onClick={this.hideFavNotification}
              ></i>

              <p
                style={{
                  marginTop: "-5px",
                  paddingLeft: "15px",
                  position: "absolute",
                }}
              >
                Kindly{" "}
                <a href={"/login"} style={{ color: "white" }}>
                  Login
                </a>
                /
                <a href={"/register"} style={{ color: "white" }}>
                  Register
                </a>{" "}
                to add favourites
              </p>
            </div>
          </div>
        </label>
      </div>
    );
  }
  render() {
    const {
      ip_patents,
      ip_trademarks,
      ip_copyrights,
      ip_designs,
      patentslimit,
      trademarkslimit,
      copyrightslimit,
      designslimit,
    } = this.state;
    const total =
      ip_patents.length +
      ip_trademarks.length +
      ip_copyrights.length +
      ip_designs.length;

    return (
      <div>
        {!this.state.loading ? (
          <div>
            <section className="pt-50 pb-50 tg-haslayout bg-white">
              <div className="container ">
                <div className="tg-sectionhead">
                  <div className="tg-title">
                    <h2>Featured</h2>
                  </div>
                  <div className="tg-description">
                    <p>{total} Featured Ads</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="tg-sectionhead ">
                      <div className="tg-title ">
                        <h2 className="text-left mt-5">
                          <img src={trademark} width="22" alt="img" />{" "}
                          Trademarks
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="tg-ads mb-40">
                    {ip_trademarks
                      .slice(0, trademarkslimit)
                      .map((ip_trademark) => (
                        <div key={ip_trademark.id}>
                          {/* <Link to={"trademarks/ip/" + ip_trademark.id + '/' } params={{ id: ip_trademark.id }}> */}
                          <div
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            style={{ float: "left" }}
                          >
                            <div className="card-bg cstmh tg-ad tg-verifiedad cborder">
                              <figure>
                                <span className="tg-themetag tg-featuretag">
                                  featured
                                </span>
                                <Link
                                  to={"trademarks/ip/" + ip_trademark.id + "/"}
                                  params={{ id: ip_trademark.id }}
                                >
                                  {ip_trademark.images === "NA" ? (
                                    <img
                                      src={Trademarks}
                                      className={"dmimg"}
                                      alt="img"
                                    />
                                  ) : (
                                    <img
                                      src={ip_trademark.images[0]}
                                      className={"dmimg"}
                                    />
                                  )}
                                </Link>
                                {/*<span className="tg-photocount">See {ip_trademark.image_count} Photos</span>*/}
                              </figure>
                              <div className="tg-adcontent">
                                <Link
                                  to={"trademarks/ip/" + ip_trademark.id + "/"}
                                  params={{ id: ip_trademark.id }}
                                >
                                  <ul className="tg-productcagegories">
                                    <li>
                                      <a href="#">
                                        {titleCase(ip_trademark.category)}
                                      </a>
                                      <span className="card--status">
                                        {titleCase(ip_trademark.status)}
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="tg-adtitle p-0">
                                    <h3>
                                      <a href="#">
                                        {this.sortTitle(ip_trademark.title) || (
                                          <Skeleton />
                                        )}
                                      </a>
                                    </h3>
                                    <div className="row">
                                      <div
                                        className="col-sm-6"
                                        style={{ float: "left" }}
                                      >
                                        <time datetime="2017-06-06">
                                          Class {ip_trademark.classes.value}
                                        </time>
                                      </div>
                                      <div
                                        className="col-sm-6 text-right"
                                        style={{ float: "text-right" }}
                                      >
                                        {ip_trademark.noOfView != 0 ? (
                                          <time datetime="2017-06-06">
                                            {ip_trademark.noOfView} views
                                          </time>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </Link>

                                <p>{ip_trademark.about}</p>
                                <div className="tg-phonelike">
                                  <Link
                                    className="tg-btnphone"
                                    to={
                                      "trademarks/ip/" + ip_trademark.id + "/"
                                    }
                                    params={{ id: ip_trademark.id }}
                                  >
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Show Phone No."
                                      data-last="0800 - 1234 - 562 - 6"
                                    >
                                      <em>Show More</em>
                                    </span>
                                  </Link>
                                  <span class="tg-like">
                                    {/* {this.state.favoritesList.map( function (item){
                                                            if(item.id == ip_trademark.id){
                                                                this.setState({
                                                                    isFavorite:true
                                                                })
                                                            }
                                                        })} */}
                                    {/* {this.state.favoritesListID.length > 0 ? this.state.favoritesListID.map((item) =>
                                                         ip_trademark.id == item ? <i className="fa fa-heart selectedFav" ></i> : <i className="fa fa-heart " onClick={()=>this._addfavourite(ip_trademark.id)}></i>
                                                        
                                                        ):<i className="fa fa-heart " onClick={()=>this._addfavourite(ip_trademark.id)}></i>} */}
                                    {/* <i className="fa fa-heart " onClick={()=>this._addfavourite(ip_trademark.id)}></i> */}

                                    {/* {this.state.favoritesListID.map((item)=>                                                       

                                                             (ip_trademark.id) == item ? <i className="fa fa-heart selectedFav" ></i>:<i className="fa fa-heart " onClick={()=>this._addfavourite(ip_trademark.id)}></i>
                                                        )} */}

                                    {/* <i className="fa fa-heart selectedFav" ></i> : <i className="fa fa-heart " onClick={()=>this._addfavourite(ip_trademark.id)}></i> */}
                                    {/* (ip_trademark.isFvt) ? <i className="fa fa-heart selectedFav" ></i>: <i className="fa fa-heart " onClick={()=>this._addfavourite(ip_trademark.id)}></i> */}
                                    {/* {ip_trademark.isFvt ? "OK":"NOT OK"} */}
                                    {ip_trademark.isFvt ? (
                                      <i
                                        className="fa fa-heart selectedFav"
                                        onClick={() => {
                                          this._delFavourite(ip_trademark.id);
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-heart "
                                        onClick={() =>
                                          this._addfavourite(ip_trademark.id)
                                        }
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                      ))}
                    {ip_trademarks.length > 8 &&
                      this.renderButton("TRADEMARKS")}
                  </div>
                </div>
              </div>

              <div className="container ">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="tg-sectionhead ">
                      <div className="tg-title ">
                        <h2 className="text-left">
                          <img src={patents} width="22" alt="img" /> Patents
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="tg-ads mb-40">
                    {ip_patents.slice(0, patentslimit).map((ip_patent) => (
                      <div key={ip_patent.id}>
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                          <div className="card-bg cstmh tg-ad tg-verifiedad cborder">
                            <figure>
                              <span className="tg-themetag tg-featuretag">
                                featured
                              </span>
                              <Link
                                to={"patents/ip/" + ip_patent.id}
                                params={{ id: ip_patent.id }}
                              >
                                {ip_patent.images !== "NA" ? (
                                  <img
                                    src={ip_patent.images[0]}
                                    className={"dmimg"}
                                  />
                                ) : (
                                  <img
                                    src={Patents}
                                    alt="image description"
                                    className={"dmimg"}
                                  />
                                )}
                              </Link>
                            </figure>
                            <div className="tg-adcontent">
                              <Link
                                to={"patents/ip/" + ip_patent.id}
                                params={{ id: ip_patent.id }}
                              >
                                <ul className="tg-productcagegories">
                                  <li>
                                    <a href="#">
                                      {titleCase(ip_patent.category)}
                                    </a>
                                    <span className="card--status">
                                      {titleCase(ip_patent.status)}
                                    </span>
                                  </li>
                                </ul>
                                <div className="tg-adtitle p-0">
                                  <h3>
                                    <a href="#">
                                      {this.sortTitle(ip_patent.title)}...
                                    </a>
                                  </h3>
                                  <div className="row">
                                    <div
                                      className="col-sm-6"
                                      style={{ float: "left" }}
                                    >
                                      <time datetime="2017-06-06">
                                        {this.sortSubTitle(
                                          ip_patent.sub_category
                                        )}
                                        ...
                                      </time>
                                    </div>
                                    <div className="col-sm-6 text-right">
                                      {ip_patent.noOfView != 0 ? (
                                        <time datetime="2017-06-06">
                                          {ip_patent.noOfView} views
                                        </time>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <p>{ip_patent.about}</p>
                              <div className="tg-phonelike">
                                <Link
                                  className="tg-btnphone"
                                  to={"patents/ip/" + ip_patent.id}
                                  params={{ id: ip_patent.id }}
                                >
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Show Phone No."
                                    data-last="0800 - 1234 - 562 - 6"
                                  >
                                    <em>Show More</em>
                                  </span>
                                </Link>
                                <span class="tg-like">
                                  {/* <i class="fa fa-heart" onClick={()=>this._addfavourite(ip_patent.id)}></i> */}
                                  {ip_patent.isFvt ? (
                                    <i
                                      className="fa fa-heart selectedFav"
                                      onClick={() => {
                                        this._delFavourite(ip_patent.id);
                                      }}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-heart "
                                      onClick={() =>
                                        this._addfavourite(ip_patent.id)
                                      }
                                    ></i>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {ip_patents.length > 8 && this.renderButton("PATENTS")}
                  </div>
                </div>
              </div>

              <div class="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="tg-sectionhead ">
                      <div className="tg-title ">
                        <h2 className="text-left">
                          <img src={Copyright} width="28" /> Copyrights
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="tg-ads mb-40">
                    {ip_copyrights
                      .slice(0, copyrightslimit)
                      .map((ip_copyright) => (
                        <div key={ip_copyright.id}>
                          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="card-bg cstmh tg-ad tg-verifiedad cborder">
                              <figure>
                                <span className="tg-themetag tg-featuretag">
                                  featured
                                </span>
                                <Link
                                  to={"copyrights/ip/" + ip_copyright.id + "/"}
                                  params={{ id: ip_copyright.id }}
                                >
                                  {ip_copyright.images !== "NA" ? (
                                    <img
                                      src={ip_copyright.images[0]}
                                      className={"dmimg"}
                                    />
                                  ) : (
                                    <img
                                      src={Copyright}
                                      alt="image description"
                                      className={"dmimg"}
                                    />
                                  )}
                                </Link>
                              </figure>
                              <div className="tg-adcontent">
                                <Link
                                  to={"copyrights/ip/" + ip_copyright.id + "/"}
                                  params={{ id: ip_copyright.id }}
                                >
                                  <ul className="tg-productcagegories">
                                    <li>
                                      <a href="#">
                                        {titleCase(ip_copyright.category)}
                                      </a>
                                      <span className="card--status">
                                        {titleCase(ip_copyright.status)}
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="tg-adtitle p-0">
                                    <h3>
                                      <a href="#">{ip_copyright.title}</a>
                                    </h3>
                                    <div className="row">
                                      <div
                                        className="col-sm-6"
                                        style={{ float: "left" }}
                                      >
                                        <time datetime="2017-06-06">
                                          {ip_copyright.work_type}
                                        </time>
                                      </div>
                                      <div
                                        className="col-sm-6 text-right"
                                        style={{ float: "text-right" }}
                                      >
                                        {ip_copyright.noOfView != 0 ? (
                                          <time>
                                            {ip_copyright.noOfView} views
                                          </time>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                                <p>{ip_copyright.about}</p>
                                <div className="tg-phonelike">
                                  <Link
                                    className="tg-btnphone"
                                    to={
                                      "copyrights/ip/" + ip_copyright.id + "/"
                                    }
                                    params={{ id: ip_copyright.id }}
                                  >
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Show Phone No."
                                      data-last="0800 - 1234 - 562 - 6"
                                    >
                                      <em>Show More</em>
                                    </span>
                                  </Link>
                                  <span class="tg-like">
                                    {/* <i class="fa fa-heart" onClick={()=>this._addfavourite(ip_patent.id)}></i> */}
                                    {ip_copyright.isFvt ? (
                                      <i
                                        className="fa fa-heart selectedFav"
                                        onClick={() => {
                                          this._delFavourite(ip_copyright.id);
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-heart "
                                        onClick={() =>
                                          this._addfavourite(ip_copyright.id)
                                        }
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {ip_copyrights.length > 8 &&
                      this.renderButton("COPYRIGHTS")}
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="tg-sectionhead ">
                      <div className="tg-title ">
                        <h2 className="text-left">
                          <img src={Design} width="22" /> Designs
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="tg-ads mb-40">
                    {ip_designs.slice(0, designslimit).map((ip_design) => (
                      <div key={ip_design.id}>
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                          <div className="card-bg cstmh tg-ad tg-verifiedad cborder">
                            <figure>
                              <span className="tg-themetag tg-featuretag">
                                featured
                              </span>
                              <Link
                                to={"designs/ip/" + ip_design.id}
                                params={{ id: ip_design.id }}
                              >
                                {ip_design.images !== "NA" ? (
                                  <img
                                    src={ip_design.images[0]}
                                    className={"dmimg"}
                                  />
                                ) : (
                                  <img
                                    src={Design}
                                    alt="image description"
                                    className={"dmimg"}
                                  />
                                )}
                              </Link>
                            </figure>
                            <div className="tg-adcontent">
                              <Link
                                to={"designs/ip/" + ip_design.id}
                                params={{ id: ip_design.id }}
                              >
                                <ul className="tg-productcagegories">
                                  <li>
                                    <a href="#">
                                      {titleCase(ip_design.category)}
                                    </a>
                                    <span className="card--status">
                                      {titleCase(ip_design.status)}
                                    </span>
                                  </li>
                                </ul>
                                <div className="tg-adtitle p-0">
                                  <h3>
                                    <a href="#">{ip_design.title}</a>
                                  </h3>
                                  <div className="row">
                                    <div
                                      className="col-sm-6"
                                      style={{
                                        float: "left",
                                        "margin-top": "10px",
                                      }}
                                    >
                                      {ip_design.classes.value ? (
                                        <time datetime="2017-06-06">
                                          Class {ip_design.classes.value}
                                        </time>
                                      ) : null}
                                    </div>
                                    <div className="col-sm-6 text-right">
                                      {ip_design.noOfView != 0 ? (
                                        <time>{ip_design.noOfView} views</time>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <p>{ip_design.about}</p>
                              <div className="tg-phonelike">
                                <Link
                                  className="tg-btnphone"
                                  to={"designs/ip/" + ip_design.id}
                                  params={{ id: ip_design.id }}
                                >
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Show Phone No."
                                    data-last="0800 - 1234 - 562 - 6"
                                  >
                                    <em>Show More</em>
                                  </span>
                                </Link>
                                <span class="tg-like">
                                  {/* <i class="fa fa-heart"></i> */}
                                  {ip_design.isFvt ? (
                                    <i
                                      className="fa fa-heart selectedFav"
                                      onClick={() => {
                                        this._delFavourite(ip_design.id);
                                      }}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-heart "
                                      onClick={() =>
                                        this._addfavourite(ip_design.id)
                                      }
                                    ></i>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {ip_designs.length > 8 && this.renderButton("DESIGNS")}
                  </div>
                </div>
              </div>
            </section>

            <section className="pt-50 pb-50 tg-haslayout tg-bglight">
              <div className="container ">
                <div className="tg-sectionhead">
                  <div className="tg-title">
                    <h2>Latest IPs</h2>
                  </div>
                  <div className="tg-description">
                    {/* <p>{total} Featured Ads</p> */}
                  </div>
                </div>
              </div>

              {/* Latest Trademarks */}

              <div class="container" style={{ marginTop: "60px" }}>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="tg-sectionhead">
                      <div class="tg-title">
                        <h2>Trademarks</h2>
                      </div>
                    </div>
                  </div>
                  <div class="tg-ads tg-adsvtwo">
                    {this.state.latestTrademark.slice(0, 4).map((item) => (
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="card-bg tg-ad tg-verifiedad cborder">
                          <figure>
                            <Link
                              to={"trademarks/ip/" + item.id + "/"}
                              params={{ id: item.id }}
                            >
                              {item.images !== "NA" ? (
                                <img
                                  src={item.images[0]}
                                  style={{ width: "200px", height: "200px" }}
                                  width={200}
                                />
                              ) : (
                                <img
                                  src={Trademarks}
                                  alt="img"
                                  style={{ width: "200px" }}
                                  width={200}
                                />
                              )}
                            </Link>
                          </figure>
                          <div class="tg-adcontent">
                            <ul class="tg-productcagegories">
                              <li>
                                <a href="javascript:void(0);">
                                  Trademark, Class {item.classes.value}
                                </a>
                              </li>
                            </ul>
                            <div class="tg-adtitle">
                              <h3>
                                <a href="javascript:void(0);">{item.title}</a>
                              </h3>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <time datetime="2017-06-06">{item.status}</time>
                              </div>
                              <div className="col-sm-6 text-right">
                                {item.noOfView != 0 ? (
                                  <time datetime="2017-06-06">
                                    {item.noOfView} views
                                  </time>
                                ) : null}
                              </div>
                            </div>
                            <div class="tg-adprice">
                              <h4>Country : {item.country}</h4>
                            </div>
                            <div class="tg-phonelike" style={{ width: "100%" }}>
                              <Link
                                className="tg-btnphone"
                                to={"trademarks/ip/" + item.id + "/"}
                                params={{ id: item.id }}
                              >
                                <span
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Show more"
                                >
                                  <em>Show more</em>
                                </span>
                              </Link>
                              <span class="tg-like">
                                {item.isFvt ? (
                                  <i
                                    className="fa fa-heart selectedFav"
                                    onClick={() => {
                                      this._delFavourite(item.id);
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-heart "
                                    onClick={() => this._addfavourite(item.id)}
                                  ></i>
                                )}
                                <i class="fa fa-heart"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Latest Trademarks Ends */}

              {/* Latest Patents */}

              <div class="container" style={{ marginTop: "60px" }}>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="tg-sectionhead">
                      <div class="tg-title">
                        <h2>Patent</h2>
                      </div>
                    </div>
                  </div>
                  <div class="tg-ads tg-adsvtwo">
                    {this.state.latestPatent.slice(0, 4).map((item) => (
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="card-bg tg-ad tg-verifiedad cborder">
                          <figure>
                            <Link
                              to={"patents/ip/" + item.id + "/"}
                              params={{ id: item.id }}
                            >
                              {item.images !== "NA" ? (
                                <img
                                  src={item.images[0]}
                                  style={{ width: "200px", height: "200px" }}
                                  width={200}
                                />
                              ) : (
                                <img
                                  src={Trademarks}
                                  alt="img"
                                  style={{ width: "200px" }}
                                  width={200}
                                />
                              )}
                            </Link>
                          </figure>
                          <div class="tg-adcontent">
                            <ul class="tg-productcagegories">
                              <li>
                                <a href="javascript:void(0);">
                                  Patent,{this.sortTitle(item.sub_category)}{" "}
                                </a>
                              </li>
                            </ul>
                            <div class="tg-adtitle">
                              <h3>
                                <a href="javascript:void(0);">{item.title}</a>
                              </h3>
                            </div>
                            <div className="row">
                              <div
                                className="col-sm-6"
                                style={{ float: "left", "margin-top": "10px" }}
                              >
                                <time datetime="2017-06-06">{item.status}</time>
                              </div>
                              <div
                                className="col-sm-6 text-right"
                                style={{ float: "text-right" }}
                              >
                                {item.noOfView != 0 ? (
                                  <time>{item.noOfView} views</time>
                                ) : null}
                              </div>
                            </div>
                            <div class="tg-adprice">
                              <h4>
                                Priority Country : {item.priority_country}
                              </h4>
                            </div>
                            <div class="tg-phonelike" style={{ width: "100%" }}>
                              <Link
                                className="tg-btnphone"
                                to={"patents/ip/" + item.id + "/"}
                                params={{ id: item.id }}
                              >
                                <span
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Show more"
                                >
                                  <em>Show more</em>
                                </span>
                              </Link>
                              <span class="tg-like">
                                {/* <i class="fa fa-heart"></i> */}
                                {item.isFvt ? (
                                  <i
                                    className="fa fa-heart selectedFav"
                                    onClick={() => {
                                      this._delFavourite(item.id);
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-heart "
                                    onClick={() => this._addfavourite(item.id)}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Latest Patents Ends */}

              {/* latest copyright */}
              <div class="container" style={{ marginTop: "60px" }}>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="tg-sectionhead">
                      <div class="tg-title">
                        <h2>Copyright</h2>
                      </div>
                    </div>
                  </div>
                  <div class="tg-ads tg-adsvtwo">
                    {this.state.latestCopyright.slice(0, 4).map((item) => (
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="card-bg tg-ad tg-verifiedad cborder">
                          <figure>
                            <Link
                              to={"copyrights/ip/" + item.id + "/"}
                              params={{ id: item.id }}
                            >
                              {item.images !== "NA" ? (
                                <img
                                  src={item.images[0]}
                                  style={{ width: "200px", height: "200px" }}
                                  width={200}
                                />
                              ) : (
                                <img
                                  src={Trademarks}
                                  alt="img"
                                  style={{ width: "200px" }}
                                  width={200}
                                />
                              )}
                            </Link>
                          </figure>
                          <div class="tg-adcontent">
                            <ul class="tg-productcagegories">
                              <li>
                                <a href="javascript:void(0);">
                                  Copyright, {this.sortTitle(item.work_type)}{" "}
                                </a>
                              </li>
                            </ul>
                            <div class="tg-adtitle">
                              <h3>
                                <a href="javascript:void(0);">{item.title}</a>
                              </h3>
                            </div>
                            <div className="row">
                              <div
                                className="col-sm-6"
                                style={{ float: "left", "margin-top": "10px" }}
                              >
                                <time datetime="2017-06-06">{item.status}</time>
                              </div>
                              <div
                                className="col-sm-6 text-right"
                                style={{ float: "text-right" }}
                              >
                                {item.noOfView != 0 ? (
                                  <time>{item.noOfView} views</time>
                                ) : null}
                              </div>
                            </div>
                            <div class="tg-adprice">
                              <h4>Country : {item.country}</h4>
                            </div>
                            <div class="tg-phonelike" style={{ width: "100%" }}>
                              <Link
                                className="tg-btnphone"
                                to={"copyrights/ip/" + item.id + "/"}
                                params={{ id: item.id }}
                              >
                                <span
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Show more"
                                >
                                  <em>Show more</em>
                                </span>
                              </Link>
                              <span class="tg-like">
                                {/* <i class="fa fa-heart"></i> */}
                                {item.isFvt ? (
                                  <i
                                    className="fa fa-heart selectedFav"
                                    onClick={() => {
                                      this._delFavourite(item.id);
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-heart "
                                    onClick={() => this._addfavourite(item.id)}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* latest copyright ends */}

              {/* latest Design */}
              <div class="container" style={{ marginTop: "60px" }}>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="tg-sectionhead">
                      <div class="tg-title">
                        <h2>Design</h2>
                      </div>
                    </div>
                  </div>
                  <div class="tg-ads tg-adsvtwo">
                    {this.state.latestDesign.slice(0, 4).map((item) => (
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="card-bg tg-ad tg-verifiedad cborder">
                          <figure>
                            <Link
                              to={"designs/ip/" + item.id + "/"}
                              params={{ id: item.id }}
                            >
                              {item.images !== "NA" ? (
                                <img
                                  src={item.images[0]}
                                  style={{ width: "200px", height: "200px" }}
                                  width={200}
                                />
                              ) : (
                                <img
                                  src={Trademarks}
                                  alt="img"
                                  style={{ width: "200px" }}
                                  width={200}
                                />
                              )}
                            </Link>
                          </figure>
                          <div class="tg-adcontent">
                            <ul class="tg-productcagegories">
                              <li>
                                <a href="javascript:void(0);">
                                  Design, Class {item.classes.value}
                                </a>
                              </li>
                            </ul>
                            <div class="tg-adtitle">
                              <h3>
                                <a href="javascript:void(0);">{item.title}</a>
                              </h3>
                            </div>
                            <div className="row">
                              <div
                                className="col-sm-6"
                                style={{ float: "left", "margin-top": "10px" }}
                              >
                                <time datetime="2017-06-06">{item.status}</time>
                              </div>
                              <div className="col-sm-6 text-right">
                                {item.noOfView != 0 ? (
                                  <time>{item.noOfView} views</time>
                                ) : null}
                              </div>
                            </div>
                            <div class="tg-adprice">
                              <h4>Country : {item.country}</h4>
                            </div>
                            <div class="tg-phonelike" style={{ width: "100%" }}>
                              <Link
                                className="tg-btnphone"
                                to={"designs/ip/" + item.id + "/"}
                                params={{ id: item.id }}
                              >
                                <span
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Show more"
                                >
                                  <em>Show more</em>
                                </span>
                              </Link>
                              <span class="tg-like">
                                {/* <i class="fa fa-heart"></i> */}
                                {item.isFvt ? (
                                  <i
                                    className="fa fa-heart selectedFav"
                                    onClick={() => {
                                      this._delFavourite(item.id);
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-heart "
                                    onClick={() => this._addfavourite(item.id)}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {this.state.favNotification
                    ? this.show_Notifification()
                    : null}
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div style={{ textAlign: "center", margin: "auto" }}>
            <CircularProgress
              style={{ width: 100, height: 100, margin: "120px" }}
            />
          </div>
        )}
      </div>
    );
  }
}
export default App;
