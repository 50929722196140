import React from "react";
import Header from "./header";
import { Link } from "react-router-dom";
import Sidebar from "../user/sidebar";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../store/actions/authActions";
import { Auth } from "aws-amplify";
import ChangePassword from "./changePassword";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      showPasswordEdit: false,
      localUsers: {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        isSocial : localStorage.getItem("isSocial")

      }
    };
    this.showPass = this.showPass.bind(this);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        this.setState({ users: user.attributes });
      })
      .catch(err => console.log(err));
  }

  showPass() {
    this.setState({ showPasswordEdit: true });
  }

  render() {
    const { users } = this.state;
    const { localUsers } = this.state;

    return (
      <div className={"user-ml"}>
        <Sidebar />
        <Header />

        <div className="tg-dashboardbanner">
          <h1>Profile</h1>
          <ol className="tg-breadcrumb">
            <li>
              <a href="javascript:void(0);">Home</a>
            </li>
            <li>
              <a href="javascript:void(0);">Profile</a>
            </li>
          </ol>
        </div>

        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-dbsectionspace tg-haslayout">
            <div className="row">
              <form className="tg-formtheme tg-formdashboard">
                <fieldset>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="tg-dashboardbox">
                      <div className="tg-dashboardboxtitle">
                        <h2>Account Information</h2>
                      </div>
                      <div className="tg-dashboardholder">
                        <div className="tg-otherfilters">
                          <div className="row">
                            <div className={"col-sm-6"}>
                              <table className={"profileTable"}>
                                <tr>
                                  <td>Name</td>
                                  <td>
                                    {users.length == 0
                                      ? localUsers.name
                                      : users.name}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  <td>
                                    {users.length == 0
                                      ? localUsers.email
                                      : users.email}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mobile</td>
                                  <td>NA</td>
                                </tr>
                                <tr>
                                  <td>Password</td>
                                  <td>
                                    {console.log(this.state.localUsers.isSocial,"IS SOCIAL")}

                                    { !this.state.localUsers.isSocial ? "Authenticated via Social Accounts" :   <span style={{ cursor: "pointer" }} onClick={this.showPass}>
                                      <u>Change Password</u>{" "}
                                      <i
                                          className="icon-pencil"
                                          style={{ fontSize: "12px" }}
                                      />
                                    </span>}

                                  </td>
                                </tr>
                              </table>

                              <Link to={"/dashboard/profile/edit"} className={"btn btn-danger"}
                              >
                                Edit
                              </Link>
                            </div>

                            {this.state.showPasswordEdit ? (
                              <div className={"col-sm-6"}>
                                <ChangePassword />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  userAuth: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userAuth: state.userAuth,
  userData: state.userData
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(Dashboard));
