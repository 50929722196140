import React from 'react';
import Navbar from '../../includes/navbar';
import Footer from '../../includes/footer';
import Objection from '../../images-custom/trademark_objection.png';
import QuickContact from './quickContact';
import Assignment from "../../images-custom/trademark_assigment.png";



class trademarkAssignment extends React.Component {

    render(){

        return (
            <div>
                <Navbar />

                <div className="container">


                <section className="tg-sectionspace tg-haslayout">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-10">

                                        <h2>TRADE MARKS</h2>
                                        <hr className="hr-custom" />
                                        <p>In India Trade marks are registered with Controller General of Patents Designs and Trade marks, Ministry of Commerce and Industry, Government of India. Trade marks are registered under the Trade mark Act, 1999.</p>

                                        <h3>OBJECTION</h3>
                                        <hr className="hr-custom" />
                                        <p>Once the trade mark is filed with the Registrar, it has to be examined within 30 days. After the examination an examination report(ER) is published which is available on their website. If there are no objections raised for the proposed trade mark then it is sent for publication in the journal. However, in some case there are objections raised due to the mark not being distinctive, deceptively similar, etc. which are mentioned in sections 9 and 11 of the trade mark act.</p>
                                        <p>The applicant has an opportunity to respond to the objections stating the reasons on why the mark should be accepted in favor of the applicant based on the objections raised. The reply to the ER should ideally be filed within 30 days after the report was published. Not doing so will allow the Registrar to abandon the application for the proposed trade mark. The filing fee originally paid will not be reimbursed either.</p>

                                        <div className="text-center" style={{marginBottom:'15px'}}>
                                            <img src={Objection} />
                                        </div>

                                        <p style={{marginTop:'20px'}}>Drafting for the ER response can take 4-5 days to prepare with all facts, evidences and any related judgement to ensure the response to the ER is complete and it stands a good chance when it is reviewed for the purposes of acceptance of the proposed trade mark.</p>

                                        <p><b>TRADEMYIP can help in providing a free consultation and filing the response to the ER.</b> Once the response is filed then the Registry will take time to process the response. <b>There is no guarantee that by filing the response the proposed trade mark will be accepted. It totally depends on case by case basis.</b></p>

                                        

                                        <QuickContact />
                                    </div>
                                </div>
                            </div>
                        </section>

                </div>
                <Footer />

            </div>
        );
    }
}

export default trademarkAssignment;
