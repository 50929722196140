export default {
  cognito: {
    REGION: "ap-south-1",

    USER_POOL_ID:
      process.env.REACT_APP_API_ENVIRONMENT === "production"
        ? "ap-south-1_KBuXJro5r"
        : "ap-south-1_nWOSmSlvD",
    APP_CLIENT_ID:
      process.env.REACT_APP_API_ENVIRONMENT === "production"
        ? "3guuic12njlbids4j7in5fakf5"
        : "5voisja7gl87e4bocbs9dr2i8l",

    //FOR PROD
    //USER_POOL_ID: "ap-south-1_KBuXJro5r",
    //APP_CLIENT_ID: "3guuic12njlbids4j7in5fakf5",

    //FOR STG
    // USER_POOL_ID: "ap-south-1_nWOSmSlvD",
    // APP_CLIENT_ID: "5voisja7gl87e4bocbs9dr2i8l"
  },
};
