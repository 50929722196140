import React, { Component } from 'react';
import Navbar from '../includes/navbar';
import titleCase from 'title-case';
import "./index.css";
import MakeOffer from "./modalOffer";
import "./crousel.css";
import Footer from '../includes/footer';
import axios from "axios";
import config from "../environment";
import { Link } from 'react-router-dom';
import {ToastsContainer, ToastsStore} from "react-toasts";
import CarouselS from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player"


import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';

import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
} from 'react-share';
import {Carousel} from "react-responsive-carousel";
import Copyright from "../images-custom/ip-logos/copyright.jpg";
import Skeleton from 'react-loading-skeleton';




class Home extends Component {

    constructor(props) {
        super(props);
       
        this.state = {
            ip: [],
            images:[],
            documents:[],
            links:[],
            related: [],
            favorites : [],
            favoritesListID:[],
            video:[],
            isFav : false,
            favNotification : false,
            viewsList:[],
            isdataLoaded : false
        }
        this.showSimilarIPs = this.showSimilarIPs.bind(this)
    }

    componentWillMount() {
        window.scrollTo(0, 0)
    }


    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

   async componentDidMount(){
           await this.ipDetails();
           await this.showSimilarIPs();
    }



    //IP Data 
    async ipDetails(){
        const { id } = this.props.match.params;
        const url = config.production.api + `fetchbyid?category=%22copyright%22&id=%22${id}%22`;

       await axios.get(url)
            .then(response => {
                this.setState({ip: response.data.Item, isdataLoaded : true,images: response.data.Item.images, video:response.data.Item.video,documents: response.data.Item.documents, links: response.data.Item.links})
            });

         const viewData = {
                "id":id
            }
            
            await  axios.post(config.production.api+`no-of-views`,viewData)
            .then(response => {
                console.log("noofviews",response)
            });
            
            const data = {
                "email":localStorage.getItem("email")
            }

            //Get favorites list
        axios.post(config.production.api +`favorites`, data)
        .then(response => {
                this.setState({favorites: response.data.body})

                for(let i = 0; i <= this.state.favorites.length; i++){                                    
                    if(this.state.favorites.length > 0){

                        if(this.state.favorites[i].id == this.state.ip.id){                                           
                            this.setState({isFav : true})
                           
                            break;    
                        }     
                        else{
                            
                            this.setState({isFav : false})
                         }                  
                    }
                    else{
                        
                        this.setState({isFav : false})
                     }    
             }
        })
        .catch( error => {
            // handle error
            console.log(error,"Error");
            })
    }

    //Function for show similar IPs
       

    showSimilarIPs = async() => {
        const subCat = this.state.ip.work_type
        const id = this.state.ip.id    
            await axios.get(config.production.api +  `showsimilarips?category="copyright"&subcategory="`+ subCat + `"&id="`+id+`"`)
                .then(response => {
                    const data = response.data.body.Items;
                    data.forEach(e => {
                        e.isFvt=false;
                    });
                    console.log(data,"dd") 
                    
                    console.log(this.state.favorites.length, "dd_2");
                    //this.setState({related: response.data.body.Items})
                    for(let i = 0; i < this.state.favorites.length; i++){                                    
                        for(let k=0; k< data.length; k++){   
                            console.log(data.length)                                  
                            if(this.state.favorites[i].id == data[k].id){                                           
                                 data[k].isFvt=true;
                                 console.log("matched","dd_")
                                 break;    
                            }
                                                 
                        }
                     }
                   this.setState({related: data})
                     axios.get(config.production.api+`no-of-views`)
                   .then(response => {
                       console.log("response ",response)
                       this.setState({viewsList : response.data})
       
                       for(let i = 0; i < this.state.viewsList.length; i++){                                    
                          for(let k=0; k< data.length; k++){       
                              let keys = Object.keys(this.state.viewsList[i]);         
                              if(keys == data[k].id){
                               data[k].noOfView = this.state.viewsList[i][keys]
                               break;
                              }                   
                                                   
                              }
                       }
                   
                       this.setState({related: data})        
                   })
                    
                })
                .catch(error=> {
                    // handle error
                    // console.log(error,"Error");
                })
                
        }

     //Add Favourites
     _addfavourite =(id)=> {
        const data={
            ip_Id: id,
            email:localStorage.getItem("email")
        }

        console.log(data.ip_Id,"THIS")
        this.ipDetails()
       if(data.email){
           //api call if user LogedIn
         axios.post( config.production.api + `favorites`,data)
        .then(response => {
            ToastsStore.success(response.data.body)
            this.ipDetails();
            setTimeout(()=>{
                this.showSimilarIPs();
            },100)

        });
       }
       else{
        this.setState({favNotification : true})
       }
    }

    //Delete Favourite
    _delFavourite =(id)=>{
        const data={
            ip_Id: id,
			email:localStorage.getItem("email"),
			remove_Fav: "true"
        }
       
         axios.post(config.production.api+`favorites`,data)
        .then(response => {
            ToastsStore.info(response.data.body)
            this.ipDetails();
        });
    }



    timeConverter(UNIX_timestamp){
        let a = new Date(UNIX_timestamp );
        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = a.getDate();
        let time = date + ' ' + month + ' ' + year ;
        return time;
    }


    hideFavNotification = () => {
        this.setState({favNotification : false})
    }

    showFavNotification = () => {
        this.setState({favNotification : true})
    }

    show_Notifification(){
        return (
            <div className="ABC">
              <label for="notify-2">
                <div id="notification-bar" style={{right: '0px', borderRadius: '5px', marginTop: '10px', marginRight: '10px', bottom: '0', marginBottom: '10px'}}>
                    <div class="container" style={{borderRadius: '5px'}}>
                        <i class="fa fa-times-circle" style={{marginTop: '3px'}} onClick={this.hideFavNotification}></i>
                        
                        <p style={{marginTop: '-5px', paddingLeft: '15px', position: 'absolute'}}>Kindly <a href={'/login'} style={{color:'white'}}>Login</a>/<a href={'/register'} style={{color:'white'}}>Register</a> to add favourites</p>
                    </div>
                </div>
                </label>

        </div>
        )
    }




    render() {

        const { images, documents, related, links, ip ,video } = this.state;
        const imgValues = Object.values(images);
        const videoValue=[];
        videoValue.push(video);
        const docValues = Object.values(documents);
        let videoUrl = "https://www.youtube.com/watch?v="+video;
       


        return (
            <div>
                <div>
                    <Navbar />
                    <main id="tg-main" className="tg-main tg-haslayout bg-white">
                        <div className="container">
                            <div className="row">


                                <div id="tg-twocolumns" className="tg-twocolumns">
                                    <div className="col-xs-12 col-sm-5 col-md-4 col-lg-4">
                                        <aside id="tg-sidebar" className="tg-sidebar">
                                            { ip.price && ip.price == 'NA' ? '' :

                                                <div className="tg-pricebox">
                                                    <div className="tg-priceandlastupdate">
                                                    <span>
                                                        { ip.price ? '₹' + ip.price : 'Loading...' }
                                                    </span>

                                                        <span>Seller asked price</span>
                                                    </div>
                                                </div>

                                            }
                                            <div className="tg-sellercontactdetail mb-40">
                                            <div className="tg-sellertitle" style={{background:'#363b4d'}}><h1 style={{color:'white'}}>IP Detail</h1></div>
                                                <div className="tg-sellercontact">

                                                    <div className="row" style={{marginBottom:'25px'}}>


                                                            <table className="table">
                                                                <tbody>
                                                                <tr>
                                                                    <td scope="col"><h4>IP Type </h4></td>
                                                                    <td><h4>{titleCase(ip.category) || <Skeleton /> }</h4></td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col"><h4>Type of work</h4></td>
                                                                    <td><h4>{titleCase(ip.work_type) || <Skeleton /> }</h4></td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col"><h4>Application No.</h4></td>
                                                                    <td><h4>{ip.registration_number || <Skeleton /> }</h4></td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col"><h4>Country</h4></td>
                                                                    <td><h4>{titleCase(ip.country) || <Skeleton />}</h4></td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col"><h4>Status</h4></td>
                                                                    <td><h4>{titleCase(ip.status) || <Skeleton />}</h4></td>
                                                                </tr>
                                                                { ip.publishedOn == "NA" ?
                                                                   null
                                                                    :
                                                                    <tr>
                                                                        <td scope="col"><h4>Published Date </h4></td>
                                                                        <td><h4>{ip.publishedOn || <Skeleton />}</h4></td>
                                                                    </tr>

                                                                }
                                                                </tbody>
                                                            </table>
                                                    </div>


                                                    { ip.isApproved ? (localStorage.getItem("email") ? (<MakeOffer id={ip.id}  id={ip.id} category={ip.category} emailTo={ip.email} ip_title={ip.title} name={ip.user} class={this.state.ip.work_type}/>) : <div onClick={this.showFavNotification}><MakeOffer /></div>) : null }
                                                    {this.state.isFav == true ?<span class="tg-like tg-liked" onClick={()=>this._delFavourite(this.state.ip.id)}><i class="fa fa-heart">Favourite Added</i></span> : <span class="tg-like" onClick={()=>this._addfavourite(this.state.ip.id)}><i class="fa fa-heart">Add To Favourite</i></span>}

                                                </div>

                                            </div>
                                        </aside>
                                    </div>
                                    <div className="col-xs-12 col-sm-7 col-md-8 col-lg-8">
                                        <div id="tg-content" className="tg-content">
                                            <div className="tg-ad tg-verifiedad tg-detail tg-addetail">
                                                <div className="tg-adcontent">
                                                    <ul className="tg-pagesequence">
                                                        <li><a href="javascript:void(0);">{titleCase(ip.category) || <Skeleton />}</a></li>
                                                        <li><a href="javascript:void(0);">{titleCase(ip.sub_category) || <Skeleton />}</a></li>
                                                    </ul>
                                                    <div className="tg-adtitle">
                                                        <h2>{ip.title || <Skeleton />}</h2>
                                                    </div>
                                                    <ul className="tg-admetadata">
                                                        <li>By: {titleCase(ip.user) || <Skeleton />}</li>
                                                        <li>Date :  { this.state.isdataLoaded ? this.timeConverter(ip.date) : <Skeleton />}   </li>
                                                        <li><span className="ip-status">{titleCase(ip.status) || <Skeleton />}</span></li>
                                                    </ul>
                                                    <div className="tg-share">
                                                        <strong>share:</strong>
                                                        <ul className="tg-socialicons">
                                                            <li>
                                                                <FacebookShareButton url={window.location.href}>
                                                                    <FacebookIcon size={32} round={true} />
                                                                </FacebookShareButton>
                                                            </li>

                                                            <li>
                                                                <TwitterShareButton url={window.location.href}>
                                                                    <TwitterIcon size={32} round={true} />
                                                                </TwitterShareButton>
                                                            </li>
                                                            <li>
                                                                <LinkedinShareButton url={window.location.href}>
                                                                    <LinkedinIcon size={32} round={true}  />
                                                                </LinkedinShareButton>
                                                            </li>
                                                            <li>
                                                                <WhatsappShareButton url={window.location.href}>
                                                                    <WhatsappIcon size={32} round={true} />
                                                                </WhatsappShareButton>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>


                                                <div className="tg-description">
                                                    <div>

                                                        <div>{ images && images !== 'NA'?
                                                            <div>
                                                                <Carousel >
                                                                    {
                                                                        imgValues.map((img) =>
                                                                            <div>
                                                                                <img src={img} style={{width:'100%' , height:'inherit'}} />
                                                               
                                                                            </div>
                                                                        )
                                                                        
                                                                    }
                                                                </Carousel>
                                                               
                                                            </div> : <img src={Copyright} style={{width:'350px'}} /> }
                                                        </div>
                                                        <div style={{marginTop:'15px'}}>
                                                           
                                                        { video && video != "NA"? <h3>Video</h3> : null }
                                                            
                                                        </div>
                                                        <div >{ video && video != "NA"?
                                                            <div>                                                              
                                                           <ReactPlayer url={videoUrl} controls={true}></ReactPlayer>                                                              
                                                            </div> :<div><h4></h4></div> }
                                                        </div>
                                                      

                                                        <div style={{marginTop:'15px'}}>
                                                            <h3>Summary</h3>
                                                            <p>{ip.summary || <Skeleton />}</p>
                                                        </div>



                                                        <div style={{marginTop:'15px'}}>
                                                            <h3>Links</h3>

                                                            {

                                                                links.map((link) =>
                                                                    <div>  {
                                                                        link.text !== 'na' && link.text !== 'NA' ?  <a href={link.url} target="_blank">{link.text}</a>
                                                                            :
                                                                            <p>No links available</p>
                                                                    } <br />
                                                                    </div>
                                                                )

                                                            }
                                                        </div>

                                                    </div>


                                                    { ip.documents && ip.documents !== "NA" ?

                                                        <div className="col-sm-12 bg-dark p-5 doc">
                                                            <h2 className="text-white"><span style={{background:'#363b4d'}}>Documentation</span></h2>
                                                            <p className="text-white">Attached files
                                                            </p>

                                                            <div style={{display:'flex'}}>
                                                                <div className="" style={{width:'50px'}}>
                                                                <span className="text-white" style={{fontSize:'2em'}}>
                                                                    <a href={ip.documents.key0} target="_blank">
                                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                                    </a>
                                                                </span>
                                                                </div>
                                                                <div className="" style={{width:'60%'}}>
                                                                {
                                                                       docValues.map((link) =>
                                                                       <div>
                                                                       <a href={link} target="_blank">
                                                                            <span className="text-white">Doc.pdf</span>
                                                                        </a><br/>
                                                                        </div>
                                                                     )
                                                                   }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        : "No Doc Available" }
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* Relateted IPs Starts */}
                                    
                                    { related.length > 0 ?

                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 similar" style={{    marginTop: '60px'}}>
                                        <div class="tg-youmayalsolike">
                                            <div class="tg-sectionhead">
                                                <div class="tg-title">
                                                    <h2>You May Also Like</h2>
                                                </div>
                                                
                                            </div>
                                            <div class="row">
                                                <div class="tg-ads">

                                                <CarouselS
                                                    additionalTransfrom={0}
                                                    arrows
                                                    autoPlaySpeed={3000}
                                                    centerMode={false}
                                                    className=""
                                                    containerClass="container-with-dots"
                                                    dotListClass=""
                                                    draggable
                                                    focusOnSelect={false}
                                                    infinite
                                                    itemClass=""
                                                    keyBoardControl
                                                    minimumTouchDrag={80}
                                                    renderButtonGroupOutside={false}
                                                    renderDotsOutside={false}
                                                    responsive={{
                                                        desktop: {
                                                        breakpoint: {
                                                            max: 3000,
                                                            min: 1024
                                                        },
                                                        items: 4,
                                                        partialVisibilityGutter: 30
                                                        },
                                                        mobile: {
                                                        breakpoint: {
                                                            max: 464,
                                                            min: 0
                                                        },
                                                        items: 1,
                                                        partialVisibilityGutter: 30
                                                        },
                                                        tablet: {
                                                        breakpoint: {
                                                            max: 1024,
                                                            min: 464
                                                        },
                                                        items: 3,
                                                        partialVisibilityGutter: 30
                                                        }
                                                    }}
                                                    showDots={false}
                                                    sliderClass=""
                                                    slidesToSlide={1}
                                                    swipeable
                                                
                                                    >

                                                {
                                                    related.map((related) =>
                                                    //<link to={'/trademarks/ip/' + related.id.S}>
                                                <div className="col" style={{float:'left', padding:'15px'}}>
                                                    <div className="cstmh tg-ad tg-verifiedad cborder">
                                                                <figure>
                                                                    <a href={"/copyrights/ip/" + related.id  } >
                                                                    { related.images=="NA"?<img src={Copyright} className={'dmimg'} alt="img" />:<img src={ related.images[0]} className={'dmimg'} /> }
                                                                    {/* <img src={Trademarks} className={'dmimg'} alt="img" /> */}
                                                                    </a>
                                                                </figure>
                                                                <div className="tg-adcontent">
                                                                    <ul className="tg-productcagegories">
                                                                        <li><a href="#">Copyright</a><span className="card--status">{related.status}</span></li>
                                                                    </ul>
                                                                    <div className="tg-adtitle">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                        <h3><a href="#">{related.title}</a></h3>
                                                                        <div className="row">
                                                                          <div className="col-sm-6" style={{float:'left'}}>
                                                                          <time datetime="2017-06-06">{related.work_type}</time>
                                                                         </div>
                                                                          <div className="col-sm-6 text-right" style={{float:'text-right'}}>
                                                                         {related.noOfView && related.noOfView !=0  ?<time>{related.noOfView} views</time> :null}                                                   
                                                                       </div>
                                                                      </div>
                                                                    </div>
                                                                    <div className="tg-phonelike">
                                                                        <a className="tg-btnphone" href={"/copyrights/ip/" + related.id  } >
                                                                            <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6"><em>Show More</em></span>
                                                                        </a>
                                                                        <span class="tg-like">{related.isFvt ? <i className="fa fa-heart selectedFav"  onClick={()=>{this._delFavourite(related.id)}} ></i> : <i className="fa fa-heart " onClick={()=>this._addfavourite(related.id)}></i>}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                // </link>
                                                )
                                                }

                                                </CarouselS>
                                                </div>
                                                {/* <div class="tg-viewallbox">
                                                    <a class="tg-btn" href="javascript:void(0)">View All</a>
                                                </div> */}
                                            </div>
							            </div>
						            </div>

                                    : null }
                                    {/* Relateted IPs Ends */}
                                    
                                    {this.state.favNotification?this.show_Notifification():null} 


                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Home;
