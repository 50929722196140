import React from "react";
import Sidebar from "../user/sidebar";
import axios from "axios";
import config from "../environment.js";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import IconImg from "../images-custom/logo/IP-icon.png";

class dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ipListing: [],
      email: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
      limit: 10,
      currentPage: 1,
      user: []
    };
    this.handlePagination = this.handlePagination.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
  }

  componentDidMount() {
    this.getUser();
    this.getIpListing();
  }

  getUser() {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        this.setState({ user: user.attributes });
      })
      .catch(err => console.log(err));
  }
  getIpListing() {
    const { token } = this.state;
    const { email } = this.state;

    axios
      .get(
        `${config.production.api}fetchbyuser?email="${email}"&token="${token}"`,
        { headers: { token: token } }
      )
      .then(response => {
        this.setState({ ipListing: response.data.data.Items });
      })
      .catch(error => {});
  }
  handlePagination(e) {
    this.setState({ currentPage: Number(e.currentTarget.dataset.id) });
  }
  handleNextClick() {
    this.setState({ currentPage: this.state.currentPage + 1 });
  }
  handlePrevClick() {
    this.setState({ currentPage: this.state.currentPage - 1 });
  }

  _delIP = (_id, _category) => {
    const info = {
      id: _id,
      category: _category
    };

    const header = {
      token: localStorage.getItem("token")
    };

    axios
      .delete(config.production.api + `delete-category-posts`, {
        headers: header,
        data: info
      })
      .then(response => {
        ToastsStore.info(response.data.body);
        this.getIpListing();
        console.log(response, "OPT");
      });
  };

  timeConverter(UNIX_timestamp) {
    let a = new Date(UNIX_timestamp);
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let time =
      date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  }

  render() {
    const { ipListing } = this.state;
    return (
      <div className={"user-ml"}>
        <Sidebar />
        <div>
          <header
            id="tg-dashboardheader"
            className="tg-dashboardheader tg-haslayout"
          >
            <div className="tg-rghtbox">
              <div className="dropdown tg-themedropdown tg-notification">
                <button
                  className="tg-btndropdown"
                  id="tg-notificationdropdown"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="icon-alarm" />
                  <span className="tg-badge">9</span>
                </button>
                
              </div>
            </div>
          </header>

          <div className="tg-dashboardbanner">
            <h1>My Listing</h1>
            <ol className="tg-breadcrumb">
              <li>
                <a href="javascript:void(0);">Home</a>
              </li>
              <li>
                <a href="javascript:void(0);">Dashboard</a>
              </li>
              <li className="tg-active">My Listing</li>
            </ol>
          </div>

          <main id="tg-main" className="tg-main tg-haslayout">
            <section className="tg-dbsectionspace tg-haslayout">
              <div className="row">
                <form className="tg-formtheme tg-formdashboard">
                  <fieldset>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="tg-dashboardbox">
                        <div className="tg-dashboardboxtitle">
                          <h2>My Listing</h2>
                        </div>

                        {ipListing.length == 0 ? (
                          <div className="tg-dashboardholder">
                            <div className="tg-otherfilters">
                              <div className="row">
                                <div className="col-xs-12 text-center">
                                  <h3>No IP Listed</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="tg-dashboardholder table-responsive">
                            <table
                              id="tg-adstype"
                              className="table tg-dashboardtable tg-tablemyads"
                            >
                              <thead>
                                <tr>
                                  <th>Photo</th>
                                  <th>Title</th>
                                  <th>Category</th>
                                  <th>Price</th>
                                  <th>Status</th>
                                  <th>Date</th>
                                  <th>Agreement</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ipListing.map(List => (
                                  <tr>
                                    <td>
                                      {List.images == "NA" ? (
                                        <img
                                          src={IconImg}
                                          style={{ width: "100px" }}
                                          alt="image description"
                                        ></img>
                                      ) : (
                                        <img
                                          src={List.images[0]}
                                          style={{ width: "150px" }}
                                          alt="image description"
                                        ></img>
                                      )}
                                    </td>
                                    <td>{List.title}</td>
                                    <td>{List.category}</td>
                                    <td>{List.price}</td>
                                    <td>
                                      {List.isApproved ? (
                                        <span class="tg-adstatus tg-adstatusactive">
                                          Approved
                                        </span>
                                      ) : (
                                        <div>
                                          {List.isRejected ? (
                                            <span class="tg-adstatus tg-adstatusdeleted">
                                              Rejected
                                            </span>
                                          ) : (
                                            <span class="tg-adstatus tg-adstatusexpired">
                                              Pending
                                            </span>
                                          )}{" "}
                                        </div>
                                      )}

                                      {
                                        List.isRejected ? <span style={{fontSize:'10px'}}>{List.rejectMessage}</span> : null
                                      }
                                    </td>
                                    <td>{this.timeConverter(List.date)}</td>
                                    <td>{List.agreement}</td>
                                    <td>
                                      {/* <a
                                        className={"btn btn-default"}
                                        href={"/" + List.category +"s/ip/" + List.id }
                                        target="_blank"
                                      >
                                        View
                                      </a> */}

                                      <div class="tg-btnsactions">
                                        <a
                                          class="tg-btnaction tg-btnactionview"
                                          href={
                                            "/" +
                                            List.category +
                                            "s/ip/" +
                                            List.id
                                          }
                                          target="_blank"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i class="fa fa-eye"></i>
                                        </a>

                                        <a
                                          class="tg-btnaction tg-btnactionedit"
                                          href={
                                            "/dashboard/post/edit/" +
                                            List.category +
                                            "/" +
                                            List.category +
                                            "/" +
                                            List.id
                                          }
                                        >
                                          <i class="fa fa-pencil"></i>
                                        </a>
                                        <a
                                          class="tg-btnaction tg-btnactiondelete"
                                          href="javascript:void(0);"
                                        >
                                          <i
                                            class="fa fa-trash"
                                            onClick={() =>
                                              this._delIP(
                                                List.id,
                                                List.category
                                              )
                                            }
                                          ></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </fieldset>
                  <ToastsContainer store={ToastsStore} />
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default dashboard;
