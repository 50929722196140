import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images-custom/logo/IP-footer.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../store/actions/authActions";
import { withRouter } from "react-router-dom";
import Avtar from "../public/images/profile.svg";
import { Auth } from "aws-amplify";
import axios from "axios";
import config from "../environment";

class sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      localUsers: {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email")
      },
      show : true
    };
  }

  showHide = () => {
    this.setState({show : !this.state.show})
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        this.setState({ users: user.attributes });
      })
      .catch(err => console.log(err));
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
    this.props.history.push("/login");
    window.location.reload();
  }

  async getProfilePic(){
    var email = new String(localStorage.getItem("email"))
    let Url =  await axios.get(config.production.api +  `getprofilepic?email=%22${email}%22`);
    localStorage.setItem("profileUrl",Url.data)
  }
    

  render() {
    const { users } = this.state;
    const { localUsers } = this.state;
    this.getProfilePic()
    return (
      <div>
        <div id="tg-sidebarwrapper" className="tg-sidebarwrapper sm-sb">
          <div
            id="tg-verticalscrollbar"
            className="tg-verticalscrollbar mCustomScrollbar _mCS_1"
          >
            <div
              id="mCSB_1"
              className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
              tabIndex="0"
              style={{ maxHeight: "none" }}
            >
              <div
                id="mCSB_1_container"
                className="mCSB_container"
                style={{ position: "relative", top: "0px", left: "0px" }}
                dir="ltr"
              >
                <strong className="tg-logo hide-sb">
                  <a href={"/"}>
                    <img
                      src={Logo}
                      alt="image description"
                      style={{ height: "auto", width: "100%" }}
                    />
                  </a>
                </strong>
                <div className="tg-user hide-sb">
                  <figure style={{marginTop:'10px'}}>
                    <span className="tg-bolticon">
                      <i className="fa fa-bolt" />
                    </span>
                    <a href="javascript:void(0);">
                      {localStorage.getItem("profileUrl") != "null"?
                        <img 
                        src={localStorage.getItem("profileUrl")}
                        style={{width:'48px',height:'48px'}}
                        alt="image description"
                        className="mCS_img_loaded"
                      />:<img 
                      src={Avtar}
                      width={"50"}
                      alt="image description"
                      className="mCS_img_loaded"
                    />

                      }
                    
                    </a>
                  </figure>
                  <div className="tg-usercontent">
                    <h3>
                      Hi! {users.length == 0 ? localUsers.name : users.name}
                    </h3>
                    <h4 style={{ textTransform: "lowercase" }}>
                      {users.length == 0 ? localUsers.email : users.email}
                    </h4>
                  </div>
                  <Link to={"/dashboard/profile/edit"} className="tg-btnedit">
                    <i className="icon-pencil" />
                  </Link>
                </div>
                <nav id="tg-navdashboard" className="tg-navdashboard">
                  <ul>
                    <li className={'show-sb'} style={{display:'none'}}>
                      <Link to={"/"}>
                        <i className="icon-home" />
                      </Link>
                    </li>
                    <li>
                      <Link to={"/dashboard"}>
                        <i className="icon-chart-bars" />
                        <span> Profile</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/dashboard/post"}>
                        <i className="icon-cog" />
                        <span>Post IP</span>
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to={"/dashboard/all"}>
                        <i className="icon-layers" />
                        <span>My IP Listing</span>
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to={"/dashboard/favorites"}>
                        <i className="icon-layers" />
                        <span>My Favorites</span>
                      </Link>
                    </li>


                    <li class="menu-item-has-children">
								      <a href="javascript:void(0);" onClick={()=> this.showHide()}>
									      <i class="icon-envelope"></i>
									        <span>Offers/Messages</span>
								      </a>
                      {!this.state.show ? <span class="tg-dropdowarrow" onClick={()=> this.showHide()}><i class="fa fa-angle-down"></i></span> : <span class="tg-dropdowarrow" onClick={()=> this.showHide()}><i class="fa fa-angle-up"></i></span>}
        
                      {this.state.show? <ul class="sub-menu" style={{display: 'block'}}>
									      <li><Link to={"/dashboard/offerRecieved"}>Offer Received</Link></li>
									      <li><Link to={"/dashboard/offers"}>Offer Sent</Link></li>
								      </ul> : null}
							      </li>
                  
                    <li
                      className="menu-item-has-children"
                      style={{ cursor: "pointer" }}
                    >
                      <a onClick={this.onLogoutClick.bind(this)}>
                        <i className="icon-power-switch" />
                        <span>Logout</span>
                      </a>
                    </li>

                    { localStorage.getItem('email') == "info@trademyip.com" ? 
                    
                    <li
                      className="menu-item-has-children"
                      style={{ cursor: "pointer" }}
                    >
                      <Link to={'/dashboard/admin'}>
                        <i className="icon-user" />
                        <span>Admin</span>
                      </Link>
                    </li> : null
                  
                  }
                  </ul>
                </nav>
              </div>
              <div
                id="mCSB_1_scrollbar_vertical"
                className="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                style={{ display: "block" }}
              >
                <div className="mCSB_draggerContainer">
                  <div
                    id="mCSB_1_dragger_vertical"
                    className="mCSB_dragger"
                    style={{
                      position: "absolute",
                      minHeight: "30px",
                      display: "block",
                      height: "180",
                      maxHeight: "403px",
                      top: "0px"
                    }}
                  >
                    <div
                      className="mCSB_dragger_bar"
                      style={{ lineHeight: "30px" }}
                    />
                  </div>
                  <div className="mCSB_draggerRail" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  userAuth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userAuth: state.userAuth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(sidebar));
