import { SET_CURRENT_USER, SET_CURRENT_PROFILE } from "./types";
import { Auth } from 'aws-amplify'

export const loginCheck = (userToken, userData) => dispatch => {
  localStorage.setItem("token", userToken);
  localStorage.setItem("email", userData.email);
  localStorage.setItem("name", userData.name);
  localStorage.setItem("isSocial", userData.isSocial);

  let time = new Date();
  localStorage.setItem("time", JSON.stringify((time.getTime())))
  dispatch(setCurrentUser(userToken));
  dispatch(setCurrentProfile(userData));
};

export const loginWithGoogleCheck = (userToken, userData) => dispatch => {
  localStorage.setItem("token", userToken);
  localStorage.setItem("email", userData.email);
  localStorage.setItem("name", userData.name);

  dispatch(setCurrentUser(userToken));
  dispatch(setCurrentProfile(userData));
};
// Set logged in user
export const setCurrentUser = token => {
  return {
    type: SET_CURRENT_USER,
    payload: token
  };
};

//set current profile
export const setCurrentProfile = user => {
  return {
    type: SET_CURRENT_PROFILE,
    payload: user
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // if(localStorage.getItem( "name")) {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("email");
  //   localStorage.removeItem("name");
  //   dispatch(setCurrentUser({}));
  // }
  // else{
  //
  //   Auth.signOut()
  //       .then(data => console.log(data))
  //       .catch(err => console.log(err));
  //   localStorage.removeItem("token");

  //}
  localStorage.clear();
};
