import React from "react";
import { Auth } from "aws-amplify";
import { ToastsContainer, ToastsStore } from "react-toasts";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      password: "",
      confirmedPassword: ""
    };

    this.changePassword = this.changePassword.bind(this);
  }

  onInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  componentDidMount() {}

  changePassword(event) {
    event.preventDefault();

    const oldpass = this.state.oldPassword;
    const newPassword = this.state.confirmedPassword;

    Auth.currentAuthenticatedUser()

      .then(user => {
        return Auth.changePassword(user, oldpass, newPassword);
      })
      .then(data => ToastsStore.success("Password Changed"))
      .catch(err => ToastsStore.error(err.message));
  }

  render() {
    return (
      <div>
        <div className="row">
          <form onSubmit={this.changePassword}>
            <fieldset>
              <h4>Change Password</h4>

              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="password"
                  className="form-control"
                  name="oldPassword"
                  placeholder="Enter Old Password"
                  onChange={this.onInputChange}
                />
              </div>

              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Enter New Password"
                  onChange={this.onInputChange}
                />
              </div>

              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="password"
                  className="form-control"
                  name="confirmedPassword"
                  placeholder="Confirm Password"
                  onChange={this.onInputChange}
                />
              </div>
              <br />

              <div className={"row"}>
                <div
                  className="col-sm-12"
                  style={{ marginBottom: "10px", float: "left" }}
                >
                  <a
                    href={"/dashboard"}
                    className="btn btn-danger"
                    style={{ marginRight: "20px" }}
                  >
                    CANCEL
                  </a>
                  <input
                    type="submit"
                    className="btn btn-success"
                    value="SAVE"
                  />
                </div>
              </div>

              <ToastsContainer store={ToastsStore} />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
