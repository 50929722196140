import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import config from '../config'
import axios from 'axios';
// To federated sign in from Google

/*var oauth = {
    domain: 'trademyip.auth.us-east-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid'],
    redirectSignIn: 'http://localhost:3000/dashboard',
     redirectSignOut: 'http://localhost:3000',
     responseType: 'code',
     responseType: 'token',

    options: {
      AdvancedSecurityDataCollectionFlag : false
    }
};

Auth.configure({ 
  oauth: oauth,
  mandatorySignIn: true,
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID
 })*/


//creating auth details:


/*Auth.configure({
    identityPoolId: 'ap-south-1:69c51cc3-34b9-440f-9c2e-d15f64e2fbd1',
    identityPoolRegion: 'ap-south-1',
    userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',

});
*/

class googleAuth extends Component {
    constructor(props) {
        super(props);
        this.signIn = this.signIn.bind(this);
    }

    state = {
    SignInResponse :[]
  };

    /*componentDidMount() {
        const ga = window.gapi && window.gapi.auth2 ?
            window.gapi.auth2.getAuthInstance() :
            null;
        if (!ga) this.createScript();


    }*/

    // one other way to do things

    /*one_other_way{
        var authData = {
    ClientId : config.cognito.APP_CLIENT_ID, // Your client id here
    AppWebDomain : 'trademyip.auth.us-east-1.amazoncognito.com',
    TokenScopesArray : [ 'email', 'profile', 'openid'], // e.g.['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'],
    RedirectUriSignIn : 'http://localhost:3000/dashboard',
    RedirectUriSignOut : 'http://localhost:3000',
    IdentityProvider : 'Google', // e.g. 'Facebook',
    UserPoolId : config.cognito.USER_POOL_ID, // Your user pool id here
    AdvancedSecurityDataCollectionFlag : false
        };
var auth = new CognitoAuth(authData);

        auth.userhandler = {
    onSuccess: function(result) {
        alert("Sign in success");
      
    },
    onFailure: function(err) {
        alert("Error!");
    }
    }
*/
    async signIn() {
        //LOCALHOST variables
        let domain = 'https://trademyip.auth.ap-south-1.amazoncognito.com';
        //let redirectSignIn =  'http://localhost:3000/login';
        //let redirectSignOut = 'http://localhost:3000';
        let responseType =  'code';
        let client_id = config.cognito.APP_CLIENT_ID;

        //staging variables
        //let domain = 'https://trademyip.auth.ap-south-1.amazoncognito.com';
        let redirectSignIn =  'https://d3bxy5khxuvbzq.cloudfront.net/login';
        let redirectSignOut = 'https://d3bxy5khxuvbzq.cloudfront.net/';
        //let responseType =  'code';
        //let client_id = config.cognito.APP_CLIENT_ID;

        //https://trademyip.auth.ap-south-1.amazoncognito.com/login?response_type=code&client_id=3guuic12njlbids4j7in5fakf5&redirect_uri=http://localhost:3000/dashboard
        let url = domain+'/login?response_type='+responseType+'&client_id='+client_id+'&redirect_uri='+redirectSignIn;
        //window.location.href = url;
        window.location.href = url;
        //const response = await axios.get(url);
        //console.log(response);




        /*const ga = window.gapi.auth2.getAuthInstance();
        console.log("ga is" , ga);
        ga.signIn().then(
            googleUser => {
                this.getAWSCredentials(googleUser);
                console.log("google user is",googleUser)
            },
            error => {
                console.log("error here kdsfkm",error);
            }
        );*/
    }


    /*async getAWSCredentials(googleUser) {
        console.log("ujder aws credentials",googleUser)
        const { id_token, expires_at } = googleUser.getAuthResponse();
        console.log("id_token i got",id_token)
        const profile = googleUser.getBasicProfile();
        let user = {
            email: profile.getEmail(),
            name: profile.getName()
        };

        const credentials = await Auth.federatedSignIn(
            'google',
            { token: id_token , expires_at },
            user
        );
        console.log('credentials', credentials);
    }

    createScript() {
        // load the Google SDK
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js';
        script.async = true;
        script.onload = this.initGapi;
        document.body.appendChild(script);
    }

    initGapi() {
        // init the Google SDK client
        const g = window.gapi;
        console.log(g)
        g.load('auth2', function() {
            g.auth2.init({
                client_id: '248263687519-rojg6a34rr43kp3jd9mi2vj2ehqnd1fs.apps.googleusercontent.com',
                // authorized scopes
                scope: 'profile email openid'
            });
        });
    }
<<<<<<< HEAD
*/

    render() {
        return (
            <div>
                <li className="tg-googleplus">
                    <a href="javascript:void(0);" onClick={this.signIn}>
                        <i className="fa fa-user" />
                        <span>
                      Sign in with <strong> Google</strong>
                    </span>
                    </a>
                </li>
            </div>
        );
    }
}


export default googleAuth;


