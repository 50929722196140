import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import { Modal, Button } from "react-bootstrap";
import { ReCaptcha } from 'react-recaptcha-google';
import "./modal.css";
import axios from "axios";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {Auth} from "aws-amplify";
import config from "../environment";




class modalOffer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            showModal: false,
            first:localStorage.getItem('name'),
            last: null,
            emailTo: this.props.emailTo,
            emailFrom: localStorage.getItem('email'),
            subject: null,
            comment: null,
            ip_id: this.props.id,
            token: localStorage.getItem("token"),
            category: this.props.category};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {


    }


    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        let offerDetailData = {
            first: this.state.first,
            emailTo: this.state.emailTo,
            emailFrom: this.state.emailFrom,
            subject: this.state.subject,
            comment: this.state.comment,
            ip_id: this.props.id ,
            category: this.props.category,
            ip_title: this.props.ip_title,
            name:this.props.name,
            class:this.props.class,
            favNotification : false,
        }
        console.log("token",this.props)
        axios.post(`${config.production.api}offers/saveoffer`,offerDetailData, { headers: { token: this.state.token } })
            .then(res => {
                if(res.data.errorMessage) {
                    ToastsStore.error("Request Unable to complete")
                }
                else {
                    ToastsStore.success(res.data.data)
                    this.setState({ showModal: false });
                }
            })
    }



   

    handleOpenModal = () => {

        const arr = {};
        arr.category = this.props.category;
        arr.ip_id = this.props.ip_id;
        arr.emailTo = this.props.emailTo;
        this.setState(arr);

        this.setState({ showModal: true });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const overlayClassName = this.state.showModal
            ? "modal fade show"
            : "modal fade";

        return (
            <div>

                { localStorage.getItem("email") ? <a className="tg-btnmakeanoffer" onClick={this.handleOpenModal} href="#" data-toggle="modal" data-target="#tg-modalmakeanoffer"><i className="icon-briefcase"></i><span><em> Make An Offer</em><span>Place Your Best Offer Now</span></span></a> : <a className="tg-btnmakeanoffer" onClick={this.login} data-toggle="modal" data-target="#tg-modalmakeanoffer"><i className="icon-briefcase"></i><span><em> Make An Offer</em><span>Place Your Best Offer Now</span></span></a> }


                <ReactModal
                    className="modal-dialog modal-content"
                    bodyOpenClassName="modal-open"
                    overlayClassName={overlayClassName}
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                >
                    <Modal.Body>


                        <div className="col-xs-12 col-sm-12">
                            <h3 className="mb-3">Make an Offer</h3>

                            <span className="m-icon" onClick={this.handleCloseModal}><i className="fa fa-times" aria-hidden="true"></i></span>
                            <div className="row">

                                <form onSubmit={this.handleSubmit}>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <input type="text" name="first" className="form-control" placeholder="First Name*" value={localStorage.getItem('name')}  onChange={this.handleChange} disabled required={true}/>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <input type="email" name="emailFrom" className="form-control" placeholder="Email*" required value={localStorage.getItem('email')} onChange={this.handleChange} disabled/>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <input type="text" name="subject" className="form-control" placeholder="Subject (optional)" value={this.state.subject} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <textarea className="form-control" name="comment" placeholder="Comment" value={this.state.comment} onChange={this.handleChange}></textarea>
                                        </div>
                                    </div>

                                    <div className="col-sm-12" style={{    marginBottom: '15px'}}><ReCaptcha ref={(el) => {this.captchaDemo = el;}}  size="normal" render="explicit" sitekey="6LfEY6IUAAAAAGzF9TMjMRCrp-a6q9WMxZ5pxg7J" onloadCallback={this.onLoadRecaptcha} verifyCallback={this.verifyCallback} /></div>

                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <input type="submit" className="tg-btn" style={{background: '#5ecd69'}} value="Submit" />
                                    </div>

                                    {this.state.favNotification?this.show_Notifification():null} 


                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{border:'0px'}}>

                    </Modal.Footer>
                </ReactModal>
                <div
                    className={
                        this.state.showModal ? "modal-backdrop fade show" : "display: none;"
                    }
                />


                <div> <ToastsContainer store={ToastsStore}/> </div>

            </div>
        );
    }
}


export default  modalOffer;