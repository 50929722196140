import React from 'react';
import Navbar from '../../includes/navbar';

import Footer from '../../includes/footer';
import Opposition from '../../images-custom/trademark_opposition.png';
import QuickContact from './quickContact';



class trademarksOpposition extends React.Component {

    render(){

        return (
            <div>
                <Navbar />

                <div className="container">


                <section className="tg-sectionspace tg-haslayout">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-10">
                                <h2>TRADE MARKS</h2>
                                        <hr className="hr-custom" />

                                <p>In India Trade marks are registered with Controller General of Patents Designs and Trade marks, Ministry of Commerce and Industry, Government of India. Trade marks are registered under the Trade mark Act, 1999.</p>
                                <h3>OPPOSITION</h3>
                                <hr className="hr-custom" />

                                <p>Proposed trade marks that are accepted are advertised in the journal that is published every week. This is done to allow the public in general to oppose the trade marks published. The opposition can be raised for various reasons like, if the mark is deceptively similar to an already registered mark which can harm the business, etc.</p>
                                <p>The time period for raising the objection has to be within 4 months of the journal being published. A notice of opposition (TM-O) can be filed by providing the reasons on why the objection is raised. Once filed the applicant of the proposed trade mark has to file a response to the opposition within 2 months.</p>

                                <img src={Opposition} />

                                <p style={{marginTop:'20px'}}>Drafting for the notice of opposition can take 4-5 days to prepare with all facts, evidences and any related judgement to ensure that the notice is complete and stands a good chance when it is reviewed for the purposes of refusing the proposed trademark of the applicant.</p>

                                <p><b>TRADEMYIP can help in providing a free consultation and filing the notice of opposition</b>. Once the response is filed then the Registry will take time to process the response. There is no guarantee that by filing the response the proposed trade mark will be accepted. It totally depends on evidence and facts of the case.</p>

                                        {/*<div className="table-responsive">*/}
                                            {/*<table className="table pricingTable">*/}
                                                {/*<tr style={{background:'#363A4D', color:'white'}}>*/}
                                                    {/*<td>All entities (if trademark was filed through TRADEMYIP)</td>*/}
                                                    {/*<td>All entities (if trademark was filed through a 3rd Party)</td>*/}
                                                {/*</tr>*/}
                                                {/*<tr style={{fontSize: '22px', fontWeight: '700'}}>*/}
                                                    {/*<td>₹ 5999</td>*/}
                                                    {/*<td>₹ 7999</td>*/}

                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                    {/*<td>Free Consultation</td>*/}
                                                    {/*<td>Free Consultation</td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                    {/*<td></td>*/}
                                                    {/*<td>New Letter of Authorization</td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                    {/*<td>Search on facts, evidences, related judgments, etc.</td>*/}
                                                    {/*<td>Search on facts, evidences, related judgments, etc.</td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                    {/*<td>Drafting of the notice of opposition</td>*/}
                                                    {/*<td>Drafting of the notice of opposition</td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                    {/*<td>Government Fee and taxes</td>*/}
                                                    {/*<td>Government Fee and taxes</td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                    {/*<td>Total time – 4-5 days</td>*/}
                                                    {/*<td>Total time – 5 days</td>*/}
                                                {/*</tr>*/}
                                            {/*</table>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <QuickContact />
                </div>
                <Footer />

            </div>
        );
    }
}

export default trademarksOpposition;
