import { SET_CURRENT_PROFILE } from "../actions/types";

const initialState = {
  user: {
    email: "",
    name: ""
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PROFILE:
      return {
        ...state,
        user: {
          email: action.payload.email,
          name: action.payload.name
        }
      };
    default:
      return state;
  }
}
