import React from 'react';
import CommingSoon from "../../pages/commingSoon"



class designServices extends React.Component {

    render(){

        return (


            <div>
             <CommingSoon/>
            </div>
        );
    }
}
export default designServices;
