import React from 'react';
import Navbar from '../../includes/navbar';

import Footer from '../../includes/footer';
import Assignment from '../../images-custom/trademark_assigment.png';
import QuickContact from './quickContact';



class trademarkAssignment extends React.Component {

    render(){

        return (
            <div>
                <Navbar />

                <div className="container">


                <section className="tg-sectionspace tg-haslayout">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <h2>TRADE MARKS</h2>
                                        <hr className="hr-custom" />
                                        <p>In India Trade marks are registered with Controller General of Patents Designs and Trade marks, Ministry of Commerce and Industry, Government of India. Trade marks are registered under the Trade mark Act, 1999.</p>

                                        <h3>ASSIGNMENT</h3>
                                        <hr className="hr-custom" />
                                        <p>Trade marks can be assigned to another party just like any other intellectual property. </p>
                                        <p>There are 2 parties involved here. Assignor – who owns the mark and Assignee – who is the recipient (new owner) of the mark.</p>


                                        <p> The ownership of the mark can be given to another party by filing with the Registrar of trade marks and paying the required fees in case of registered trade marks. A proper agreement/assignment deed is required along with a user affidavit to do the assignment. This will prevent any kind of legal hassles that can arise later.</p>
                                        <p> Below is the process for assignment of a trade mark</p>



                                        <div className="text-center">
                                            <img src={Assignment} />
                                        </div>

                                        <p style={{marginTop:'20px'}}><b>TRADEMYIP can help with Trade mark assignment by providing free consultation, creating assignment deed, affidavits and filing with the registrar.</b></p>


                                        <h4>BENEFITS OF TRADE MARK ASSIGNMENT</h4>
                                        <hr className="hr-custom" />
                                        <ul>
                                            <li><b>For Assignor</b> – Trade marks have goodwill which has a financial value for the owner of the mark. Selling the ownership of a mark with goodwill and brand value can fetch good monetary rewards for the owner.</li>
                                            <li><b>For Assignee</b> – Will get exclusive right to use the mark for the purposes of trade/business. Mark with a certain goodwill will already have well-established set of customers and recognition that can be beneficial for business/trade.</li>
                                        </ul>

                                        <QuickContact />
                                    </div>
                                </div>
                            </div>
                        </section>


                </div>
                <Footer />

            </div>
        );
    }
}

export default trademarkAssignment;
