import React, { Component } from 'react';
import Navbar from '../includes/navbar';
import Footer from '../includes/footer';
import ContactImg from '../images-custom/contact.jpg';
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = { first: '', last: '', email: '', subject: '',comment: '',mobile: ''};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(event) {
        const arr = {}
        arr[event.target.name] = event.target.value;
        this.setState(arr);
    }

    handleSubmit(event) {
        event.preventDefault();
        const { first,last,email,subject,comment,mobile } = this.state;
        let formBody = {
            first,
            last,
            email,
            subject: subject || 'NA',
            comment: comment || 'NA',
            mobile: mobile || 'NA'
        }
        axios.post(`https://hij2qhiaa6.execute-api.us-east-1.amazonaws.com/staging/contact`, formBody)
            .then(res => {
                if(res.data.errorMessage) {
                    ToastsStore.error("Request Unable to complete")
                }
                else {
                    ToastsStore.success("Request Successfully Submitted");
                    this.setState({first: '', last: '', email: '', subject: '', comment: '',mobile:''});
                }
            })
    }
        render() {
        return (
            <div>
                <Navbar />
                <main id="tg-main" className="tg-main tg-haslayout">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="tg-contactarea" style={{marginBottom:'60px'}}>
                                    <div className="tg-sectionhead" style={{marginTop:'40px'}}>
                                        <div className="tg-title">
                                            <h2>Get In Touch With Us</h2>
                                        </div>
                                        <div className="tg-description">
                                            <p>Have any questions/comments or feedback. We are listening</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <img src={ContactImg} className="imgStyle"  />
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <div className="row">
                                                <form onSubmit={this.handleSubmit} ref={this.resetForm}>
                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="first" className="form-control" placeholder="First Name*" value={this.state.first}  onChange={this.handleChange}  required={true}/>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="last" className="form-control" placeholder="Last Name*" value={this.state.last} onChange={this.handleChange} required={true} />
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="email" name="email" className="form-control" placeholder="Email*" required={true} value={this.state.email} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="subject" className="form-control" placeholder="Subject (optional)" value={this.state.subject} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control" name="comment" placeholder="Comment" value={this.state.comment} onChange={this.handleChange}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <input type="submit" className="tg-btn" style={{background: '#5ecd69'}} value="Submit" />
                                                </div>
                                                </form>
                                                <div>
                                                    <ToastsContainer store={ToastsStore}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }
}

export default Contact;
